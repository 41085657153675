import { Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box} from "@mui/material";
import React,{ useEffect } from "react";
import axios from "axios";
import Navback from "./Navbarback";
import Footer from "../fronthome/footer/footer";
import './backsearch.css'
import One from "./one";
import Swal from "sweetalert2";

const Backhome = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";;

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (
          response.data.status === "OK" &&
          (role === "admin" || role === "root")
        ) {
          // alert('login success')
        } else if (
          response.data.status === "OK" &&
          (role !== "admin" || role !== "root")
        ) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Navback />
      <CssBaseline />
      <Container>
      <Box>
        <Paper style={{ marginTop: "100px" , marginBottom:"80px" }}>
            <One/>
        </Paper>
      </Box>
      </Container>
      <Footer/>
    </div>
  );
};
export default Backhome;