import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import DatePicker from "react-date-picker";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  Block,
  CancelOutlined,
  EventAvailable,
  EventBusy,
  Send,
  NotificationAdd,
} from "@mui/icons-material/";
import "./ondate.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {
  Container,
  TextField,
  Grid,
  CssBaseline,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import Swal from "sweetalert2";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const Apponeback = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const schedule =
    "https://script.google.com/macros/s/AKfycbxspdCNuixRp_vSsSrJUbN7BozeUKxbDx-JaGqPy44BorKi9Hzk8wbdZyNg3LrhUMtF4g/exec?action=getUsers";
  const schedule2 =
    "https://script.google.com/macros/s/AKfycbzoOo07wrYQnLl3L69cnYjafwyg8C0x2JtNHSrjZtO695kCP-5KRSTBOoaweS3fdj83cw/exec?action=getUsers";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (
          response.data.status === "OK" &&
          (role === "admin" || role === "root")
        ) {
          // alert('login success')
        } else if (
          response.data.status === "OK" &&
          (role !== "admin" || role !== "root")
        ) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  //date

  const [startDate, setStartDate] = useState(new Date());

  const [dayname, setDayname] = useState("");

  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${URL}/users`).then((res) => {
      res.data.shift();
      res.data.shift();
      setData(res.data);
    });
  }, [URL]);

  const [search, setSearch] = useState("");
  const testtt = (e) => {
    return setSearch(e.target.value);
  };

  //เวปรระะจำวัน

  const [scheduletoday, setScheduletoday] = useState([]);
  const [scheduletoday2, setScheduletoday2] = useState([]);

  useEffect(() => {
    const holiday = [
      {
        date: "ยังไม่กำหนดเวร",
        asuwa: "ยังไม่กำหนดเวร",
        arofat: "ยังไม่กำหนดเวร",
        aneera: "ยังไม่กำหนดเวร",
      },
    ];
    const holiday2 = [
      {
        date: "ยังไม่กำหนดเวร",
        fateemoh: "ยังไม่กำหนดเวร",
        wanareena: "ยังไม่กำหนดเวร",
        nuraida: "ยังไม่กำหนดเวร",
        seeteefateemoh: "ยังไม่กำหนดเวร",
        abdulloh: "ยังไม่กำหนดเวร",
      },
    ];
    axios.get(`${schedule}`).then((res) => {
      if (
        res.data.filter(
          (e) =>
            new Date(e.date).toLocaleDateString("th-TH") ===
            startDate.toLocaleDateString("th-TH")
        ).length === 0
      ) {
        setScheduletoday(holiday);
        return;
      }
      setScheduletoday(
        res.data.filter(
          (e) =>
            new Date(e.date).toLocaleDateString("th-TH") ===
            startDate.toLocaleDateString("th-TH")
        )
      );
    });
    axios.get(`${schedule2}`).then((res) => {
      if (
        res.data.filter(
          (e) =>
            new Date(e.date).toLocaleDateString("th-TH") ===
            startDate.toLocaleDateString("th-TH")
        ).length === 0
      ) {
        setScheduletoday2(holiday2);
        return;
      }
      setScheduletoday2(
        res.data.filter(
          (e) =>
            new Date(e.date).toLocaleDateString("th-TH") ===
            startDate.toLocaleDateString("th-TH")
        )
      );
    });
  }, [URL, startDate]);

  const checkttm = (check) => {
    if (check === "ซักประวัติ") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#A8D08D",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "คีย์ข้อมูล") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#B4C6E7",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "งานเอกสาร") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#FFE599",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "หยุด") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#767171",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เวร") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#00FFFF",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "สกรีนเช้า") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#00B050",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "สกรีนบ่าย") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#F4656D",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "วันหยุดราชการ") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#767171",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#D9D9D9",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    }
  };

  const checkttm2 = (check) => {
    if (check === "หัวหน้าผู้ช่วย") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#F2C7CD",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เตรียมตู้อบสมุนไพร") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#FFAE92",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เตรียมลูกประคบ") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#DADD45",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เคลียตู้อบสมุนไพร") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#Fbd4ad",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เคลียลูกประคบ") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#A5B59C",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "หยุด") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#767171",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "เวร") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#00FFFF",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "สกรีนเช้า") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#00B050",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "สกรีนบ่าย") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#F4656D",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else if (check === "วันหยุดราชการ") {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#767171",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    } else {
      return {
        tableLayout: "fixed",
        width: "200px",
        height: "40px",
        backgroundColor: "#D9D9D9",
        fontFamily: "Athiti",
        fontWeight: "bold",
      };
    }
  };

  const dateInPast = function (firstDate, secondDate) {
    if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  };
  //เวรประจำวัน

  const [usersdata, setUsersdata] = useState([]);

  useEffect(() => {
    const usersURL = `${URL}/users`;
    axios.get(`${usersURL}`).then((res) => {
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      setUsersdata(res.data);
    });
  }, [URL]);

  const [searchh, setSearchh] = useState(null);
  const testttt = (e) => {
    if (e.target.value === "") {
      return setSearchh(null);
    } else {
      return setSearchh(e.target.value);
    }
  };

  //date

  //clerdata
  const newconfirmlist = () => {
    var config = {
      method: "post",
      url: "https://lineapi.thaipadi.org/newconfirmlist",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cleardata = (AN) => {
    var data = JSON.stringify({
      TN: 1,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinments/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการเข้ารับบริการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldatasss();
          ldataman();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          newconfirmlist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cleardatas = (AN) => {
    var data = JSON.stringify({
      TN: 1,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinmentss/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการเข้ารับบริการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          newconfirmlist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cleardatass = (AN) => {
    var data = JSON.stringify({
      TN: 1,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinmentsss/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการเข้ารับบริการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          newconfirmlist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cleardataman = (AN) => {
    var data = JSON.stringify({
      TN: 1,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmmanappoinments/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการเข้ารับบริการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          newconfirmlist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addconfirmlist = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardata(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addconfirmlists = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardatas(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addconfirmlistss = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;
    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardatass(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addconfirmlistsss = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;
    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardatass(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addconfirmlistman = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardataman(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //cleardata
  const newblocklist = () => {
    var config = {
      method: "post",
      url: "https://lineapi.thaipadi.org/newblocklist",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //cancel
  const canceldata = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomen`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteholiday = (holidayTN) => {
    if (holidayTN === 7) {
      var data = JSON.stringify({
        date: startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      });

      var config = {
        method: "delete",
        url: `${URL}/holiday`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data["status"] === "OK") {
            recheck91();
            recheck92();
            recheck93();
            recheck94();
            recheck95();
            recheck105();
            recheck135();
            recheck145();
            recheck101();
            recheck102();
            recheck103();
            recheck104();
            recheck131();
            recheck132();
            recheck133();
            recheck134();
            recheck141();
            recheck142();
            recheck143();
            recheck144();
            return;
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ลบข้อมูลวันหยุดผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              return;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return;
    }
  };

  const canceldata2 = (AN) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinments/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          newblocklist();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatas = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomens`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatas2 = (AN) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinmentss/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          newblocklist();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatass = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomenss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatass2 = (AN) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinmentsss/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          newblocklist();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatasss = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomensss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatasss2 = (AN) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmappoinmentssss/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          newblocklist();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };





  const canceldataman = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearman`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldataman2 = (AN) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/confirmmanappoinments/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          recheck91();
          recheck92();
          recheck93();
          recheck94();
          recheck95();
          recheck105();
          recheck135();
          recheck145();
          recheck101();
          recheck102();
          recheck103();
          recheck104();
          recheck131();
          recheck132();
          recheck133();
          recheck134();
          recheck141();
          recheck142();
          recheck143();
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          newblocklist();
          passappblock();
          passappconfirm();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //cancel

  //block

  const addblocklist = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/blocklist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        canceldata2(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addblocklists = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;
    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/blocklist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        canceldatas2(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addblocklistss = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/blocklist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        canceldatass2(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addblocklistsss = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/blocklist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        canceldatasss2(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addblocklistman = (TN, AN, date, time, provider) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/blocklist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        canceldataman2(AN);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //block

  const linealert = () => {
    Swal.fire({
      title: "ยืนยันการแจ้งเตือน",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        var config = {
          method: "post",
          url: `https://linenotify.thaipadi.org/alertline`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  //09:00
  //one
  const [data9one, setData9one] = useState([]);
  const [data9one2, setData9one2] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [status91, setStatus91] = useState("ว่าง");
  const [cfer91, setCfer91] = useState(true);
  const [cancel91, setCancel91] = useState(true);
  useEffect(() => {
    ldataall();
    ldatas();
    ldatass();
    ldataman();
    ldatasss();
    appdataone();
    appdatatwo();
    appdatatree();
    appdatafour();
    appdatafive();
    passappblock();
    passappconfirm();
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one1 = [
      {
        AN: null,
        TN: null,
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      const resdata91 = res.data.map((item) => {
        return item.TN;
      });
      if (res.data.length === 0) {
        setData9one(data9one1[0]);
        setEditButton91(false);
        setIcon91(<EventAvailable />);
        setStatus91("ว่างงง");
        setCancel91(true);
        setCfer91(true);
        return;
      } else if (res.data.length >= 1) {
        setData9one(res.data[0]);
        setEditButton91(true);
        setIcon91(<EventBusy />);
        setStatus91("ไม่ว่าง");
        if (dateInPast(startDate, new Date())) {
          if (resdata91[0] === 1 || resdata91[0] === 2) {
            setCfer91(true);
            setCancel91(true);
            return;
          } else if (resdata91[0] > 2 && resdata91[0] < 10) {
            setCfer91(true);
            setCancel91(false);
            return;
          } else if (resdata91[0] > 10) {
            setCfer91(false);
            setCancel91(false);
            return;
          } else {
            setCfer91(false);
            setCancel91(false);
            return;
          }
        } else {
          setCfer91(true);
          setCancel91(false);
          return;
        }
      }
    });
  }, [startDate, data9one2, URL]);
  // Modalsearch
  const [selecttn91, setSelecttn91] = useState("");
  const [selectname91, setSelectname91] = useState("");
  const [service91, setService91] = useState("");
  const handleChange91 = (event) => {
    setService91(event.target.value);
  };

  const selectpatient91 = (TN, prefix, fname, lname) => {
    setSelecttn91(TN);
    setSelectname91(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment91 = () => {
    var data = JSON.stringify({
      TN: selecttn91,
      date: data9one.date,
      time: data9one.time,
      service: service91,
      provider: data9one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck91();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen91(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const addholiday = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    if (selecttn91 === 7) {
      var data = JSON.stringify({
        date: startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        dateget: bbb,
      });
      var config = {
        method: "post",
        url: `${URL}/holidays`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data.status));
          if (response.data["status"] === "OK") {
            recheck91();
            recheck92();
            recheck93();
            recheck94();
            recheck95();
            recheck105();
            recheck135();
            recheck145();
            recheck101();
            recheck102();
            recheck103();
            recheck104();
            recheck131();
            recheck132();
            recheck133();
            recheck134();
            recheck141();
            recheck142();
            recheck143();
            recheck144();
            return;
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "เพิ่มข้อมูลวันหยุดผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              return;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      return;
    } else {
      return;
    }
  };

  const recheck91 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9one2(res.data[0]);
    });
  };

  const [open91, setOpen91] = useState(false);
  const handleOpen91 = () => setOpen91(true);
  const handleClose91 = () => setOpen91(false);

  const handleOpenin91 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen91(false);
            recheck91()
          }
        });
        return;
      } else if (!service91 || !selecttn91) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addholiday();
          addmanaappoinment91();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm91 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlist(
          data9one.TN,
          data9one.AN,
          data9one.date,
          data9one.time,
          data9one.provider
        );
      }
    });
  };

  const handleOpencancel91 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data9one.AN);
        deleteholiday(data9one.TN);
      }
    });
  };

  const handleOpenblock91 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklist(
          data9one.TN,
          data9one.AN,
          data9one.date,
          data9one.time,
          data9one.provider
        );
      }
    });
  };
  //one

  //two
  const [data9two, setData9two] = useState([]);
  const [data9two2, setData9two2] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [status92, setStatus92] = useState("ว่าง");
  const [cfer92, setCfer92] = useState(true);
  const [cancel92, setCancel92] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata92 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            setData9two(data9one2[0]);
            setEditButton92(false);
            setIcon92(<EventAvailable />);
            setStatus92("ว่างงง");
            setCancel92(true);
            setCfer92(true);
            return;
          } else if (res.data.length >= 1) {
            setData9two(res.data[0]);
            setEditButton92(true);
            setIcon92(<EventBusy />);
            setStatus92("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata92[0] === 1 || resdata92[0] === 2) {
                setCfer92(true);
                setCancel92(true);
                return;
              } else if (resdata92[0] > 2 && resdata92[0] < 10) {
                setCfer92(true);
                setCancel92(false);
                return;
              } else if (resdata92[0] > 10) {
                setCfer92(false);
                setCancel92(false);
                return;
              } else {
                setCfer92(false);
                setCancel92(false);
                return;
              }
            } else {
              setCfer92(true);
              setCancel92(false);
              return;
            }
          }
        });
      } else {
        setData9two(data9one2[2]);
        setEditButton92(true);
        setIcon92(<EventBusy />);
        setStatus92("ไม่ว่าง");
        setCfer92(true);
        setCancel92(true);
        return;
      }
    });
  }, [startDate, data9two2, URL]);

  //Modalsearch

  const [selecttn92, setSelecttn92] = useState("");
  const [selectname92, setSelectname92] = useState("");
  const [service92, setService92] = useState("");
  const handleChange92 = (event) => {
    setService92(event.target.value);
  };

  const selectpatient92 = (TN, prefix, fname, lname) => {
    setSelecttn92(TN);
    setSelectname92(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment92 = () => {
    var data = JSON.stringify({
      TN: selecttn92,
      date: data9two.date,
      time: data9two.time,
      service: service92,
      provider: data9two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 2500,
        }).then((result) => {
          recheck92();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen92(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck92 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9two2(res.data[0]);
    });
  };

  const [open92, setOpen92] = useState(false);
  const handleOpen92 = () => setOpen92(true);
  const handleClose92 = () => setOpen92(false);

  const handleOpenin92 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen92(false);
            recheck92()
          }
        });
        return;
      } else if (!service92 || !selecttn92) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment92();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm92 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlists(
          data9two.TN,
          data9two.AN,
          data9two.date,
          data9two.time,
          data9two.provider
        );
      }
    });
  };

  const handleOpencancel92 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data9two.AN);
      }
    });
  };

  const handleOpenblock92 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklists(
          data9two.TN,
          data9two.AN,
          data9two.date,
          data9two.time,
          data9two.provider
        );
      }
    });
  };
  //two

  //tree

  const [data9tree, setData9tree] = useState([]);
  const [data9tree2, setData9tree2] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [status93, setStatus93] = useState("ว่าง");
  const [cfer93, setCfer93] = useState(true);
  const [cancel93, setCancel93] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata93 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData9tree(data9one3[1]);
              setEditButton93(true);
              setIcon93(<EventBusy />);
              setStatus93("ไม่ว่าง");
              setCancel93(true);
              setCfer93(true);
              return;
            }
            setData9tree(data9one3[0]);
            setEditButton93(false);
            setIcon93(<EventAvailable />);
            setStatus93("ว่างงง");
            setCancel93(true);
            setCfer93(true);
            return;
          } else if (res.data.length >= 1) {
            setData9tree(res.data[0]);
            setEditButton93(true);
            setIcon93(<EventBusy />);
            setStatus93("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata93[0] === 1 || resdata93[0] === 2) {
                setCfer93(true);
                setCancel93(true);
                return;
              } else if (resdata93[0] > 2 && resdata93[0] < 10) {
                setCfer93(true);
                setCancel93(false);
                return;
              } else if (resdata93[0] > 10) {
                setCfer93(false);
                setCancel93(false);
                return;
              } else {
                setCfer93(false);
                setCancel93(false);
                return;
              }
            } else {
              setCfer93(true);
              setCancel93(false);
              return;
            }
          }
        });
      } else {
        setData9tree(data9one3[2]);
        setEditButton93(true);
        setIcon93(<EventBusy />);
        setStatus93("ไม่ว่าง");
        setCfer93(true);
        setCancel93(true);
        return;
      }
    });
  }, [startDate, data9tree2, URL]);

  //Modalsearch

  const [selecttn93, setSelecttn93] = useState("");
  const [selectname93, setSelectname93] = useState("");
  const [service93, setService93] = useState("");
  const handleChange93 = (event) => {
    setService93(event.target.value);
  };

  const selectpatient93 = (TN, prefix, fname, lname) => {
    setSelecttn93(TN);
    setSelectname93(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment93 = () => {
    var data = JSON.stringify({
      TN: selecttn93,
      date: data9tree.date,
      time: data9tree.time,
      service: service93,
      provider: data9tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck93();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen93(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck93 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9tree2(res.data[0]);
    });
  };

  const [open93, setOpen93] = useState(false);
  const handleOpen93 = () => setOpen93(true);
  const handleClose93 = () => setOpen93(false);

  const handleOpenin93 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen93(false);
            recheck93()
          }
        });
        return;
      } else if (!service93 || !selecttn93) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment93();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm93 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistss(
          data9tree.TN,
          data9tree.AN,
          data9tree.date,
          data9tree.time,
          data9tree.provider
        );
      }
    });
  };

  const handleOpencancel93 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data9tree.AN);
      }
    });
  };

  const handleOpenblock93 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistss(
          data9tree.TN,
          data9tree.AN,
          data9tree.date,
          data9tree.time,
          data9tree.provider
        );
      }
    });
  };
  //tree

  //five
  const [data9five, setData9five] = useState([]);
  const [data9five2, setData9five2] = useState([]);
  const [editButton95, setEditButton95] = useState(false);
  const [icon95, setIcon95] = useState(<EventAvailable />);
  const [status95, setStatus95] = useState("ว่าง");
  const [cfer95, setCfer95] = useState(true);
  const [cancel95, setCancel95] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one5 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentsss/9/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata95 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData9five(data9one5[1]);
              setEditButton95(true);
              setIcon95(<EventBusy />);
              setStatus95("ไม่ว่าง");
              setCancel95(true);
              setCfer95(true);
              return;
            }
            setData9five(data9one5[0]);
            setEditButton95(false);
            setIcon95(<EventAvailable />);
            setStatus95("ว่างงง");
            setCancel95(true);
            setCfer95(true);
            return;
          } else if (res.data.length >= 1) {
            setData9five(res.data[0]);
            setEditButton95(true);
            setIcon95(<EventBusy />);
            setStatus95("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata95[0] === 1 || resdata95[0] === 2) {
                setCfer95(true);
                setCancel95(true);
                return;
              } else if (resdata95[0] > 2 && resdata95[0] < 10) {
                setCfer95(true);
                setCancel95(false);
                return;
              } else if (resdata95[0] > 10) {
                setCfer95(false);
                setCancel95(false);
                return;
              } else {
                setCfer95(false);
                setCancel95(false);
                return;
              }
            } else {
              setCfer95(true);
              setCancel95(false);
              return;
            }
          }
        });
      } else {
        setData9five(data9one5[2]);
        setEditButton95(true);
        setIcon95(<EventBusy />);
        setStatus95("ไม่ว่าง");
        setCfer95(true);
        setCancel95(true);
        return;
      }
    });
  }, [startDate, data9five2, URL]);

  //Modalsearch

  const [selecttn95, setSelecttn95] = useState("");
  const [selectname95, setSelectname95] = useState("");
  const [service95, setService95] = useState("");
  const handleChange95 = (event) => {
    setService95(event.target.value);
  };

  const selectpatient95 = (TN, prefix, fname, lname) => {
    setSelecttn95(TN);
    setSelectname95(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment95 = () => {
    var data = JSON.stringify({
      TN: selecttn95,
      date: data9five.date,
      time: data9five.time,
      service: service95,
      provider: data9five.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentssss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck95();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen95(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck95 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9five2(res.data[0]);
    });
  };

  const [open95, setOpen95] = useState(false);
  const handleOpen95 = () => setOpen95(true);
  const handleClose95 = () => setOpen95(false);

  const handleOpenin95 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen95(false);
            recheck95()
          }
        });
        return;
      } else if (!service95 || !selecttn95) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment95();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm95 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistsss(
          data9five.TN,
          data9five.AN,
          data9five.date,
          data9five.time,
          data9five.provider
        );
      }
    });
  };

  const handleOpencancel95 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatasss(data9five.AN);
      }
    });
  };

  const handleOpenblock95 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistsss(
          data9five.TN,
          data9five.AN,
          data9five.date,
          data9five.time,
          data9five.provider
        );
      }
    });
  };
  //five

  //four

  const [data9four, setData9four] = useState([]);
  const [data9four2, setData9four2] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [status94, setStatus94] = useState("ว่าง");
  const [cfer94, setCfer94] = useState(true);
  const [cancel94, setCancel94] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "ชาย",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata94 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData9four(data9one4[1]);
              setEditButton94(true);
              setIcon94(<EventBusy />);
              setStatus94("ไม่ว่าง");
              setCfer94(true);
              setCancel94(true);
              return;
            }
            setData9four(data9one4[0]);
            setEditButton94(false);
            setIcon94(<EventAvailable />);
            setStatus94("ว่างงง");
            setCancel94(true);
            setCfer94(true);
            return;
          } else if (res.data.length >= 1) {
            setData9four(res.data[0]);
            setEditButton94(true);
            setIcon94(<EventBusy />);
            setStatus94("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata94[0] === 1 || resdata94[0] === 2) {
                setCfer94(true);
                setCancel94(true);
                return;
              } else if (resdata94[0] > 2 && resdata94[0] < 10) {
                setCfer94(true);
                setCancel94(false);
                return;
              } else if (resdata94[0] > 10) {
                setCfer94(false);
                setCancel94(false);
                return;
              } else {
                setCfer94(false);
                setCancel94(false);
                return;
              }
            } else {
              setCfer94(true);
              setCancel94(false);
              return;
            }
          }
        });
      } else {
        setData9four(data9one4[2]);
        setEditButton94(true);
        setIcon94(<EventBusy />);
        setStatus94("ไม่ว่าง");
        setCfer94(true);
        setCancel94(true);
        return;
      }
    });
  }, [startDate, data9four2, URL]);

  //Modalsearch

  const [selecttn94, setSelecttn94] = useState("");
  const [selectname94, setSelectname94] = useState("");
  const [service94, setService94] = useState("");
  const handleChange94 = (event) => {
    setService94(event.target.value);
  };

  const selectpatient94 = (TN, prefix, fname, lname) => {
    setSelecttn94(TN);
    setSelectname94(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment94 = () => {
    var data = JSON.stringify({
      TN: selecttn94,
      date: data9four.date,
      time: data9four.time,
      service: service94,
      provider: data9four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck94();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen94(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck94 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9four2(res.data[0]);
    });
  };

  const [open94, setOpen94] = useState(false);
  const handleOpen94 = () => setOpen94(true);
  const handleClose94 = () => setOpen94(false);

  const handleOpenin94 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen94(false);
            recheck94()
          }
        });
        return;
      } else if (!service94 || !selecttn94) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment94();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm94 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistman(
          data9four.TN,
          data9four.AN,
          data9four.date,
          data9four.time,
          data9four.provider
        );
      }
    });
  };

  const handleOpencancel94 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data9four.AN);
      }
    });
  };

  const handleOpenblock94 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistman(
          data9four.TN,
          data9four.AN,
          data9four.date,
          data9four.time,
          data9four.provider
        );
      }
    });
  };
  //four

  //09:00

  //10:00
  //one
  const [data10one, setData10one] = useState([]);
  const [data10one2, setData10one2] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [status101, setStatus101] = useState("ว่าง");
  const [cfer101, setCfer101] = useState(true);
  const [cancel101, setCancel101] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata101 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            setData10one(data10one1[0]);
            setEditButton101(false);
            setIcon101(<EventAvailable />);
            setStatus101("ว่างงง");
            setCancel101(true);
            setCfer101(true);
            return;
          } else if (res.data.length >= 1) {
            setData10one(res.data[0]);
            setEditButton101(true);
            setIcon101(<EventBusy />);
            setStatus101("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata101[0] === 1 || resdata101[0] === 2) {
                setCfer101(true);
                setCancel101(true);
                return;
              } else if (resdata101[0] > 2 && resdata101[0] < 10) {
                setCfer101(true);
                setCancel101(false);
                return;
              } else if (resdata101[0] > 10) {
                setCfer101(false);
                setCancel101(false);
                return;
              } else {
                setCfer101(false);
                setCancel101(false);
                return;
              }
            } else {
              setCfer101(true);
              setCancel101(false);
              return;
            }
          }
        });
      } else {
        setData10one(data10one1[2]);
        setEditButton101(true);
        setIcon101(<EventBusy />);
        setStatus101("ไม่ว่าง");
        setCfer101(true);
        setCancel101(true);
        return;
      }
    });
  }, [startDate, data10one2, URL]);

  //Modalsearch

  const [selecttn101, setSelecttn101] = useState("");
  const [selectname101, setSelectname101] = useState("");
  const [service101, setService101] = useState("");
  const handleChange101 = (event) => {
    setService101(event.target.value);
  };

  const selectpatient101 = (TN, prefix, fname, lname) => {
    setSelecttn101(TN);
    setSelectname101(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment101 = () => {
    var data = JSON.stringify({
      TN: selecttn101,
      date: data10one.date,
      time: data10one.time,
      service: service101,
      provider: data10one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 2500,
        }).then((result) => {
          recheck101();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen101(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck101 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10one2(res.data[0]);
    });
  };

  const [open101, setOpen101] = useState(false);
  const handleOpen101 = () => setOpen101(true);
  const handleClose101 = () => setOpen101(false);

  const handleOpenin101 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen101(false);
            recheck101()
          }
        });
        return;
      } else if (!service101 || !selecttn101) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment101();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm101 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlist(
          data10one.TN,
          data10one.AN,
          data10one.date,
          data10one.time,
          data10one.provider
        );
      }
    });
  };

  const handleOpencancel101 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data10one.AN);
      }
    });
  };

  const handleOpenblock101 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklist(
          data10one.TN,
          data10one.AN,
          data10one.date,
          data10one.time,
          data10one.provider
        );
      }
    });
  };
  //one

  //two
  const [data10two, setData10two] = useState([]);
  const [data10two2, setData10two2] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [status102, setStatus102] = useState("ว่าง");
  const [cfer102, setCfer102] = useState(true);
  const [cancel102, setCancel102] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata102 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            setData10two(data10one2[0]);
            setEditButton102(false);
            setIcon102(<EventAvailable />);
            setStatus102("ว่างงง");
            setCancel102(true);
            setCfer102(true);
            return;
          } else if (res.data.length >= 1) {
            setData10two(res.data[0]);
            setEditButton102(true);
            setIcon102(<EventBusy />);
            setStatus102("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata102[0] === 1 || resdata102[0] === 2) {
                setCfer102(true);
                setCancel102(true);
                return;
              } else if (resdata102[0] > 2 && resdata102[0] < 10) {
                setCfer102(true);
                setCancel102(false);
                return;
              } else if (resdata102[0] > 10) {
                setCfer102(false);
                setCancel102(false);
                return;
              } else {
                setCfer102(false);
                setCancel102(false);
                return;
              }
            } else {
              setCfer102(true);
              setCancel102(false);
              return;
            }
          }
        });
      } else {
        setData10two(data10one2[2]);
        setEditButton102(true);
        setIcon102(<EventBusy />);
        setStatus102("ไม่ว่าง");
        setCfer102(true);
        setCancel102(true);
        return;
      }
    });
  }, [startDate, data10two2, URL]);

  //Modalsearch

  const [selecttn102, setSelecttn102] = useState("");
  const [selectname102, setSelectname102] = useState("");
  const [service102, setService102] = useState("");
  const handleChange102 = (event) => {
    setService102(event.target.value);
  };

  const selectpatient102 = (TN, prefix, fname, lname) => {
    setSelecttn102(TN);
    setSelectname102(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment102 = () => {
    var data = JSON.stringify({
      TN: selecttn102,
      date: data10two.date,
      time: data10two.time,
      service: service102,
      provider: data10two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck102();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen102(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck102 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10two2(res.data[0]);
    });
  };

  const [open102, setOpen102] = useState(false);
  const handleOpen102 = () => setOpen102(true);
  const handleClose102 = () => setOpen102(false);

  const handleOpenin102 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen102(false);
            recheck102()
          }
        });
        return;
      } else if (!service102 || !selecttn102) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment102();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm102 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlists(
          data10two.TN,
          data10two.AN,
          data10two.date,
          data10two.time,
          data10two.provider
        );
      }
    });
  };

  const handleOpencancel102 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data10two.AN);
      }
    });
  };

  const handleOpenblock102 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklists(
          data10two.TN,
          data10two.AN,
          data10two.date,
          data10two.time,
          data10two.provider
        );
      }
    });
  };

  //two

  //tree
  const [data10tree, setData10tree] = useState([]);
  const [data10tree2, setData10tree2] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [status103, setStatus103] = useState("ว่าง");
  const [cfer103, setCfer103] = useState(true);
  const [cancel103, setCancel103] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata103 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData10tree(data10one3[1]);
              setEditButton103(true);
              setIcon103(<EventBusy />);
              setStatus103("ไม่ว่าง");
              setCfer103(true);
              setCancel103(true);
              return;
            }
            setData10tree(data10one3[0]);
            setEditButton103(false);
            setIcon103(<EventAvailable />);
            setStatus103("ว่างงง");
            setCancel103(true);
            setCfer103(true);
            return;
          } else if (res.data.length >= 1) {
            setData10tree(res.data[0]);
            setEditButton103(true);
            setIcon103(<EventBusy />);
            setStatus103("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata103[0] === 1 || resdata103[0] === 2) {
                setCfer103(true);
                setCancel103(true);
                return;
              } else if (resdata103[0] > 2 && resdata103[0] < 10) {
                setCfer103(true);
                setCancel103(false);
                return;
              } else if (resdata103[0] > 10) {
                setCfer103(false);
                setCancel103(false);
                return;
              } else {
                setCfer103(false);
                setCancel103(false);
                return;
              }
            } else {
              setCfer103(true);
              setCancel103(false);
              return;
            }
          }
        });
      } else {
        setData10tree(data10one3[2]);
        setEditButton103(true);
        setIcon103(<EventBusy />);
        setStatus103("ไม่ว่าง");
        setCfer103(true);
        setCancel103(true);
        return;
      }
    });
  }, [startDate, data10tree2, URL]);

  //Modalsearch

  const [selecttn103, setSelecttn103] = useState("");
  const [selectname103, setSelectname103] = useState("");
  const [service103, setService103] = useState("");
  const handleChange103 = (event) => {
    setService103(event.target.value);
  };

  const selectpatient103 = (TN, prefix, fname, lname) => {
    setSelecttn103(TN);
    setSelectname103(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment103 = () => {
    var data = JSON.stringify({
      TN: selecttn103,
      date: data10tree.date,
      time: data10tree.time,
      service: service103,
      provider: data10tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck103();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen103(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck103 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10tree2(res.data[0]);
    });
  };

  const [open103, setOpen103] = useState(false);
  const handleOpen103 = () => setOpen103(true);
  const handleClose103 = () => setOpen103(false);

  const handleOpenin103 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen103(false);
            recheck103()
          }
        });
        return;
      } else if (!service103 || !selecttn103) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment103();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm103 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistss(
          data10tree.TN,
          data10tree.AN,
          data10tree.date,
          data10tree.time,
          data10tree.provider
        );
      }
    });
  };

  const handleOpencancel103 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data10tree.AN);
      }
    });
  };

  const handleOpenblock103 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistss(
          data10tree.TN,
          data10tree.AN,
          data10tree.date,
          data10tree.time,
          data10tree.provider
        );
      }
    });
  };

  //tree


  //five

  const [data10five, setData10five] = useState([]);
  const [data10five2, setData10five2] = useState([]);
  const [editButton105, setEditButton105] = useState(false);
  const [icon105, setIcon105] = useState(<EventAvailable />);
  const [status105, setStatus105] = useState("ว่าง");
  const [cfer105, setCfer105] = useState(true);
  const [cancel105, setCancel105] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one5 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentsss/10/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata105 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData10five(data10one5[1]);
              setEditButton105(true);
              setIcon105(<EventBusy />);
              setStatus105("ไม่ว่าง");
              setCfer105(true);
              setCancel105(true);
              return;
            }
            setData10five(data10one5[0]);
            setEditButton105(false);
            setIcon105(<EventAvailable />);
            setStatus105("ว่างงง");
            setCancel105(true);
            setCfer105(true);
            return;
          } else if (res.data.length >= 1) {
            setData10five(res.data[0]);
            setEditButton105(true);
            setIcon105(<EventBusy />);
            setStatus105("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata105[0] === 1 || resdata105[0] === 2) {
                setCfer105(true);
                setCancel105(true);
                return;
              } else if (resdata105[0] > 2 && resdata105[0] < 10) {
                setCfer105(true);
                setCancel105(false);
                return;
              } else if (resdata105[0] > 10) {
                setCfer105(false);
                setCancel105(false);
                return;
              } else {
                setCfer105(false);
                setCancel105(false);
                return;
              }
            } else {
              setCfer105(true);
              setCancel105(false);
              return;
            }
          }
        });
      } else {
        setData10five(data10one5[2]);
        setEditButton105(true);
        setIcon105(<EventBusy />);
        setStatus105("ไม่ว่าง");
        setCfer105(true);
        setCancel105(true);
        return;
      }
    });
  }, [startDate, data10five2, URL]);

  //Modalsearch

  const [selecttn105, setSelecttn105] = useState("");
  const [selectname105, setSelectname105] = useState("");
  const [service105, setService105] = useState("");
  const handleChange105 = (event) => {
    setService105(event.target.value);
  };

  const selectpatient105 = (TN, prefix, fname, lname) => {
    setSelecttn105(TN);
    setSelectname105(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment105 = () => {
    var data = JSON.stringify({
      TN: selecttn105,
      date: data10five.date,
      time: data10five.time,
      service: service105,
      provider: data10five.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentssss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck105();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen105(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck105 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10five2(res.data[0]);
    });
  };

  const [open105, setOpen105] = useState(false);
  const handleOpen105 = () => setOpen105(true);
  const handleClose105 = () => setOpen105(false);

  const handleOpenin105 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen105(false);
            recheck105()
          }
        });
        return;
      } else if (!service105 || !selecttn105) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment105();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm105 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistsss(
          data10five.TN,
          data10five.AN,
          data10five.date,
          data10five.time,
          data10five.provider
        );
      }
    });
  };

  const handleOpencancel105 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatasss(data10five.AN);
      }
    });
  };

  const handleOpenblock105 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistsss(
          data10five.TN,
          data10five.AN,
          data10five.date,
          data10five.time,
          data10five.provider
        );
      }
    });
  };
  //five

  //four

  const [data10four, setData10four] = useState([]);
  const [data10four2, setData10four2] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [status104, setStatus104] = useState("ว่าง");
  const [cfer104, setCfer104] = useState(true);
  const [cancel104, setCancel104] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "ชาย",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata104 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData10four(data10one4[1]);
              setEditButton104(true);
              setIcon104(<EventBusy />);
              setStatus104("ไม่ว่าง");
              setCfer104(true);
              setCancel104(true);
              return;
            }
            setData10four(data10one4[0]);
            setEditButton104(false);
            setIcon104(<EventAvailable />);
            setStatus104("ว่างงง");
            setCancel104(true);
            setCfer104(true);
            return;
          } else if (res.data.length >= 1) {
            setData10four(res.data[0]);
            setEditButton104(true);
            setIcon104(<EventBusy />);
            setStatus104("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata104[0] === 1 || resdata104[0] === 2) {
                setCfer104(true);
                setCancel104(true);
                return;
              } else if (resdata104[0] > 2 && resdata104[0] < 10) {
                setCfer104(true);
                setCancel104(false);
                return;
              } else if (resdata104[0] > 10) {
                setCfer104(false);
                setCancel104(false);
                return;
              } else {
                setCfer104(false);
                setCancel104(false);
                return;
              }
            } else {
              setCfer104(true);
              setCancel104(false);
              return;
            }
          }
        });
      } else {
        setData10four(data10one4[2]);
        setEditButton104(true);
        setIcon104(<EventBusy />);
        setStatus104("ไม่ว่าง");
        setCfer104(true);
        setCancel104(true);
        return;
      }
    });
  }, [startDate, data10four2, URL]);

  //Modalsearch

  const [selecttn104, setSelecttn104] = useState("");
  const [selectname104, setSelectname104] = useState("");
  const [service104, setService104] = useState("");
  const handleChange104 = (event) => {
    setService104(event.target.value);
  };

  const selectpatient104 = (TN, prefix, fname, lname) => {
    setSelecttn104(TN);
    setSelectname104(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment104 = () => {
    var data = JSON.stringify({
      TN: selecttn104,
      date: data10four.date,
      time: data10four.time,
      service: service104,
      provider: data10four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck104();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen104(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck104 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10four2(res.data[0]);
    });
  };

  const [open104, setOpen104] = useState(false);
  const handleOpen104 = () => setOpen104(true);
  const handleClose104 = () => setOpen104(false);

  const handleOpenin104 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen104(false);
            recheck104()
          }
        });
        return;
      } else if (!service104 || !selecttn104) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment104();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm104 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistman(
          data10four.TN,
          data10four.AN,
          data10four.date,
          data10four.time,
          data10four.provider
        );
      }
    });
  };

  const handleOpencancel104 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data10four.AN);
      }
    });
  };

  const handleOpenblock104 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistman(
          data10four.TN,
          data10four.AN,
          data10four.date,
          data10four.time,
          data10four.provider
        );
      }
    });
  };
  //four

  //10:00

  //13:30

  //one
  const [data13one, setData13one] = useState([]);
  const [data13one2, setData13one2] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [status131, setStatus131] = useState("ว่าง");
  const [cfer131, setCfer131] = useState(true);
  const [cancel131, setCancel131] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata131 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            setData13one(data13one1[0]);
            setEditButton131(false);
            setIcon131(<EventAvailable />);
            setStatus131("ว่างงง");
            setCancel131(true);
            setCfer131(true);
            return;
          } else if (res.data.length >= 1) {
            setData13one(res.data[0]);
            setEditButton131(true);
            setIcon131(<EventBusy />);
            setStatus131("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata131[0] === 1 || resdata131[0] === 2) {
                setCfer131(true);
                setCancel131(true);
                return;
              } else if (resdata131[0] > 2 && resdata131[0] < 10) {
                setCfer131(true);
                setCancel131(false);
                return;
              } else if (resdata131[0] > 10) {
                setCfer131(false);
                setCancel131(false);
                return;
              } else {
                setCfer131(false);
                setCancel131(false);
                return;
              }
            } else {
              setCfer131(true);
              setCancel131(false);
              return;
            }
          }
        });
      } else {
        setData13one(data13one1[2]);
        setEditButton131(true);
        setIcon131(<EventBusy />);
        setStatus131("ไม่ว่าง");
        setCfer131(true);
        setCancel131(true);
        return;
      }
    });
  }, [startDate, data13one2, URL]);

  //Modalsearch

  const [selecttn131, setSelecttn131] = useState("");
  const [selectname131, setSelectname131] = useState("");
  const [service131, setService131] = useState("");
  const handleChange131 = (event) => {
    setService131(event.target.value);
  };

  const selectpatient131 = (TN, prefix, fname, lname) => {
    setSelecttn131(TN);
    setSelectname131(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment131 = () => {
    var data = JSON.stringify({
      TN: selecttn131,
      date: data13one.date,
      time: data13one.time,
      service: service131,
      provider: data13one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck131();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen131(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck131 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13one2(res.data[0]);
    });
  };

  const [open131, setOpen131] = useState(false);
  const handleOpen131 = () => setOpen131(true);
  const handleClose131 = () => setOpen131(false);

  const handleOpenin131 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen131(false);
            recheck131()
          }
        });
        return;
      } else if (!service131 || !selecttn131) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment131();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm131 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlist(
          data13one.TN,
          data13one.AN,
          data13one.date,
          data13one.time,
          data13one.provider
        );
      }
    });
  };

  const handleOpencancel131 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data13one.AN);
      }
    });
  };

  const handleOpenblock131 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklist(
          data13one.TN,
          data13one.AN,
          data13one.date,
          data13one.time,
          data13one.provider
        );
      }
    });
  };

  //one

  //two
  const [data13two, setData13two] = useState([]);
  const [data13two2, setData13two2] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [status132, setStatus132] = useState("ว่าง");
  const [cfer132, setCfer132] = useState(true);
  const [cancel132, setCancel132] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata132 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            setData13two(data13one2[0]);
            setEditButton132(false);
            setIcon132(<EventAvailable />);
            setStatus132("ว่างงง");
            setCancel132(true);
            setCfer132(true);
            return;
          } else if (res.data.length >= 1) {
            setData13two(res.data[0]);
            setEditButton132(true);
            setIcon132(<EventBusy />);
            setStatus132("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata132[0] === 1 || resdata132[0] === 2) {
                setCfer132(true);
                setCancel132(true);
                return;
              } else if (resdata132[0] > 2 && resdata132[0] < 10) {
                setCfer132(true);
                setCancel132(false);
                return;
              } else if (resdata132[0] > 10) {
                setCfer132(false);
                setCancel132(false);
                return;
              } else {
                setCfer132(false);
                setCancel132(false);
                return;
              }
            } else {
              setCfer132(true);
              setCancel132(false);
              return;
            }
          }
        });
      } else {
        setData13two(data13one2[2]);
        setEditButton132(true);
        setIcon132(<EventBusy />);
        setStatus132("ไม่ว่าง");
        setCfer132(true);
        setCancel132(true);
        return;
      }
    });
  }, [startDate, data13two2, URL]);

  //Modalsearch

  const [selecttn132, setSelecttn132] = useState("");
  const [selectname132, setSelectname132] = useState("");
  const [service132, setService132] = useState("");
  const handleChange132 = (event) => {
    setService132(event.target.value);
  };

  const selectpatient132 = (TN, prefix, fname, lname) => {
    setSelecttn132(TN);
    setSelectname132(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment132 = () => {
    var data = JSON.stringify({
      TN: selecttn132,
      date: data13two.date,
      time: data13two.time,
      service: service132,
      provider: data13two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck132();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen132(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck132 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13two2(res.data[0]);
    });
  };

  const [open132, setOpen132] = useState(false);
  const handleOpen132 = () => setOpen132(true);
  const handleClose132 = () => setOpen132(false);

  const handleOpenin132 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen132(false);
            recheck132()
          }
        });
        return;
      } else if (!service132 || !selecttn132) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment132();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm132 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlists(
          data13two.TN,
          data13two.AN,
          data13two.date,
          data13two.time,
          data13two.provider
        );
      }
    });
  };

  const handleOpencancel132 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data13two.AN);
      }
    });
  };

  const handleOpenblock132 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklists(
          data13two.TN,
          data13two.AN,
          data13two.date,
          data13two.time,
          data13two.provider
        );
      }
    });
  };

  //two

  //tree
  const [data13tree, setData13tree] = useState([]);
  const [data13tree2, setData13tree2] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [status133, setStatus133] = useState("ว่าง");
  const [cfer133, setCfer133] = useState(true);
  const [cancel133, setCancel133] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata133 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData13tree(data13one3[1]);
              setEditButton133(true);
              setIcon133(<EventBusy />);
              setStatus133("ไม่ว่าง");
              setCfer133(true);
              setCancel133(true);
              return;
            }
            setData13tree(data13one3[0]);
            setEditButton133(false);
            setIcon133(<EventAvailable />);
            setStatus133("ว่างงง");
            setCancel133(true);
            setCfer133(true);
            return;
          } else if (res.data.length >= 1) {
            setData13tree(res.data[0]);
            setEditButton133(true);
            setIcon133(<EventAvailable />);
            setStatus133("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata133[0] === 1 || resdata133[0] === 2) {
                setCfer133(true);
                setCancel133(true);
                return;
              } else if (resdata133[0] > 2 && resdata133[0] < 10) {
                setCfer133(true);
                setCancel133(false);
                return;
              } else if (resdata133[0] > 10) {
                setCfer133(false);
                setCancel133(false);
                return;
              } else {
                setCfer133(false);
                setCancel133(false);
                return;
              }
            } else {
              setCfer133(true);
              setCancel133(false);
              return;
            }
          }
        });
      } else {
        setData13tree(data13one3[2]);
        setEditButton133(true);
        setIcon133(<EventBusy />);
        setStatus133("ไม่ว่าง");
        setCfer133(true);
        setCancel133(true);
        return;
      }
    });
  }, [startDate, data13tree2, URL]);

  //Modalsearch

  const [selecttn133, setSelecttn133] = useState("");
  const [selectname133, setSelectname133] = useState("");
  const [service133, setService133] = useState("");
  const handleChange133 = (event) => {
    setService133(event.target.value);
  };

  const selectpatient133 = (TN, prefix, fname, lname) => {
    setSelecttn133(TN);
    setSelectname133(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment133 = () => {
    var data = JSON.stringify({
      TN: selecttn133,
      date: data13tree.date,
      time: data13tree.time,
      service: service133,
      provider: data13tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck133();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen133(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck133 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13tree2(res.data[0]);
    });
  };

  const [open133, setOpen133] = useState(false);
  const handleOpen133 = () => setOpen133(true);
  const handleClose133 = () => setOpen133(false);

  const handleOpenin133 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen133(false);
            recheck133()
          }
        });
        return;
      } else if (!service133 || !selecttn133) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment133();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm133 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistss(
          data13tree.TN,
          data13tree.AN,
          data13tree.date,
          data13tree.time,
          data13tree.provider
        );
      }
    });
  };

  const handleOpencancel133 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data13tree.AN);
      }
    });
  };

  const handleOpenblock133 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistss(
          data13tree.TN,
          data13tree.AN,
          data13tree.date,
          data13tree.time,
          data13tree.provider
        );
      }
    });
  };

  //tree

  //five
  const [data13five, setData13five] = useState([]);
  const [data13five2, setData13five2] = useState([]);
  const [editButton135, setEditButton135] = useState(false);
  const [icon135, setIcon135] = useState(<EventAvailable />);
  const [status135, setStatus135] = useState("ว่าง");
  const [cfer135, setCfer135] = useState(true);
  const [cancel135, setCancel135] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one5 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentsss/13/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata135 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData13five(data13one5[1]);
              setEditButton135(true);
              setIcon135(<EventBusy />);
              setStatus135("ไม่ว่าง");
              setCfer135(true);
              setCancel135(true);
              return;
            }
            setData13five(data13one5[0]);
            setEditButton135(false);
            setIcon135(<EventAvailable />);
            setStatus135("ว่างงง");
            setCancel135(true);
            setCfer135(true);
            return;
          } else if (res.data.length >= 1) {
            setData13five(res.data[0]);
            setEditButton135(true);
            setIcon135(<EventAvailable />);
            setStatus135("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata135[0] === 1 || resdata135[0] === 2) {
                setCfer135(true);
                setCancel135(true);
                return;
              } else if (resdata135[0] > 2 && resdata135[0] < 10) {
                setCfer135(true);
                setCancel135(false);
                return;
              } else if (resdata135[0] > 10) {
                setCfer135(false);
                setCancel135(false);
                return;
              } else {
                setCfer135(false);
                setCancel135(false);
                return;
              }
            } else {
              setCfer135(true);
              setCancel135(false);
              return;
            }
          }
        });
      } else {
        setData13five(data13one5[2]);
        setEditButton135(true);
        setIcon135(<EventBusy />);
        setStatus135("ไม่ว่าง");
        setCfer135(true);
        setCancel135(true);
        return;
      }
    });
  }, [startDate, data13five2, URL]);

  //Modalsearch

  const [selecttn135, setSelecttn135] = useState("");
  const [selectname135, setSelectname135] = useState("");
  const [service135, setService135] = useState("");
  const handleChange135 = (event) => {
    setService135(event.target.value);
  };

  const selectpatient135 = (TN, prefix, fname, lname) => {
    setSelecttn135(TN);
    setSelectname135(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment135 = () => {
    var data = JSON.stringify({
      TN: selecttn135,
      date: data13five.date,
      time: data13five.time,
      service: service135,
      provider: data13five.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentssss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck135();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen135(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck135 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13five2(res.data[0]);
    });
  };

  const [open135, setOpen135] = useState(false);
  const handleOpen135 = () => setOpen135(true);
  const handleClose135 = () => setOpen135(false);

  const handleOpenin135 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen135(false);
            recheck135()
          }
        });
        return;
      } else if (!service135 || !selecttn135) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment135();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm135 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistsss(
          data13five.TN,
          data13five.AN,
          data13five.date,
          data13five.time,
          data13five.provider
        );
      }
    });
  };

  const handleOpencancel135 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatasss(data13five.AN);
      }
    });
  };

  const handleOpenblock135 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistsss(
          data13five.TN,
          data13five.AN,
          data13five.date,
          data13five.time,
          data13five.provider
        );
      }
    });
  };

  //five

  //four

  const [data13four, setData13four] = useState([]);
  const [data13four2, setData13four2] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [status134, setStatus134] = useState("ว่าง");
  const [cfer134, setCfer134] = useState(true);
  const [cancel134, setCancel134] = useState(true);

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "ชาย",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata134 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData13four(data13one4[1]);
              setEditButton134(true);
              setIcon134(<EventBusy />);
              setStatus134("ไม่ว่าง");
              setCfer134(true);
              setCancel134(true);
              return;
            }
            setData13four(data13one4[0]);
            setEditButton134(false);
            setIcon134(<EventAvailable />);
            setStatus134("ว่างงง");
            setCancel134(true);
            setCfer134(true);
            return;
          } else if (res.data.length >= 1) {
            setData13four(res.data[0]);
            setEditButton134(true);
            setIcon134(<EventBusy />);
            setStatus134("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata134[0] === 1 || resdata134[0] === 2) {
                setCfer134(true);
                setCancel134(true);
                return;
              } else if (resdata134[0] > 2 && resdata134[0] < 10) {
                setCfer134(true);
                setCancel134(false);
                return;
              } else if (resdata134[0] > 10) {
                setCfer134(false);
                setCancel134(false);
                return;
              } else {
                setCfer134(false);
                setCancel134(false);
                return;
              }
            } else {
              setCfer134(true);
              setCancel134(false);
              return;
            }
          }
        });
      } else {
        setData13four(data13one4[2]);
        setEditButton134(true);
        setIcon134(<EventBusy />);
        setStatus134("ไม่ว่าง");
        setCfer134(true);
        setCancel134(true);
        return;
      }
    });
  }, [startDate, data13four2, URL]);

  //Modalsearch

  const [selecttn134, setSelecttn134] = useState("");
  const [selectname134, setSelectname134] = useState("");
  const [service134, setService134] = useState("");
  const handleChange134 = (event) => {
    setService134(event.target.value);
  };

  const selectpatient134 = (TN, prefix, fname, lname) => {
    setSelecttn134(TN);
    setSelectname134(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment134 = () => {
    var data = JSON.stringify({
      TN: selecttn134,
      date: data13four.date,
      time: data13four.time,
      service: service134,
      provider: data13four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck134();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen134(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck134 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13four2(res.data[0]);
    });
  };

  const [open134, setOpen134] = useState(false);
  const handleOpen134 = () => setOpen134(true);
  const handleClose134 = () => setOpen134(false);

  const handleOpenin134 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen134(false);
            recheck134()
          }
        });
        return;
      } else if (!service134 || !selecttn134) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment134();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm134 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistman(
          data13four.TN,
          data13four.AN,
          data13four.date,
          data13four.time,
          data13four.provider
        );
      }
    });
  };

  const handleOpencancel134 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data13four.AN);
      }
    });
  };

  const handleOpenblock134 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistman(
          data13four.TN,
          data13four.AN,
          data13four.date,
          data13four.time,
          data13four.provider
        );
      }
    });
  };

  //four

  //13:30

  //14:45
  //one
  const [data14one, setData14one] = useState([]);
  const [data14one2, setData14one2] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [status141, setStatus141] = useState("ว่าง");
  const [cfer141, setCfer141] = useState(true);
  const [cancel141, setCancel141] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata141 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData14one(data14one1[1]);
              setEditButton141(true);
              setIcon141(<EventBusy />);
              setStatus141("ไม่ว่าง");
              setCfer141(true);
              setCancel141(true);
              return;
            }
            setData14one(data14one1[0]);
            setEditButton141(false);
            setIcon141(<EventAvailable />);
            setStatus141("ว่างงง");
            setCancel141(true);
            setCfer141(true);
            return;
          } else if (res.data.length >= 1) {
            setData14one(res.data[0]);
            setEditButton141(true);
            setIcon141(<EventBusy />);
            setStatus141("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata141[0] === 1 || resdata141[0] === 2) {
                setCfer141(true);
                setCancel141(true);
                return;
              } else if (resdata141[0] > 2 && resdata141[0] < 10) {
                setCfer141(true);
                setCancel141(false);
                return;
              } else if (resdata141[0] > 10) {
                setCfer141(false);
                setCancel141(false);
                return;
              } else {
                setCfer141(false);
                setCancel141(false);
                return;
              }
            } else {
              setCfer141(true);
              setCancel141(false);
              return;
            }
          }
        });
      } else {
        setData14one(data14one1[2]);
        setEditButton141(true);
        setIcon141(<EventBusy />);
        setStatus141("ไม่ว่าง");
        setCfer141(true);
        setCancel141(true);
        return;
      }
    });
  }, [startDate, data14one2, URL]);

  //Modalsearch

  const [selecttn141, setSelecttn141] = useState("");
  const [selectname141, setSelectname141] = useState("");
  const [service141, setService141] = useState("");
  const handleChange141 = (event) => {
    setService141(event.target.value);
  };

  const selectpatient141 = (TN, prefix, fname, lname) => {
    setSelecttn141(TN);
    setSelectname141(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment141 = () => {
    var data = JSON.stringify({
      TN: selecttn141,
      date: data14one.date,
      time: data14one.time,
      service: service141,
      provider: data14one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck141();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen141(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck141 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14one2(res.data[0]);
    });
  };

  const [open141, setOpen141] = useState(false);
  const handleOpen141 = () => setOpen141(true);
  const handleClose141 = () => setOpen141(false);

  const handleOpenin141 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen141(false);
            recheck141()
          }
        });
        return;
      } else if (!service141 || !selecttn141) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment141();
        }
      });
    });
  };

  //Modalsearch
  const handleOpenconfirm141 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlist(
          data14one.TN,
          data14one.AN,
          data14one.date,
          data14one.time,
          data14one.provider
        );
      }
    });
  };

  const handleOpencancel141 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data14one.AN);
      }
    });
  };

  const handleOpenblock141 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklist(
          data14one.TN,
          data14one.AN,
          data14one.date,
          data14one.time,
          data14one.provider
        );
      }
    });
  };

  //one

  //two
  const [data14two, setData14two] = useState([]);
  const [data14two2, setData14two2] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [status142, setStatus142] = useState("ว่าง");
  const [cfer142, setCfer142] = useState(true);
  const [cancel142, setCancel142] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata142 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData14two(data14one2[1]);
              setEditButton142(true);
              setIcon142(<EventBusy />);
              setStatus142("ไม่ว่าง");
              setCfer142(true);
              setCancel142(true);
              return;
            }
            setData14two(data14one2[0]);
            setEditButton142(false);
            setIcon142(<EventAvailable />);
            setStatus142("ว่างงง");
            setCancel142(true);
            setCfer142(true);
            return;
          } else if (res.data.length >= 1) {
            setData14two(res.data[0]);
            setEditButton142(true);
            setIcon142(<EventBusy />);
            setStatus142("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata142[0] === 1 || resdata142[0] === 2) {
                setCfer142(true);
                setCancel142(true);
                return;
              } else if (resdata142[0] > 2 && resdata142[0] < 10) {
                setCfer142(true);
                setCancel142(false);
                return;
              } else if (resdata142[0] > 10) {
                setCfer142(false);
                setCancel142(false);
                return;
              } else {
                setCfer142(false);
                setCancel142(false);
                return;
              }
            } else {
              setCfer142(true);
              setCancel142(false);
              return;
            }
          }
        });
      } else {
        setData14two(data14one2[2]);
        setEditButton142(true);
        setIcon142(<EventBusy />);
        setStatus142("ไม่ว่าง");
        setCfer142(true);
        setCancel142(true);
        return;
      }
    });
  }, [startDate, data14two2, URL]);

  //Modalsearch

  const [selecttn142, setSelecttn142] = useState("");
  const [selectname142, setSelectname142] = useState("");
  const [service142, setService142] = useState("");
  const handleChange142 = (event) => {
    setService142(event.target.value);
  };

  const selectpatient142 = (TN, prefix, fname, lname) => {
    setSelecttn142(TN);
    setSelectname142(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment142 = () => {
    var data = JSON.stringify({
      TN: selecttn142,
      date: data14two.date,
      time: data14two.time,
      service: service142,
      provider: data14two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck142();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen142(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck142 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14two2(res.data[0]);
    });
  };

  const [open142, setOpen142] = useState(false);
  const handleOpen142 = () => setOpen142(true);
  const handleClose142 = () => setOpen142(false);

  const handleOpenin142 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen142(false);
            recheck142()
          }
        });
        return;
      } else if (!service142 || !selecttn142) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment142();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm142 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlists(
          data14two.TN,
          data14two.AN,
          data14two.date,
          data14two.time,
          data14two.provider
        );
      }
    });
  };

  const handleOpencancel142 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data14two.AN);
      }
    });
  };

  const handleOpenblock142 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklists(
          data14two.TN,
          data14two.AN,
          data14two.date,
          data14two.time,
          data14two.provider
        );
      }
    });
  };

  //two

  //tree
  const [data14tree, setData14tree] = useState([]);
  const [data14tree2, setData14tree2] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [status143, setStatus143] = useState("ว่าง");
  const [cfer143, setCfer143] = useState(true);
  const [cancel143, setCancel143] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata143 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData14tree(data14one3[1]);
              setEditButton143(true);
              setIcon143(<EventBusy />);
              setStatus143("ไม่ว่าง");
              setCfer143(true);
              setCancel143(true);
              return;
            }
            setData14tree(data14one3[0]);
            setEditButton143(false);
            setIcon143(<EventAvailable />);
            setStatus143("ว่างงง");
            setCancel143(true);
            setCfer143(true);
            return;
          } else if (res.data.length >= 1) {
            setData14tree(res.data[0]);
            setEditButton143(true);
            setIcon143(<EventBusy />);
            setStatus143("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata143[0] === 1 || resdata143[0] === 2) {
                setCfer143(true);
                setCancel143(true);
                return;
              } else if (resdata143[0] > 2 && resdata143[0] < 10) {
                setCfer143(true);
                setCancel143(false);
                return;
              } else if (resdata143[0] > 10) {
                setCfer143(false);
                setCancel143(false);
                return;
              } else {
                setCfer143(false);
                setCancel143(false);
                return;
              }
            } else {
              setCfer143(true);
              setCancel143(false);
              return;
            }
          }
        });
      } else {
        setData14tree(data14one3[2]);
        setEditButton143(true);
        setIcon143(<EventBusy />);
        setStatus143("ไม่ว่าง");
        setCfer143(true);
        setCancel143(true);
        return;
      }
    });
  }, [startDate, data14tree2, URL]);

  //Modalsearch

  const [selecttn143, setSelecttn143] = useState("");
  const [selectname143, setSelectname143] = useState("");
  const [service143, setService143] = useState("");
  const handleChange143 = (event) => {
    setService143(event.target.value);
  };

  const selectpatient143 = (TN, prefix, fname, lname) => {
    setSelecttn143(TN);
    setSelectname143(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment143 = () => {
    var data = JSON.stringify({
      TN: selecttn143,
      date: data14tree.date,
      time: data14tree.time,
      service: service143,
      provider: data14tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck143();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen143(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck143 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14tree2(res.data[0]);
    });
  };

  const [open143, setOpen143] = useState(false);
  const handleOpen143 = () => setOpen143(true);
  const handleClose143 = () => setOpen143(false);

  const handleOpenin143 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen143(false);
            recheck143()
          }
        });
        return;
      } else if (!service143 || !selecttn143) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment143();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm143 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistss(
          data14tree.TN,
          data14tree.AN,
          data14tree.date,
          data14tree.time,
          data14tree.provider
        );
      }
    });
  };

  const handleOpencancel143 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data14tree.AN);
      }
    });
  };

  const handleOpenblock143 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistss(
          data14tree.TN,
          data14tree.AN,
          data14tree.date,
          data14tree.time,
          data14tree.provider
        );
      }
    });
  };

  //tree

  //five

  const [data14five, setData14five] = useState([]);
  const [data14five2, setData14five2] = useState([]);
  const [editButton145, setEditButton145] = useState(false);
  const [icon145, setIcon145] = useState(<EventAvailable />);
  const [status145, setStatus145] = useState("ว่าง");
  const [cfer145, setCfer145] = useState(true);
  const [cancel145, setCancel145] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one5 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentsss/14/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata145 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData14five(data14one5[1]);
              setEditButton145(true);
              setIcon145(<EventBusy />);
              setStatus145("ไม่ว่าง");
              setCfer145(true);
              setCancel145(true);
              return;
            }
            setData14five(data14one5[0]);
            setEditButton145(false);
            setIcon145(<EventAvailable />);
            setStatus145("ว่างงง");
            setCancel145(true);
            setCfer145(true);
            return;
          } else if (res.data.length >= 1) {
            setData14five(res.data[0]);
            setEditButton145(true);
            setIcon145(<EventBusy />);
            setStatus145("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata145[0] === 1 || resdata145[0] === 2) {
                setCfer145(true);
                setCancel145(true);
                return;
              } else if (resdata145[0] > 2 && resdata145[0] < 10) {
                setCfer145(true);
                setCancel145(false);
                return;
              } else if (resdata145[0] > 10) {
                setCfer145(false);
                setCancel145(false);
                return;
              } else {
                setCfer145(false);
                setCancel145(false);
                return;
              }
            } else {
              setCfer145(true);
              setCancel145(false);
              return;
            }
          }
        });
      } else {
        setData14five(data14one5[2]);
        setEditButton145(true);
        setIcon145(<EventBusy />);
        setStatus145("ไม่ว่าง");
        setCfer145(true);
        setCancel145(true);
        return;
      }
    });
  }, [startDate, data14five2, URL]);

  //Modalsearch

  const [selecttn145, setSelecttn145] = useState("");
  const [selectname145, setSelectname145] = useState("");
  const [service145, setService145] = useState("");
  const handleChange145 = (event) => {
    setService145(event.target.value);
  };

  const selectpatient145 = (TN, prefix, fname, lname) => {
    setSelecttn145(TN);
    setSelectname145(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment145 = () => {
    var data = JSON.stringify({
      TN: selecttn145,
      date: data14five.date,
      time: data14five.time,
      service: service145,
      provider: data14five.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentssss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck145();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen145(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck145 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14five2(res.data[0]);
    });
  };

  const [open145, setOpen145] = useState(false);
  const handleOpen145 = () => setOpen145(true);
  const handleClose145 = () => setOpen145(false);

  const handleOpenin145 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentsss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen145(false);
            recheck145()
          }
        });
        return;
      } else if (!service145 || !selecttn145) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment145();
        }
      });
    });
  };

  //Modalsearch

  const handleOpenconfirm145 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistsss(
          data14five.TN,
          data14five.AN,
          data14five.date,
          data14five.time,
          data14five.provider
        );
      }
    });
  };

  const handleOpencancel145 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatasss(data14five.AN);
      }
    });
  };

  const handleOpenblock145 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistsss(
          data14five.TN,
          data14five.AN,
          data14five.date,
          data14five.time,
          data14five.provider
        );
      }
    });
  };

  //five

  //four

  const [data14four, setData14four] = useState([]);
  const [data14four2, setData14four2] = useState([]);
  const [editButton144, setEditButton144] = useState(false);
  const [icon144, setIcon144] = useState(<EventAvailable />);
  const [status144, setStatus144] = useState("ว่าง");
  const [cfer144, setCfer144] = useState(true);
  const [cancel144, setCancel144] = useState(true);

  useEffect(() => {
    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      return false;
    };
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
      {
        AN: null,
        TN: null,
        fname: "ปิดบริการ",
        lname: "วันหยุดราชการ",
        service: "วันหยุดราชการหรืออื่นๆ",
        provider: "ชาย",
        date: bbb,
        time: "14:45",
        prefix: null,
      },
    ];
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    const holidaysURL = `${URL}/holidays`;
    axios.get(`${holidaysURL}`).then((res) => {
      const holidaysearch = startDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const holidays = res.data.map((item) => item.date);
      const arrayIncludes = holidays.includes(holidaysearch);
      if (arrayIncludes !== true) {
        axios.get(oneURL).then((res) => {
          const resdata144 = res.data.map((item) => {
            return item.TN;
          });
          if (res.data.length === 0) {
            if (
              (startDate.getDay() === 0 || startDate.getDay() === 6) &&
              roletoken === "admin"
            ) {
              setData14four(data14one4[1]);
              setEditButton144(true);
              setIcon144(<EventBusy />);
              setStatus144("ไม่ว่าง");
              setCfer144(true);
              setCancel144(true);
              return;
            }
            setData14four(data14one4[0]);
            setEditButton144(false);
            setIcon144(<EventAvailable />);
            setStatus144("ว่างงง");
            setCancel144(true);
            setCfer144(true);
            return;
          } else if (res.data.length === 1) {
            setData14four(res.data[0]);
            setEditButton144(true);
            setIcon144(<EventBusy />);
            setStatus144("ไม่ว่าง");
            if (dateInPast(startDate, new Date())) {
              if (resdata144[0] === 1 || resdata144[0] === 2) {
                setCfer144(true);
                setCancel144(true);
                return;
              } else if (resdata144[0] > 2 && resdata144[0] < 10) {
                setCfer144(true);
                setCancel144(false);
                return;
              } else if (resdata144[0] > 10) {
                setCfer144(false);
                setCancel144(false);
                return;
              } else {
                setCfer144(false);
                setCancel144(false);
                return;
              }
            } else {
              setCfer144(true);
              setCancel144(false);
              return;
            }
          }
        });
      } else {
        setData14four(data14one4[2]);
        setEditButton144(true);
        setIcon144(<EventBusy />);
        setStatus144("ไม่ว่าง");
        setCfer144(true);
        setCancel144(true);
        return;
      }
    });
  }, [startDate, data14four2, URL]);

  //Modalsearch

  const [selecttn144, setSelecttn144] = useState("");
  const [selectname144, setSelectname144] = useState("");
  const [service144, setService144] = useState("");

  // const handlesave = () => {
  //   setOpen144(false);
  // };

  const handleChange144 = (event) => {
    setService144(event.target.value);
  };

  const selectpatient144 = (TN, prefix, fname, lname) => {
    setSelecttn144(TN);
    setSelectname144(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment144 = () => {
    var data = JSON.stringify({
      TN: selecttn144,
      date: data14four.date,
      time: data14four.time,
      service: service144,
      provider: data14four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck144();
          ldataall();
          ldatas();
          ldatass();
          ldataman();
          ldatasss();
          appdataone();
          appdatatwo();
          appdatatree();
          appdatafour();
          appdatafive();
          passappblock();
          passappconfirm();
          setOpen144(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const recheck144 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14four2(res.data[0]);
    });
  };

  const [open144, setOpen144] = useState(false);
  const handleOpen144 = () => setOpen144(true);
  const handleClose144 = () => setOpen144(false);

  const handleOpenin144 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen144(false);
            recheck144()
          }
        });
        return;
      } else if (!service144 || !selecttn144) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addmanaappoinment144();
        }
      });
    });
  };

  // const handleClosein144 = () => setOpenin144(false);

  //Modalsearch

  const handleOpenconfirm144 = () => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlistman(
          data14four.TN,
          data14four.AN,
          data14four.date,
          data14four.time,
          data14four.provider
        );
      }
    });
  };

  const handleOpencancel144 = () => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data14four.AN);
      }
    });
  };

  const handleOpenblock144 = () => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklistman(
          data14four.TN,
          data14four.AN,
          data14four.date,
          data14four.time,
          data14four.provider
        );
      }
    });
  };

  //four

  //14:45

  //นัดจำนวนการนัดหมาย
  const [dataall, setDataall] = useState([0]);
  const [adata, setAdata] = useState([0]);
  const [bdata, setBdata] = useState([0]);
  const [cdata, setCdata] = useState([0]);
  const ldataall = () => {
    const usersURL = `${URL}/appoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setDataall(
        res.data.filter((e) => {
          return e.TN === 1 || e.TN === 2;
        })
      );
      setAdata(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setBdata(
        res.data.filter((e) => {
          return e.TN === 2;
        })
      );
      setCdata(
        res.data.filter((e) => {
          return e.TN === 1;
        })
      );
    });
  };

  const [datas, setDatas] = useState([0]);
  const [adatas, setAdatas] = useState([0]);
  const [bdatas, setBdatas] = useState([0]);
  const [cdatas, setCdatas] = useState([0]);
  const ldatas = () => {
    const usersURL = `${URL}/appoinments`;
    axios.get(`${usersURL}`).then((res) => {
      setDatas(
        res.data.filter((e) => {
          return e.TN === 1 || e.TN === 2;
        })
      );
      setAdatas(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setBdatas(
        res.data.filter((e) => {
          return e.TN === 2;
        })
      );
      setCdatas(
        res.data.filter((e) => {
          return e.TN === 1;
        })
      );
    });
  };

  const [datass, setDatass] = useState([0]);
  const [adatass, setAdatass] = useState([0]);
  const [bdatass, setBdatass] = useState([0]);
  const [cdatass, setCdatass] = useState([0]);
  const ldatass = () => {
    const usersURL = `${URL}/appoinmentss`;
    axios.get(`${usersURL}`).then((res) => {
      setDatass(
        res.data.filter((e) => {
          return e.TN === 1 || e.TN === 2;
        })
      );
      setAdatass(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setBdatass(
        res.data.filter((e) => {
          return e.TN === 2;
        })
      );
      setCdatass(
        res.data.filter((e) => {
          return e.TN === 1;
        })
      );
    });
  };

  const [datasss, setDatasss] = useState([0]);
  const [adatasss, setAdatasss] = useState([0]);
  const [bdatasss, setBdatasss] = useState([0]);
  const [cdatasss, setCdatasss] = useState([0]);
  const ldatasss = () => {
    const usersURL = `${URL}/appoinmentsss`;
    axios.get(`${usersURL}`).then((res) => {
      setDatasss(
        res.data.filter((e) => {
          return e.TN === 1 || e.TN === 2;
        })
      );
      setAdatasss(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setBdatasss(
        res.data.filter((e) => {
          return e.TN === 2;
        })
      );
      setCdatasss(
        res.data.filter((e) => {
          return e.TN === 1;
        })
      );
    });
  };

  const [dataman, setDataman] = useState([0]);
  const [adataman, setAdataman] = useState([0]);
  const [bdataman, setBdataman] = useState([0]);
  const [cdataman, setCdataman] = useState([0]);
  const ldataman = () => {
    const usersURL = `${URL}/manappoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setDataman(
        res.data.filter((e) => {
          return e.TN === 1 || e.TN === 2;
        })
      );
      setAdataman(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setBdataman(
        res.data.filter((e) => {
          return e.TN === 2;
        })
      );
      setCdataman(
        res.data.filter((e) => {
          return e.TN === 1;
        })
      );
    });
  };

  const alldata = [...dataall, ...datas, ...datass, ...datasss, ...dataman];
  const alldatal = alldata.length;
  const alldatap = 100;

  const Aalldata = [...adata, ...adatas, ...adatass, ...adatasss, ...adataman];
  const Aalldatal = Aalldata.length;

  const Balldata = [...bdata, ...bdatas, ...bdatass, ...bdatasss, ...bdataman];
  const Balldatal = Balldata.length;
  const Balldatap = (Balldatal * 100) / alldatal;

  const Calldata = [...cdata, ...cdatas, ...cdatass, ...cdatasss, ...cdataman];
  const Calldatal = Calldata.length;
  const Calldatap = (Calldatal * 100) / alldatal;

  //searchback
  const [appdata, setAppdata] = useState([]);
  const appdataone = () => {
    const usersURL = `${URL}/appoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdata(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [appdatas, setAppdatas] = useState([]);
  const appdatatwo = () => {
    const usersURL = `${URL}/appoinments`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatas(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [appdatass, setAppdatass] = useState([]);
  const appdatatree = () => {
    const usersURL = `${URL}/appoinmentss`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatass(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [appdatasss, setAppdatasss] = useState([]);
  const appdatafive = () => {
    const usersURL = `${URL}/appoinmentsss`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatasss(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [appdataman, setAppdataman] = useState([]);
  const appdatafour = () => {
    const usersURL = `${URL}/manappoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdataman(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [passconfirmapp, setPassconfirmapp] = useState([]);
  const passappconfirm = () => {
    const usersURL = `${URL}/passconfirmapp`;
    axios.get(`${usersURL}`).then((res) => {
      setPassconfirmapp(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const [passblockapp, setPassblockapp] = useState([]);
  const passappblock = () => {
    const usersURL = `${URL}/passblockapp`;
    axios.get(`${usersURL}`).then((res) => {
      setPassblockapp(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  };

  const appalldata = [
    ...appdata,
    ...appdatas,
    ...appdatass,
    ...appdatasss,
    ...appdataman,
    ...passconfirmapp,
    ...passblockapp,
  ];

  const [appsearch, setAppsearch] = useState(null);
  const apptesttt = (e) => {
    if (e.target.value === "") {
      return setAppsearch(null);
    } else {
      return setAppsearch(e.target.value);
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "10px 10px 10px 10px" }}>
          <DatePicker
            onChange={(Date) => setStartDate(Date)}
            value={startDate}
            format="dd-MM-y"
            locale="th-TH"
            calendarClassName="calendarclass"
            className="picker"
          />
          <span style={{ marginLeft: "10px", fontSize: "small" }}>
            {dayname}
          </span>
        </h2>
      </div>
      <div className="testtest">
        <table className="ondate">
          <tbody>
            <tr className="head">
              <th className="headtime">เวลา</th>
              <th className="headname">ชื่อ-สกุล</th>
              <th className="headservice">หัตถการ</th>
              <th className="headprovider">ผู้ให้บริการ</th>
              <th className="headstatus">
                สถานะ
                <Button
                  color="warning"
                  className="alertbutton"
                  size="small"
                  onClick={linealert}
                >
                  <NotificationAdd size="small" />
                </Button>
              </th>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodyname">
                {data9one.prefix}
                {data9one.fname} {data9one.lname}
                
              </td>
              <td className="bodyservice">{data9one.service}</td>
              <td className="bodyprovider">{data9one.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon91}
                  disabled={editButton91}
                  onClick={handleOpen91}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status91}
                </Button>
                <Modal
                  open={open91}
                  onClose={handleClose91}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient91(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn91}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname91}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data9one.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data9one.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data9one.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service91}
                                    onChange={handleChange91}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin91}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose91}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer91}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm91}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel91}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel91}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer91}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock91}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodyname">
                {data9two.prefix}
                {data9two.fname} {data9two.lname}
              </td>
              <td className="bodyservice">{data9two.service}</td>
              <td className="bodyprovider">{data9two.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={icon92}
                  disabled={editButton92}
                  onClick={handleOpen92}
                  style={{ margin: "0 0 0 4px" }}
                  className="btnstatus"
                >
                  {status92}
                </Button>
                <Modal
                  open={open92}
                  onClose={handleClose92}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient92(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn92}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname92}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data9two.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data9two.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data9two.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service92}
                                    onChange={handleChange92}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin92}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose92}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  className="cfer"
                  size="small"
                  startIcon={<Send />}
                  disabled={cfer92}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm92}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel92}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel92}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer92}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock92}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodyname">
                {data9tree.prefix}
                {data9tree.fname} {data9tree.lname}
              </td>
              <td className="bodyservice">{data9tree.service}</td>
              <td className="bodyprovider">{data9tree.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon93}
                  disabled={editButton93}
                  onClick={handleOpen93}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status93}
                </Button>
                <Modal
                  open={open93}
                  onClose={handleClose93}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient93(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn93}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname93}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data9tree.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data9tree.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data9tree.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service93}
                                    onChange={handleChange93}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin93}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose93}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer93}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm93}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel93}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel93}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer93}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock93}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodyname">
                {data9five.prefix}
                {data9five.fname} {data9five.lname}
              </td>
              <td className="bodyservice">{data9five.service}</td>
              <td className="bodyprovider">{data9five.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon95}
                  disabled={editButton95}
                  onClick={handleOpen95}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status95}
                </Button>
                <Modal
                  open={open95}
                  onClose={handleClose95}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient95(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn95}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname95}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data9five.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data9five.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data9five.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service95}
                                    onChange={handleChange95}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin95}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose95}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer95}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm95}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel95}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel95}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer95}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock95}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodyname">
                {data9four.prefix}
                {data9four.fname} {data9four.lname}
              </td>
              <td className="bodyservice">{data9four.service}</td>
              <td className="bodyprovider">{data9four.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon94}
                  disabled={editButton94}
                  onClick={handleOpen94}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status94}
                </Button>
                <Modal
                  open={open94}
                  onClose={handleClose94}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient94(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn94}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname94}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data9four.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data9four.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data9four.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service94}
                                    onChange={handleChange94}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin94}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose94}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  className="cfer"
                  size="small"
                  startIcon={<Send />}
                  disabled={cfer94}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm94}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel94}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel94}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  startIcon={<Block />}
                  disabled={cfer94}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock94}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodyname">
                {data10one.prefix}
                {data10one.fname} {data10one.lname}
              </td>
              <td className="bodyservice">{data10one.service}</td>
              <td className="bodyprovider">{data10one.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon101}
                  disabled={editButton101}
                  onClick={handleOpen101}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status101}
                </Button>
                <Modal
                  open={open101}
                  onClose={handleClose101}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient101(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn101}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname101}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data10one.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data10one.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data10one.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service101}
                                    onChange={handleChange101}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin101}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose101}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer101}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm101}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel101}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel101}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer101}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock101}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodyname">
                {data10two.prefix}
                {data10two.fname} {data10two.lname}
              </td>
              <td className="bodyservice">{data10two.service}</td>
              <td className="bodyprovider">{data10two.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon102}
                  onClick={handleOpen102}
                  disabled={editButton102}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status102}
                </Button>
                <Modal
                  open={open102}
                  onClose={handleClose102}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient102(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn102}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname102}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data10two.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data10two.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data10two.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service102}
                                    onChange={handleChange102}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin102}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose102}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer102}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm102}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel102}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel102}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer102}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock102}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodyname">
                {data10tree.prefix}
                {data10tree.fname} {data10tree.lname}
              </td>
              <td className="bodyservice">{data10tree.service}</td>
              <td className="bodyprovider">{data10tree.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon103}
                  disabled={editButton103}
                  onClick={handleOpen103}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status103}
                </Button>
                <Modal
                  open={open103}
                  onClose={handleClose103}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient103(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn103}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname103}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data10tree.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data10tree.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data10tree.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service103}
                                    onChange={handleChange103}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin103}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose103}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  className="cfer"
                  size="small"
                  startIcon={<Send />}
                  disabled={cfer103}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm103}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel103}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel103}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer103}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock103}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodyname">
                {data10five.prefix}
                {data10five.fname} {data10five.lname}
              </td>
              <td className="bodyservice">{data10five.service}</td>
              <td className="bodyprovider">{data10five.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon105}
                  disabled={editButton105}
                  onClick={handleOpen105}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status105}
                </Button>
                <Modal
                  open={open105}
                  onClose={handleClose105}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient105(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn105}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname105}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data10five.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data10five.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data10five.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service105}
                                    onChange={handleChange105}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin105}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose105}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  className="cfer"
                  size="small"
                  startIcon={<Send />}
                  disabled={cfer105}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm105}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel105}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel105}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer105}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock105}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodyname">
                {data10four.prefix}
                {data10four.fname} {data10four.lname}
              </td>
              <td className="bodyservice">{data10four.service}</td>
              <td className="bodyprovider">{data10four.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon104}
                  onClick={handleOpen104}
                  disabled={editButton104}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status104}
                </Button>
                <Modal
                  open={open104}
                  onClose={handleClose104}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient104(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn104}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname104}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data10four.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data10four.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data10four.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service104}
                                    onChange={handleChange104}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin104}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose104}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer104}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm104}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel104}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel104}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  disabled={cfer104}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock104}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodyname">
                {data13one.prefix}
                {data13one.fname} {data13one.lname}
              </td>
              <td className="bodyservice">{data13one.service}</td>
              <td className="bodyprovider">{data13one.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon131}
                  onClick={handleOpen131}
                  disabled={editButton131}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status131}
                </Button>
                <Modal
                  open={open131}
                  onClose={handleClose131}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient131(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn131}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname131}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data13one.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data13one.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data13one.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service131}
                                    onChange={handleChange131}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin131}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose91}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer131}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm131}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel131}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel131}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer131}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock131}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodyname">
                {data13two.prefix}
                {data13two.fname} {data13two.lname}
              </td>
              <td className="bodyservice">{data13two.service}</td>
              <td className="bodyprovider">{data13two.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon132}
                  onClick={handleOpen132}
                  disabled={editButton132}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status132}
                </Button>
                <Modal
                  open={open132}
                  onClose={handleClose132}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient132(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn132}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname132}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data13two.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data13two.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data13two.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service132}
                                    onChange={handleChange132}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin132}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose132}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer132}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm132}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel132}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel132}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  startIcon={<Block />}
                  disabled={cfer132}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock132}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodyname">
                {data13tree.prefix}
                {data13tree.fname} {data13tree.lname}
              </td>
              <td className="bodyservice">{data13tree.service}</td>
              <td className="bodyprovider">{data13tree.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon133}
                  onClick={handleOpen133}
                  disabled={editButton133}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status133}
                </Button>
                <Modal
                  open={open133}
                  onClose={handleClose133}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient133(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn133}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname133}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data13tree.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data13tree.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data13tree.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service133}
                                    onChange={handleChange133}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin133}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose133}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer133}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm133}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel133}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel133}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer133}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock133}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodyname">
                {data13five.prefix}
                {data13five.fname} {data13five.lname}
              </td>
              <td className="bodyservice">{data13five.service}</td>
              <td className="bodyprovider">{data13five.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  startIcon={icon135}
                  onClick={handleOpen135}
                  disabled={editButton135}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status135}
                </Button>
                <Modal
                  open={open135}
                  onClose={handleClose135}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient135(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn135}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname135}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data13five.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data13five.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data13five.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service135}
                                    onChange={handleChange135}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin135}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose135}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer135}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm135}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel135}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel135}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer135}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock135}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodyname">
                {data13four.prefix}
                {data13four.fname} {data13four.lname}
              </td>
              <td className="bodyservice">{data13four.service}</td>
              <td className="bodyprovider">{data13four.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon134}
                  disabled={editButton134}
                  onClick={handleOpen134}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status134}
                </Button>
                <Modal
                  open={open134}
                  onClose={handleClose134}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient134(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn134}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname134}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data13four.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data13four.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data13four.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service134}
                                    onChange={handleChange134}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin134}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose134}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  disabled={cfer134}
                  startIcon={<Send />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm134}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel134}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel134}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  disabled={cfer134}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock134}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodyname">
                {data14one.prefix}
                {data14one.fname} {data14one.lname}
              </td>
              <td className="bodyservice">{data14one.service}</td>
              <td className="bodyprovider">{data14one.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  className="btnstatus"
                  size="small"
                  onClick={handleOpen141}
                  startIcon={icon141}
                  disabled={editButton141}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status141}
                </Button>
                <Modal
                  open={open141}
                  onClose={handleClose141}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient141(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn141}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname141}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data14one.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data14one.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data14one.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service141}
                                    onChange={handleChange141}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin141}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose141}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  disabled={cfer141}
                  startIcon={<Send />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm141}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel141}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel141}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer141}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock141}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodyname">
                {data14two.prefix}
                {data14two.fname} {data14two.lname}
              </td>
              <td className="bodyservice">{data14two.service}</td>
              <td className="bodyprovider">{data14two.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  onClick={handleOpen142}
                  startIcon={icon142}
                  disabled={editButton142}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status142}
                </Button>
                <Modal
                  open={open142}
                  onClose={handleClose142}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient142(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn142}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname142}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data14two.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data14two.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data14two.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service142}
                                    onChange={handleChange142}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin142}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose142}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={cfer142}
                  className="cfer"
                  startIcon={<Send />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm142}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel142}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel142}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  disabled={cfer142}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock142}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodyname">
                {data14tree.prefix}
                {data14tree.fname} {data14tree.lname}
              </td>
              <td className="bodyservice">{data14tree.service}</td>
              <td className="bodyprovider">{data14tree.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon143}
                  disabled={editButton143}
                  onClick={handleOpen143}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status143}
                </Button>
                <Modal
                  open={open143}
                  onClose={handleClose143}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient143(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn143}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname143}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data14tree.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data14tree.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data14tree.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service143}
                                    onChange={handleChange143}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin143}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose143}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Send />}
                  className="cfer"
                  disabled={cfer143}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm143}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel143}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel143}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={cfer143}
                  className="cfer"
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock143}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodyname">
                {data14five.prefix}
                {data14five.fname} {data14five.lname}
              </td>
              <td className="bodyservice">{data14five.service}</td>
              <td className="bodyprovider">{data14five.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon145}
                  disabled={editButton145}
                  onClick={handleOpen145}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status145}
                </Button>
                <Modal
                  open={open145}
                  onClose={handleClose145}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            variant="outlined"
                            onChange={testtt}
                            size="small"
                          />
                        </Box>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient145(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn145}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname145}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data14five.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  value={`${data14five.time}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  value={`${data14five.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl variant="standard" fullWidth>
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service145}
                                    onChange={handleChange145}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin145}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose145}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Send />}
                  className="cfer"
                  disabled={cfer145}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm145}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel145}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel145}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={cfer145}
                  className="cfer"
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock145}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodyname">
                {data14four.prefix}
                {data14four.fname} {data14four.lname}
              </td>
              <td className="bodyservice">{data14four.service}</td>
              <td className="bodyprovider">{data14four.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  className="btnstatus"
                  color="success"
                  size="small"
                  startIcon={icon144}
                  disabled={editButton144}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpen144}
                >
                  {status144}
                </Button>
                <Modal
                  open={open144}
                  onClose={handleClose144}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                    <Paper>
                      <Box display="flex">
                        <TextField
                          fullWidth
                          id="fullWidth"
                          label="ค้นหา..."
                          onChange={testtt}
                          size="small"
                          variant="outlined"
                        />
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        <TableContainer
                          sx={{ height: "160px", maxWidth: "320px" }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    maxHeight: "30px",
                                    padding: "5px 0 5px 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  TN
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  ชื่อสกุล
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxHeight: "30px",
                                    padding: "0 0 0 0",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  สิทธิ์
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    maxWidth: "60px",
                                    padding: "5px 5px 5px 5px",
                                    backgroundColor: "#1bb3b3",
                                  }}
                                >
                                  เลือก
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data
                                .filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                  return (
                                    <TableRow key={item.TN}>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 0 5px 0",
                                        }}
                                      >
                                        {item.TN}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "0 0 0 5px" }}
                                      >
                                        {item.fname} {item.lname}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxHeight: "30px",
                                          padding: "0 0 0 0",
                                        }}
                                      >
                                        {item.role}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          maxWidth: "60px",
                                          padding: "5px 5px 5px 5px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            margin: "0 0 0 8px",
                                            display: "contents",
                                          }}
                                          onClick={() => {
                                            return selectpatient144(
                                              item.TN,
                                              item.prefix,
                                              item.fname,
                                              item.lname
                                            );
                                          }}
                                        >
                                          <Send
                                            color="secondary"
                                            size="small"
                                          />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Paper>
                    <Paper style={{ marginTop: 10 }}>
                      <Grid item xs={4} sm={4}>
                        <CssBaseline />
                        <Container maxWidth="sm" sx={{ p: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            ลงนัดคนไข้
                          </Typography>
                          <Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="TN"
                                  label="TN"
                                  size="small"
                                  variant="standard"
                                  placeholder="กรุณากรอก TN"
                                  value={`${selecttn144}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="fullname"
                                  label="ชื่อสกุล"
                                  size="small"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${selectname144}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="date"
                                  label="วันที่"
                                  size="small"
                                  variant="standard"
                                  placeholder="กรุณากรอกชื่อ-สกุล"
                                  value={`${data14four.date}`}
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  id="เวลา"
                                  label="time"
                                  size="small"
                                  value={`${data14four.time} น.`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <TextField
                                  id="provider"
                                  label="เจ้าหน้าที่"
                                  size="small"
                                  value={`${data14four.provider}`}
                                  variant="standard"
                                  required
                                />
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <FormControl
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                >
                                  <InputLabel id="demo-simple-select-standard-label">
                                    หัตถการ
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={service144}
                                    onChange={handleChange144}
                                    label="service"
                                  >
                                    <MenuItem value="นวด">นวด</MenuItem>
                                    <MenuItem value="ประคบ">ประคบ</MenuItem>
                                    <MenuItem value="อบสมุนไพร">
                                      อบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดและประคบ">
                                      นวดและประคบ
                                    </MenuItem>
                                    <MenuItem value="นวดและอบสมุนไพร">
                                      นวดและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="ประคบและอบสมุนไพร">
                                      ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวด ประคบและอบสมุนไพร">
                                      นวด ประคบและอบสมุนไพร
                                    </MenuItem>
                                    <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                      นวดเท้าเพื่อสุขภาพ
                                    </MenuItem>
                                    <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                      เจ้าหน้าที่ไม่พร้อมให้บริการ
                                    </MenuItem>
                                    <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                      วันหยุดราชการหรืออื่นๆ
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleOpenin144}
                                >
                                  นัดหมาย
                                </Button>
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose144}
                                >
                                  ยกเลิก
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Send />}
                  className="cfer"
                  disabled={cfer144}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenconfirm144}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel144}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpencancel144}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  className="cfer"
                  startIcon={<Block />}
                  disabled={cfer144}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={handleOpenblock144}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Paper style={{ marginBottom: "30px", marginTop: "30px" }}>
        <div style={{ textAlign: "center" }}>
          <h2
            className="scheduletop"
            style={{ marginRight: "10px", fontSize: "32px" }}
          >
            เวรประจำ{" "}
            <span style={{ textDecoration: "underline" }}>{dayname}</span> ที่{" "}
            {startDate.toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
        </div>
        <TableContainer style={{ marginBottom: "10px" }}>
          <Table>
            <thead className="scheduletophead">
              <tr>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  อาซูวา
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  อารอฟัต
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  อานีรา
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  ฟาตีเม๊าะ
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  วันอารีนา
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  นูรไอดา
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  สีตีฟาตีเมาะ
                </th>
                <th
                  style={{
                    tableLayout: "fixed",
                    width: "200px",
                    height: "60px",
                    backgroundColor: "#1bb3b3",
                    fontFamily: "Athiti",
                    fontWeight: "bold",
                  }}
                >
                  อับดุลเลาะ
                </th>
              </tr>
            </thead>
            <tbody className="scheduletopbody">
              {scheduletoday.map((item) => {
                if (dateInPast(startDate, new Date())) {
                  return (
                    <tr key={uuidv4()}>
                      <td colSpan="8" style={{ backgroundColor: "#767171" }}>
                        วันที่ผ่านไปแล้ว
                      </td>
                    </tr>
                  );
                } else {
                  if (item.asuwa === "วันหยุดราชการ") {
                    return (
                      <tr key={uuidv4()}>
                        <td colSpan="8" style={{ backgroundColor: "#767171" }}>
                          วันหยุดราชการหรืออื่นๆ
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={uuidv4()}>
                        <td style={checkttm(item.asuwa)}>{item.asuwa}</td>
                        <td style={checkttm(item.arofat)}>{item.arofat}</td>
                        <td style={checkttm(item.aneera)}>{item.aneera}</td>
                        {scheduletoday2.map((item) => {
                          return (
                            <td key={uuidv4()} style={checkttm2(item.fateemoh)}>
                              {item.fateemoh}
                            </td>
                          );
                        })}
                        {scheduletoday2.map((item) => {
                          return (
                            <td
                              key={uuidv4()}
                              style={checkttm2(item.wanareena)}
                            >
                              {item.wanareena}
                            </td>
                          );
                        })}
                        {scheduletoday2.map((item) => {
                          return (
                            <td key={uuidv4()} style={checkttm2(item.nuraida)}>
                              {item.nuraida}
                            </td>
                          );
                        })}
                        {scheduletoday2.map((item) => {
                          return (
                            <td
                              key={uuidv4()}
                              style={checkttm2(item.seeteefateemoh)}
                            >
                              {item.seeteefateemoh}
                            </td>
                          );
                        })}
                        {scheduletoday2.map((item) => {
                          return (
                            <td key={uuidv4()} style={checkttm2(item.abdulloh)}>
                              {item.abdulloh}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                }
              })}
            </tbody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper className="searchapp" style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4px",
          }}
        >
          <h1>สรุปยอดการเข้ารับบริการ</h1>
        </div>
        <table>
          <thead
            style={{
              backgroundColor: "#F0725C",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <tr>
              <th>รายการ</th>
              <th>ทั้งหมด</th>
              <th>มาตามนัด</th>
              <th>ผิดนัดหมาย</th>
              <th
                style={{
                  backgroundColor: "#F7D707",
                }}
              >
                รอการเข้ารับบริการ
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>จำนวน(ครั้ง)</td>
              <td>{alldatal}</td>
              <td>{Calldatal}</td>
              <td>{Balldatal}</td>
              <td rowSpan="2">{Aalldatal}</td>
            </tr>
            <tr>
              <td>ร้อยละ</td>
              <td>{alldatap.toFixed(2)}</td>
              <td>{Calldatap.toFixed(2)}</td>
              <td>{Balldatap.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <h1>ค้นหานัดหมายผู้รับบริการ</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={apptesttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "200px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackdate"
                    >
                      วันที่
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "50px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbacktime"
                    >
                      เวลา
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        maxWidth: "90px",
                        padding: "0 0 0 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackname"
                    >
                      ชื่อ-สกุล
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "30px",
                        padding: "0 0 0 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackprovider"
                    >
                      เจ้าหน้าที่
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "30px",
                        padding: "5px 5px 5px 5px",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackrole"
                    >
                      สิทธิ์
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appalldata
                    .filter((e) => {
                      return (
                        e.fname.includes(appsearch) ||
                        e.lname.includes(appsearch) ||
                        e.TN.toString().includes(appsearch) ||
                        e.tel.includes(appsearch)
                      );
                    })
                    .map((item) => {
                      return (
                        <TableRow key={uuidv4()} className="searchusersrow">
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 1px 5px 1px",
                            }}
                            // className="searchbackdate"
                          >
                            {item.date}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "40px",
                              padding: "5px 0 5px 0",
                            }}
                            // className="searchbacktime"
                          >
                            {item.time} น.
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              maxWidth: "90px",
                              maxHeight: "10px",
                              padding: "0 0 0 10px",
                            }}
                            // className="searchbackname"
                          >
                            {item.prefix}
                            {item.fname} {item.lname}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "0 0 0 0",
                            }}
                            // className="searchbackprovider"
                          >
                            {item.provider}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "5px 5px 5px 5px",
                            }}
                            // className="searchbackrole"
                          >
                            {item.role}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper className="searchapp">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <h1>ค้นหาข้อมูลผู้รับบริการ</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={testttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "200px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#e79129",
                      }}
                      className="searchbacktn"
                    >
                      TN
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#e79129",
                      }}
                    >
                      ชื่อ-สกุล
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        padding: "0 0 0 0",
                        backgroundColor: "#e79129",
                      }}
                    >
                      เบอร์โทรศัพท์
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        padding: "5px 5px 5px 5px",
                        backgroundColor: "#e79129",
                      }}
                      className="searchbackrole"
                    >
                      สิทธิ์
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersdata
                    .filter((e) => {
                      return (
                        e.fname.includes(searchh) ||
                        e.lname.includes(searchh) ||
                        e.TN.toString().includes(searchh) ||
                        e.tel.includes(searchh)
                      );
                    })
                    .map((item) => {
                      return (
                        <TableRow key={uuidv4()} className="searchbackrow">
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 0 5px 0",
                            }}
                            className="searchbacktn"
                          >
                            {item.TN}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxHeight: "30px", padding: "0 0 0 10px" }}
                          >
                            {item.prefix}
                            {item.fname} {item.lname}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "5px 0 5px 0",
                            }}
                          >
                            {item.tel}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 5px 5px 5px",
                            }}
                            className="searchbackrole"
                          >
                            {item.role}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default Apponeback;
