import "./Calendar.css";
import * as React from "react";
import { Box, Container, CssBaseline,Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import liff from "@line/liff";
import Appcalendar from "./AppCalendar";
import { useEffect} from "react";


const Calendarline = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN");
    const role = localStorage.getItem("role");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          if (role === "admin" || role === "root") {
            navigate("/calendaradminline");
            return;
          } else {
            liff.init(
              { liffId: "1657187328-2wnqP3GZ" },
              () => {
                liff
                  .getProfile()
                  .then((profile) => {
                    axios
                      .put(`${URL}/uuidline/${TN}`, {
                        uuid: profile.userId,
                      })
                      .then(function (response) {
                        navigate("/calendarline");
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  })
                  .catch((err) => console.error(err));
              },
              (err) => console.error(err)
            );
            return;
          }
        } else {
          localStorage.removeItem("fname");
          localStorage.removeItem("lname");
          localStorage.removeItem("TN");
          localStorage.removeItem("role");
          localStorage.removeItem("prefix");
          localStorage.removeItem("uuid");
          navigate("/calendarusersline");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);

  return (
    <div
      className="aboutpages"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Container>
        <Box>
          <Paper
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              paddingTop: "10px",
              border: "1px solid #e1932c",
            }}
          >
            <div style={{margin:'20px'}}>
            <Appcalendar/>
            </div>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};
export default Calendarline;
