import "./Calendar.css";
import * as React from "react";
import {Paper} from "@mui/material";
import Webcalendarline from "./WebCalendarline";

const Calendaruserslinefive = () => {

  return (
    <Paper
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        paddingTop: "10px",
        border: "1px solid #e1932c",
      }}
    >
      <div style={{marginBottom:'10px'}}>

      <Webcalendarline />
      </div>
    </Paper>
  );
};
export default Calendaruserslinefive;
