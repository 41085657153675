import * as React from "react";
import Paper from "@mui/material/Paper";
import Navback from "./Navbarback";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, TextField, Grid} from "@mui/material";
import {
  DeleteForever,
} from "@mui/icons-material";
import Footer from "../fronthome/footer/footer";
import "./users.css";
import Swal from "sweetalert2";


const Block=()=>{

  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  useEffect(() => {
    
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location = "/";
        })
      }   
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location = "/login";
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });

    }, [URL]);



//การดึงข้อมูล
    const [data, setData] = useState([]);
    const getdata = ()=>{
      axios.get(`${URL}/blocklist`).then((res) => {
          setData(res.data);
        });    
    }
    useEffect(() => {
      getdata()
    }, []);
  
//การดึงข้อมูล


async function cancelblock(TN) {
      var data = JSON.stringify({
        "TN": TN
      });
      var config = {
        method: 'delete',
        url: `${URL}/blocklist`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
    axios(config)
      .then(function (response) {
       if (response.data['status'] === 'OK'){
        Swal.fire({
          icon: 'success',
          title: 'ยกเลิกการบล๊อคเรียบร้อย',
          showConfirmButton: false,
          timer: 1500
        }).then((result)=>{
          getdata()
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
    }

    const [search, setSearch] = useState("");

    const testtt = (e) => {
      return setSearch(e.target.value);
    };
    
    const confirmcancel= (TN) =>{
      Swal.fire({
        title: 'ยกเลิกการบล๊อค',
        text: "การยกเลิกบล๊อคจะทำให้ผู้รับบริการสามารถใช้งานระบบได้อีกครั้ง!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {
        if (result.isConfirmed) {
          cancelblock(TN)
        }
      })
    }
    return (
      <div>
        <Navback />
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Paper style={{ marginTop: "100px", marginBottom: "65px" }}>
          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
            <Grid item xs={5} style={{ marginLeft: "10px", marginTop: "3px" }}>
              <TextField
                id="outlined-basic"
                label="ค้นหา..."
                variant="outlined"
                size="small"
                onChange={testtt}
                fullWidth
              />
            </Grid>
            </Grid>
          <div>
            <table>
              <thead>
                <tr className="head">
                  <th className="headusertn">TN</th>
                  <th className="headusername">ชื่อ-สกุล</th>
                  <th className="headusertel">เบอร์โทรศัพท์</th>
                  <th className="headuserrole">สิทธิ์</th>
                  <th className="headuseractionn">สถานะ</th>
                </tr>
              </thead>
              <tbody className="users">
                {data
                .filter((e) => {
                    return (
                      e.fname.includes(search) ||
                      e.lname.includes(search) ||
                      e.TN.toString().includes(search) ||
                      e.tel.includes(search)
                    );
                  })
                  .map((item) => {
                    return (
                      <tr key={item.TN}>
                        <td align="center">{item.TN}</td>
                        <td align="left">
                          {item.prefix}{item.fname} {item.lname}
                        </td>
                        <td align="center" className="bodyusertel">
                          {item.tel}
                        </td>
                        <td align="center" className="bodyuserrole">
                          {item.role}
                        </td>
                        <td align="center" className="bodyuseractionn">
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            className="deleteusers"
                            onClick={()=>{return confirmcancel(item.TN)}}
                            startIcon={<DeleteForever />}
                            style={{ margin: "0 0 0 8px" }}
                          >
                            ยกเลิกการบล๊อค
                          </Button>
                          <div className="deletecancel">
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            className="deleteuserss"
                            onClick={()=>{return confirmcancel(item.TN)}}
                            style={{ margin: "0 0 0 8px" }}
                          >
                            <DeleteForever size="small" color="warning" />
                          </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Paper>
      </Container>
      <Footer />
      </div>
    );
  }
export default Block;
