import liff from "@line/liff";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./reviews.css";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { CssBaseline, Container } from "@mui/material";

const Reviews = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const prefixtoken = localStorage.getItem("prefix");
  const roletoken = localStorage.getItem("role");
  const TNtoken = localStorage.getItem("TN");
  const navigate = useNavigate();
  const [rolename, setRolename] = useState("");

  const setrole = (role)=>{
    if (role === "admin") {
      setRolename("ผู้ดูแลระบบ");
      return;
    } else if (role === "A2") {
      setRolename("สวัสดิการข้าราชการ");
      return;
    } else if (role === "A1") {
      setRolename("ชำระเงิน");
      return;
    } else if (role === "A7") {
      setRolename("ประกันสังคม");
      return;
    } else if (role === "UC") {
      setRolename("หลักประกันสุขภาพถ้วนหน้า");
      return;
    } else if (role === "user") {
      setRolename("ผู้รับบริการทั่วไป");
      return;
    }
  }
  const getdata = () =>{
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          liff.init(
            { liffId: "1657187328-BPAq0K4z" },
            () => {
              liff
                .getProfile()
                .then((profile) => {
                  axios
                    .put(`${URL}/uuidline/${TN}`, {
                      uuid: profile.userId,
                    })
                    .then(function (response) {
                      navigate("/about");
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch((err) => console.error(err));
            },
            (err) => console.error(err)
          );
        } else {
          localStorage.removeItem("fname");
          localStorage.removeItem("lname");
          localStorage.removeItem("TN");
          localStorage.removeItem("role");
          localStorage.removeItem("prefix");
          localStorage.removeItem("uuid");
          navigate("/aboutlogin");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    setrole(roletoken)
    getdata()
  }, []);

  const clearpage = () => {
    localStorage.clear();
    navigate("/aboutlogin");
  };

  const check = () => {
    axios.get(`${URL}/user/${TNtoken}`).then((res) => {
      localStorage.setItem('role', res.data[0].role)
      setrole(res.data[0].role)
      getdata()
    });
  };

  // console.log(uuid)

  // onSubmit={logout}>
  return (
    <div className="aboutpages" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      {/* <Navbar /> */}
      <Container>
        <CssBaseline />
        <Box>
          <Paper style={{ marginBottom: "10px", marginTop: "10px", paddingTop: "10px", border: "1px solid #e1932c" }}>
            <form>
              <div style={{ textAlign: "center", color: "black", fontSize: "24px", marginBottom: "10px", marginTop: "10px" }}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <img className="logoclinic" src="/logo.png" alt="/about" />
                </div>
                <h5>ข้อมูลส่วนตัว</h5>
                <h6>
                  คุณ{fnametoken} {lnametoken}
                </h6>
              </div>
              <div style={{ width: "98%", marginLeft: "auto", marginRight: "auto", textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                <TextField
                  label="ชื่อ-สกุล"
                  id="outlined-name"
                  value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              </div>
              <div style={{ width: "98%", marginLeft: "auto", marginRight: "auto", textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                <TextField
                  label="เลขที่ผู้รับบริการ"
                  id="outlined-name"
                  value={`${TNtoken}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              </div>
              <div style={{ width: "98%", marginLeft: "auto", marginRight: "auto", textAlign: "center", marginTop: "10px", marginBottom: "20px" }}>
                <TextField
                  label="สิทธิ์การรักษา"
                  id="outlined-name"
                  value={rolename}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              </div>

              <div className="button" style={{ marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                <Button type="button" variant="contained" className="buttonreviews" onClick={check} color="success" style={{ marginRight: "10px" }}>
                  อัพเดตสิทธิ์การรักษา
                </Button>
                <Button type="button" variant="contained" className="buttonreviews" color="error" onClick={clearpage}>
                  ออกจากระบบ
                </Button>
              </div>
            </form>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};
export default Reviews;
