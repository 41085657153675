import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



export default function Loginline() {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  

  const navigate = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();


    const data = new FormData(event.currentTarget);


    const jsondata = JSON.stringify({
      tel: data.get('tel'),
      password: data.get('password'),
    });


    var config = {
      method: 'post',
      url: `${URL}/login`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : jsondata
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK'){
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('TN', response.data.TN);
        localStorage.setItem('fname', response.data.fname);
        localStorage.setItem('lname', response.data.lname);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('prefix', response.data.prefix);
        if(response.data.role === 'admin' || response.data.role === 'root' ){
          navigate('/calendaradminline')
        } else{
          navigate('/calendarline')
        }
      } else{
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });


  };



  return (
    <div className="aboutpages" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Container style={{backgroundColor:'#ffff',margin:'30px'}} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img className="logoclinic" src="/logo.png"  alt="/about" style={{marginBottom:'10px'}} />
          <Typography component="h1" variant="h5">
            กรุณาเข้าสู่ระบบ
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="tel"
              label="เบอร์โทรศัพท์"
              name="tel"
              type="number"
              autoComplete="tel"
              autoFocus
              size="small"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="รหัสผ่าน"
              type="password"
              id="password"
              size="small"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              size="small"
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              เข้าสู่ระบบ
            </Button>
          </Box>
        </Box>
            <hr style={{marginTop:'2px'}} />
            <h6 style={{marginTop:'8px' , textAlign:'center'}} >
            กรุณาติดต่อเจ้าหน้าที่หากลืมรหัสผ่านหรือยังไม่เคยลงทะเบียน
            </h6>
            <h6 style={{textAlign:'center',marginBottom:'10px'}}>
            0902099333
            </h6>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
      </div>
  );
}