import "./Calendar.css";
import * as React from "react";
import liff from "@line/liff";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Box, Paper, TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Appcalendar from "./AppCalendar";
import Searchhistory from "./Searchhistory";

const Calendargrouplinefive = () => {
  const navigate = useNavigate();
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  //สลับหน้า
  const move = () => {
    navigate("/cancelappgroupline");
  };

  const movelogout = () => {
    Swal.fire({
      icon: "info",
      title: "<strong>แจ้งเตือน</strong>",
      html: "<h4>ท่านต้องการออกจากระบบใช่หรือไม่</h4>",
      showCancelButton: true,
      confirmButtonColor: "error",
      cancelButtonColor: "warning",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ออกจากระบบ",
    }).then((result) => {
      if (result.isConfirmed) {
        clearpage();
      }
    });
    return;
  };

  const clearpage = () => {
    localStorage.clear();
    navigate("/calendarusersgroupline");
  };

  //สลับหน้า

  //14:45
  return (
    <Paper style={{ marginBottom: "10px", marginTop: "10px", paddingTop: "10px", border: "1px solid #e1932c" }}>
      <div style={{ textAlign: "right", marginRight: "10px" }}>
        <Button variant="contained" color="warning" size="small" onClick={move} className="btnstatus" style={{ marginRight: "10px" }}>
          <p>ยกเลิกนัดหมาย</p>
        </Button>
        <Button variant="contained" color="error" size="small" onClick={movelogout} className="btnstatus">
          <p>ออกจากระบบ</p>
        </Button>
      </div>
      <div style={{ margin: "10px" }}>
        <Appcalendar />
      </div>
      <div style={{ margin: "10px" }}>
        <Searchhistory />
      </div>
    </Paper>
  );
};
export default Calendargrouplinefive;
