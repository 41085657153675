import liff from '@line/liff';
import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import {CssBaseline, Container,TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Search } from '@mui/icons-material';
import Searchapp from './Searchapp';

const Searchline = () => {
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const navigate = useNavigate()
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN")
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          liff.init({ liffId: '1657187328-M5eZRVK2' }, () => {
            liff.getProfile().then(profile => {
              axios.put(`${URL}/uuidline/${TN}`, {
                uuid: profile.userId
              })
              .then(function (response) {
                navigate("/searchline")
              })
              .catch(function (error) {
                console.log(error);
              });
              }).catch(err => console.error(err));
            }, err => console.error(err));
          } else {
          localStorage.removeItem('fname')
          localStorage.removeItem('lname')
          localStorage.removeItem('TN')
          localStorage.removeItem('role')
          localStorage.removeItem('prefix')
          localStorage.removeItem('uuid')
          navigate("/searchloginline");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);



  const [mandata, setMandata] = useState([]);
  // const mandataL = mandata.length
  const [data, setData] = useState([]);
  // const dataL = data.length
  const [datas, setDatas] = useState([]);
  // const datasL = datas.length
  const [datass, setDatass] = useState([]);
  // const datassL = datass.length
  const [datasss, setDatasss] = useState([]);
  // const datassL = datass.length

  //จำนวนครั้งที่นัดหมาย
    // const Apl = mandataL+dataL+datasL+datassL
  //0ำนวนครั้งที่นัดหมาย


  const getdata = ()=>{
    const tntoken = localStorage.getItem("TN");
    const usersURL1 = `${URL}/manappoinment/`;
    const usersURL2 = `${URL}/appoinment/`;
    const usersURL3 = `${URL}/appoinments/`;
    const usersURL4 = `${URL}/appoinmentss/`;
    const usersURL5 = `${URL}/appoinmentsss/`;
    axios.get(`${usersURL1}${tntoken}`).then((res) => {
      setMandata(res.data);
    });
    axios.get(`${usersURL2}${tntoken}`).then((res) => {
      setData(res.data);
    });
    axios.get(`${usersURL3}${tntoken}`).then((res) => {
      setDatas(res.data);
    });
    axios.get(`${usersURL4}${tntoken}`).then((res) => {
      setDatass(res.data);
    });
    axios.get(`${usersURL5}${tntoken}`).then((res) => {
      setDatasss(res.data);
    });
  }
  //ดึงข้อมูลผิดนัด
  const [datablock, setDatablock] = useState([]);
  const getblock = ()=>{
    const tntoken = localStorage.getItem("TN");
    const usersURL1 = `${URL}/blocklist/`;
    axios.get(`${usersURL1}${tntoken}`).then((res) => {
      setDatablock(res.data);
    });
  }
  //ดึงข้อมูลผิดนัด

  useEffect(() => {
    getdata()
    getblock()
  }, []);

  //cancel
  //คนที่1


  // const confirmcancel1 = (AN) =>{
  //   Swal.fire({
  //     title: '!แจ้งเตือน',
  //     text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'ยกเลิกนัดหมาย',
  //     cancelButtonText: 'ปิด'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       canceldata(AN)
  //     }
  //   })
  // }
  
  // const canceldata = (AN) => {
  //   var data = JSON.stringify({
  //     AN: AN,
  //   });

  //   var config = {
  //     method: "delete",
  //     url: `${URL}/clearwomen`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if((response.data["status"] === "OK")){
  //         Swal.fire({
  //           title: 'เสร็จสิ้น',
  //           text:'ยกเลิกนัดหมายเรียบร้อย',
  //           showConfirmButton: false,
  //           timer: 2000
  //         })
  //         .then((result)=>{
  //           getdata()
  //       })
  //     } else {
  //       alert(JSON.stringify(response.data))
  //     }
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  //คนที่1
  //คนที่2

  // const confirmcancel2 = (AN) =>{
  //   Swal.fire({
  //     title: '!แจ้งเตือน',
  //     text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'ยกเลิกนัดหมาย',
  //     cancelButtonText: 'ปิด'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       canceldatas(AN)
  //     }
  //   })

  // }

  // const canceldatas = (AN) => {
  //   var data = JSON.stringify({
  //     AN: AN,
  //   });

  //   var config = {
  //     method: "delete",
  //     url: `${URL}/clearwomens`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if((response.data["status"] === "OK")){
  //         Swal.fire({
  //           title: 'เสร็จสิ้น',
  //           text:'ยกเลิกนัดหมายเรียบร้อย',
  //           showConfirmButton: false,
  //           timer: 2000
  //         })
  //         .then((result)=>{
  //           getdata()
  //       })
  //     } else {
  //       alert(JSON.stringify(response.data))
  //     }
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  //คนที่2
  //คนที่3

  // const confirmcancel3 = (AN) =>{
  //   Swal.fire({
  //     title: '!แจ้งเตือน',
  //     text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'ยกเลิกนัดหมาย',
  //     cancelButtonText: 'ปิด'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       canceldatass(AN)
  //     }
  //   })

  // }

  // const canceldatass = (AN) => {
  //   var data = JSON.stringify({
  //     AN: AN,
  //   });

  //   var config = {
  //     method: "delete",
  //     url: `${URL}/clearwomenss`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if((response.data["status"] === "OK")){
  //         Swal.fire({
  //           title: 'เสร็จสิ้น',
  //           text:'ยกเลิกนัดหมายเรียบร้อย',
  //           showConfirmButton: false,
  //           timer: 2000
  //         })
  //         .then((result)=>{
  //           getdata()
  //       })
  //     } else {
  //       alert(JSON.stringify(response.data))
  //     }
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  //คนที่3
  //คนที่4
  // const [ancancel4, setANcancel4] = useState(null);
  // const [open4, setOpen4] = useState(false);


  // const handleOpen4 = (AN) => {
  //   setANcancel4(AN);
  //   setOpen4(true);
  //   return;
  // };
  // const handleClose4 = () => setOpen4(false);

  // const confirmcancel4 = (AN) =>{
  //   Swal.fire({
  //     title: '!แจ้งเตือน',
  //     text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'ยกเลิกนัดหมาย',
  //     cancelButtonText: 'ปิด'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       canceldataman(AN)
  //     }
  //   })

  // }

  // const canceldataman = (AN) => {
  //   var data = JSON.stringify({
  //     AN: AN,
  //   });

  //   var config = {
  //     method: "delete",
  //     url: `${URL}/clearman`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       if((response.data["status"] === "OK")){
  //         Swal.fire({
  //           title: 'เสร็จสิ้น',
  //           text:'ยกเลิกนัดหมายเรียบร้อย',
  //           showConfirmButton: false,
  //           timer: 2000
  //         })
  //         .then((result)=>{
  //           getdata();
  //       })
  //     } else {
  //       alert(JSON.stringify(response.data))
  //     }
  //   })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  //คนที่4
  //canceldata




  // ดึงข้อมูลนัดหมาย
  const [appdata, setAppdata] = useState([]);
  const appdataone = () => {
    const usersURL = `${URL}/appoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdata(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdatas, setAppdatas] = useState([]);
  const appdatatwo = () => {
    const usersURL = `${URL}/appoinments`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatas(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdatass, setAppdatass] = useState([]);
  const appdatatree=() => {
    const usersURL = `${URL}/appoinmentss`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatass(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdatasss, setAppdatasss] = useState([]);
  const appdatafive=() => {
    const usersURL = `${URL}/appoinmentsss`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatasss(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdataman, setAppdataman] = useState([]);
  const appdatafour = () => {
    const usersURL = `${URL}/manappoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdataman(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }


  useEffect(() => {
    appdataone()
    appdatatwo()
    appdatatree()
    appdatafive()
    appdatafour()
  }, []);
  
  const appalldata = [...appdata, ...appdatas, ...appdatass, ...appdatasss, ...appdataman];

  const [appsearch, setAppsearch] = useState(null);
  const apptesttt = (e) => {
    if(e.target.value === ''){
      return setAppsearch(null)
    }
    else{
      return setAppsearch(e.target.value);
    }
  };

  // ดึงข้อมูลนัดหมาย

  return (
    <div className="aboutpages" style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
      <CssBaseline />
      <Container>
        <Box>
          <Searchapp/>
          {/* <Paper style={{ marginBottom: "10px",marginTop:'10px',paddingTop:'10px',border:'1px solid #e1932c'}}>
            <div style={{marginTop:"8px" }}>
                <h4 style={{ textAlign: "center", color: "black" }}>
                  นัดหมาย
                </h4>
                <h4 style={{ textAlign: "center", color: "black"}}>
                  คุณ{fnametoken} {lnametoken}
                </h4>
                <h5 style={{ textAlign: "center", color: "black" }}>
                  จำนวนที่ผิดนัดในเดือนนี้ทั้งหมด = {datablock.length} ครั้ง
                </h5>
                <h6 style={{ textAlign: "center", color: "red",paddingBottom:"10px"  }}>
                  ระบบนัดหมายด้วยตนเองสามารถผิดนัดได้ไม่เกิน 3 ครั้ง/เดือน
                </h6>
            </div>
              <table style={{width:'98%', marginLeft: 'auto', marginRight:'auto'}}  >
                <thead>
                  <tr className="head" style={{fontSize:"12px"}}>
                    <td>
                      <h4>วันที่</h4>
                    </td>
                    <td>
                      <h4>เวลา</h4>
                    </td>
                    <td>
                      <h4>หัตถการ</h4>
                    </td>
                    <td>
                      <h4>เจ้าหน้าที่</h4>
                    </td>
                  </tr>
                </thead>

                <tbody className="search" style={{fontSize:"12px"}} >
                  {data.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.service}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                  {datas.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.service}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                  {datass.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.service}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                  {datasss.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.service}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                  {mandata.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.service}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{marginTop:"8px"}}>
                <h4 style={{ textAlign: "center", color: "black",paddingTop:"10px",marginBottom:'6px'}}>
                    ค้นหานัดหมาย
                </h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "8px",
              }}
              >
              <TextField
              style={{width:'95%'}}
                id="fullWidth"
                label="กรอกชื่อหรือนามสกุล เพื่อค้นหานัดหมาย..."
                align="center"
                variant="outlined"
                onChange={apptesttt}
                size="small"
              />
            </div>
              <table style={{width:'98%', marginLeft: 'auto', marginRight:'auto',marginBottom:'30px'}}  >
                <thead>
                  <tr className="head" style={{fontSize:"12px"}}>
                    <td>
                      <h4>วันที่</h4>
                    </td>
                    <td>
                      <h4>เวลา</h4>
                    </td>
                    <td>
                      <h4>ชื่อ-สกุล</h4>
                    </td>
                    <td>
                      <h4>เจ้าหน้าที่</h4>
                    </td>
                  </tr>
                </thead>
                <tbody className="search" style={{fontSize:"12px"}} >
                  {appalldata
                      .filter((e) => {
                        return (
                          e.fname.includes(appsearch) ||
                          e.lname.includes(appsearch) ||
                          e.TN.toString().includes(appsearch) ||
                          e.tel.includes(appsearch)
                        );
                      })
                      .map((item) => (
                        <tr key={uuidv4()}>
                        <td>{item.date}</td>
                        <td>{item.time}</td>
                        <td>{item.prefix}{item.fname} {item.lname}</td>
                        <td>{item.provider}</td>
                      </tr>
                      ))}
                  </tbody>
              </table>
          </Paper> */}
        </Box>
      </Container>
    </div>
  );
};

export default Searchline ;
