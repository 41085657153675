import { useEffect, useState } from "react";
import axios from "axios";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { CssBaseline, Container,TextField,Table,TableRow,TableHead,TableCell,TableBody} from "@mui/material";
import Swal from "sweetalert2";



const Searchusers = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  useEffect(() => {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
                Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/";
          })
        }   
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/login";
          })
        }
      })
    .catch(function (error) {
      console.log(error);
    });

    }, [URL]);




  const [usersdata, setUsersdata] = useState([]);

  useEffect(() => {
    const usersURL = `${URL}/users`;
    axios.get(`${usersURL}`).then((res) => {
      res.data.shift()
      res.data.shift()
      res.data.shift()
      res.data.shift()
      res.data.shift()
      res.data.shift()
      setUsersdata(res.data);
    });
  }, [URL]);

  const [search, setSearch] = useState(null);
  const testtt = (e) => {
    if(e.target.value === ''){
      return setSearch(null)
    }
    else{
      return setSearch(e.target.value);
    }
  };

  return (
    <div>
        <CssBaseline />
        <Container maxWidth="sm"> 
          <Paper >
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}>
                <TextField
                  id="fullWidth"
                  label="ค้นหา..."
                  align="center"
                  variant="outlined"
                  onChange={testtt}
                  size="small"
                  style={{marginTop:"10px",width:"90%"}}
                />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <TableContainer sx={{ height: "200px", maxWidth: "500px" }} style={{marginBottom:"10px"}}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "60px",
                          maxHeight: "30px",
                          padding: "5px 0 5px 0",
                          backgroundColor: "#e79129",
                        }}
                        className="searchbacktn"
                      >
                        TN
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxHeight: "30px",
                          padding: "5px 0 5px 0",
                          backgroundColor: "#e79129",
                        }}
                      >
                        ชื่อ-สกุล
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxHeight: "30px",
                          padding: "0 0 0 0",
                          backgroundColor: "#e79129",
                        }}
                      >
                        เบอร์โทรศัพท์
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "60px",
                          padding: "5px 5px 5px 5px",
                          backgroundColor: "#e79129",
                        }}
                        className="searchbackrole"
                      >
                        สิทธิ์
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersdata
                      .filter((e) => {
                        return (
                          e.fname.includes(search) ||
                          e.lname.includes(search) ||
                          e.TN.toString().includes(search) ||
                          e.tel.includes(search)
                        );
                      })
                      .map((item) => {
                        return (
                          <TableRow key={uuidv4()} className='searchbackrow'>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "60px",
                                padding: "5px 0 5px 0",
                              }}
                              className="searchbacktn"
                            >
                            {item.TN}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ maxHeight: "30px", padding: "0 0 0 10px" }}
                            >
                            {item.prefix}{item.fname} {item.lname}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "30px",
                                padding: "5px 0 5px 0",
                              }}
                            >
                            {item.tel}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "60px",
                                padding: "5px 5px 5px 5px",
                              }}
                              className="searchbackrole"
                            >
                            {item.role}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
          </Container>
    </div>
  );
};
export default Searchusers;
