import { Link,useNavigate } from 'react-router-dom';
import './sidebar.css'

function Sidebar() {
  const tntoken = localStorage.getItem('token')
  const navigate = useNavigate()

  const clearpage = () =>{
    localStorage.clear()
    navigate('/');
  }

  if (tntoken !== null){
  return (
    <div className="box">
      <div className="content">
          <Link to="/calendar">ปฏิทินนัด</Link>
          <Link to="/search">ตรวจสอบนัด</Link>
          <Link to="/reviews">ประเมินความพึงพอใจ</Link>
          <Link to="/" onClick={clearpage} >ออกจากระบบ</Link>
          {/* <a href="tel:0902099333">ติดต่อเจ้าหน้าที่</a> */}
      </div>
    </div>
  );
  }return (
    <div className="box">
      <div className="content">
          <Link to="/calendarusers">ปฏิทินนัด</Link>
          <Link to="/search">ตรวจสอบนัด</Link>
          <Link to="/aboutme"> เกี่ยวกับเรา</Link>
          <Link to="/login">เข้าสู่ระบบ</Link>
          {/* <a href="tel:0902099333">ติดต่อเจ้าหน้าที่</a> */}
      </div>
    </div>
  );
}

export default Sidebar;