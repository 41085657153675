import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import DatePicker from "react-date-picker";
import { Button } from "@mui/material";
import { CancelOutlined, EventAvailable, EventBusy } from "@mui/icons-material/";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { TextField, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Modal } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function One() {
  const navigate = useNavigate();
  const tntoken = localStorage.getItem("TN");
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const prefixtoken = localStorage.getItem("prefix");
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const URLAlert = "https://lineapi.thaipadi.org";
  //const URLAlert = "http://localhost:4000";

  //date
  const [startDate, setStartDate] = useState(new Date());
  const [dayname, setDayname] = useState("");
  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);
  //date

  //ดึงข้อมูลผิดนัด
  const [datablock, setDatablock] = useState([]);
  const getblock = () => {
    const tntoken = localStorage.getItem("TN");
    const URLblocklist = `${URL}/getblock/${tntoken}`;
    axios.get(URLblocklist).then((res) => {
      setDatablock(res.data.length);
    });
  };
  //ดึงข้อมูลผิดนัด

  //จำนวนครั้งที่นัด
  const [dataL, setDataL] = useState([]);
  const CheckL = async () => {
    try {
      const appoinment = [
        { name: "appoinment", id: "1" },
        { name: "appoinments", id: "2" },
        { name: "appoinmentss", id: "3" },
        { name: "appoinmentsss", id: "4" },
        { name: "appoinmentssss", id: "5" },
        { name: "appoinmentsssss", id: "6" },
      ];
      const api = appoinment.map((item) => {
        return `${URL}/${item.name}/${tntoken}`;
      });
      const [res1, res2, res3, res4, res5, res6] = await Promise.all([axios.get(api[0]), axios.get(api[1]), axios.get(api[2]), axios.get(api[3]), axios.get(api[4]), axios.get(api[5])]);
      setDataL(res1.data.length + res2.data.length + res3.data.length + res4.data.length + res5.data.length + res6.data.length);
    } catch (error) {
      console.log(error);
    }
  };
  //จำนวนครั้งที่นัด

  useEffect(() => {
    getblock();
    CheckL();
  }, []);

  //แจ้งเตือนไลน์กลุ่มประจำวัน
  const linealert = () => {
    if (startDate.toLocaleDateString() === new Date().toLocaleDateString()) {
      var config = {
        method: "post",
        url: `${URLAlert}/alertline`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return;
    }
  };
  //แจ้งเตือนไลน์กลุ่มประจำวัน

  //แจ้งเตือนกลุ่มadmin
  const alert = (appurl, timeid, date) => {
    var data = JSON.stringify({
      appurl: appurl,
      timeid: timeid,
      date: date,
    });
    var config = {
      method: "post",
      url: `${URLAlert}/appoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const cancelalert = (AN, appurl, appid, appcancel, timeid) => {
    var data = JSON.stringify({
      appurl: appurl,
      timeid: timeid,
      date: selectdate,
    });
    var config = {
      method: "post",
      url: `${URLAlert}/cancelappoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        canceldata(AN, appurl, appid, appcancel, timeid);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "ผิดพลาด",
          text: "ไม่สามารถยกเลิกนัดหมายได่้",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          console.log(error);
        });
        return;
      });
  };
  //แจ้งเตือนกลุ่มadmin

  //clerdata
  const addappoinment = (appurl, appid, timestring, timeid) => {
    var data = JSON.stringify({
      TN: tntoken,
      date: selectdate,
      time: timestring,
      service: service,
      provider: provider,
      dateapp: startDate,
    });

    var config = {
      method: "post",
      url: `${URL}/${appurl}s`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          linealert();
          alert(appurl, timeid, selectdate);
          CheckL();
          recheck(appurl, appid, timeid);
          setOpen(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const canceldata = (AN, appurl, appid, appcancel, timeid) => {
    var data = JSON.stringify({
      AN: AN,
    });
    var config = {
      method: "delete",
      url: `${URL}/${appcancel}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          linealert();
          CheckL();
          recheck(appurl, appid, timeid);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const recheck = (appurl, appid, timeid) => {
    const PostURL = `${URL}/${appurl}/${timeid}/${selectdate}`;
    axios.get(PostURL).then((res) => {
      switch (appid) {
        case "91":
          setReData91(res.data[0]);
          break;
        case "92":
          setReData92(res.data[0]);
          break;
        case "93":
          setReData93(res.data[0]);
          break;
        case "94":
          setReData94(res.data[0]);
          break;
        case "95":
          setReData95(res.data[0]);
          break;
        case "96":
          setReData96(res.data[0]);
          break;
        case "101":
          setReData101(res.data[0]);
          break;
        case "102":
          setReData102(res.data[0]);
          break;
        case "103":
          setReData103(res.data[0]);
          break;
        case "104":
          setReData104(res.data[0]);
          break;
        case "105":
          setReData105(res.data[0]);
          break;
        case "106":
          setReData106(res.data[0]);
          break;
        case "111":
          setReData111(res.data[0]);
          break;
        case "112":
          setReData112(res.data[0]);
          break;
        case "113":
          setReData113(res.data[0]);
          break;
        case "114":
          setReData114(res.data[0]);
          break;
        case "115":
          setReData115(res.data[0]);
          break;
        case "116":
          setReData116(res.data[0]);
          break;
        case "131":
          setReData131(res.data[0]);
          break;
        case "132":
          setReData132(res.data[0]);
          break;
        case "133":
          setReData133(res.data[0]);
          break;
        case "134":
          setReData134(res.data[0]);
          break;
        case "135":
          setReData135(res.data[0]);
          break;
        case "136":
          setReData136(res.data[0]);
          break;
        case "141":
          setReData141(res.data[0]);
          break;
        case "142":
          setReData142(res.data[0]);
          break;
        case "143":
          setReData143(res.data[0]);
          break;
        case "144":
          setReData144(res.data[0]);
          break;
        case "145":
          setReData145(res.data[0]);
          break;
        case "146":
          setReData146(res.data[0]);
          break;
        default:
        // code block
      }
    });
  };
  //clerdata

  //seturl,id
  const time9 = ["08:50", "9"];
  const time10 = ["09:55", "10"];
  const time11 = ["11:00", "11"];
  const time13 = ["13:30", "13"];
  const time14 = ["14:45", "14"];
  const time16 = ["16:15", "16"];
  const time17 = ["17:30", "17"];
  const providername = ["หญิง", "ฟาตีเม๊าะ"];
  const [appUrl, setAppUrl] = useState([]);
  const [appId, setAppId] = useState([]);
  const [timeString, setTimeString] = useState([]);
  const [provider, setProvider] = useState([]);
  const [timeId, setTimeId] = useState([]);
  //seturl,id

  //91
  const [data91, setData91] = useState([]);
  const [reData91, setReData91] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [status91, setStatus91] = useState("ว่าง");
  const [app91, setApp91] = useState(true);
  const [cancel91, setCancel91] = useState(true);

  //92
  const [data92, setData92] = useState([]);
  const [reData92, setReData92] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [status92, setStatus92] = useState("ว่าง");
  const [app92, setApp92] = useState(true);
  const [cancel92, setCancel92] = useState(true);

  //93
  const [data93, setData93] = useState([]);
  const [reData93, setReData93] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [status93, setStatus93] = useState("ว่าง");
  const [app93, setApp93] = useState(true);
  const [cancel93, setCancel93] = useState(true);

  //94
  const [data94, setData94] = useState([]);
  const [reData94, setReData94] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [status94, setStatus94] = useState("ว่าง");
  const [app94, setApp94] = useState(true);
  const [cancel94, setCancel94] = useState(true);

  //95
  const [data95, setData95] = useState([]);
  const [reData95, setReData95] = useState([]);
  const [editButton95, setEditButton95] = useState(false);
  const [icon95, setIcon95] = useState(<EventAvailable />);
  const [status95, setStatus95] = useState("ว่าง");
  const [app95, setApp95] = useState(true);
  const [cancel95, setCancel95] = useState(true);

  //96
  const [data96, setData96] = useState([]);
  const [reData96, setReData96] = useState([]);
  const [editButton96, setEditButton96] = useState(false);
  const [icon96, setIcon96] = useState(<EventAvailable />);
  const [status96, setStatus96] = useState("ว่าง");
  const [app96, setApp96] = useState(true);
  const [cancel96, setCancel96] = useState(true);

  //101
  const [data101, setData101] = useState([]);
  const [reData101, setReData101] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [status101, setStatus101] = useState("ว่าง");
  const [app101, setApp101] = useState(true);
  const [cancel101, setCancel101] = useState(true);

  //102
  const [data102, setData102] = useState([]);
  const [reData102, setReData102] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [status102, setStatus102] = useState("ว่าง");
  const [app102, setApp102] = useState(true);
  const [cancel102, setCancel102] = useState(true);

  //103
  const [data103, setData103] = useState([]);
  const [reData103, setReData103] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [status103, setStatus103] = useState("ว่าง");
  const [app103, setApp103] = useState(true);
  const [cancel103, setCancel103] = useState(true);

  //104
  const [data104, setData104] = useState([]);
  const [reData104, setReData104] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [status104, setStatus104] = useState("ว่าง");
  const [app104, setApp104] = useState(true);
  const [cancel104, setCancel104] = useState(true);

  //105
  const [data105, setData105] = useState([]);
  const [reData105, setReData105] = useState([]);
  const [editButton105, setEditButton105] = useState(false);
  const [icon105, setIcon105] = useState(<EventAvailable />);
  const [status105, setStatus105] = useState("ว่าง");
  const [app105, setApp105] = useState(true);
  const [cancel105, setCancel105] = useState(true);

  //106
  const [data106, setData106] = useState([]);
  const [reData106, setReData106] = useState([]);
  const [editButton106, setEditButton106] = useState(false);
  const [icon106, setIcon106] = useState(<EventAvailable />);
  const [status106, setStatus106] = useState("ว่าง");
  const [app106, setApp106] = useState(true);
  const [cancel106, setCancel106] = useState(true);

  //111
  const [data111, setData111] = useState([]);
  const [reData111, setReData111] = useState([]);
  const [editButton111, setEditButton111] = useState(false);
  const [icon111, setIcon111] = useState(<EventAvailable />);
  const [status111, setStatus111] = useState("ว่าง");
  const [app111, setApp111] = useState(true);
  const [cancel111, setCancel111] = useState(true);

  //112
  const [data112, setData112] = useState([]);
  const [reData112, setReData112] = useState([]);
  const [editButton112, setEditButton112] = useState(false);
  const [icon112, setIcon112] = useState(<EventAvailable />);
  const [status112, setStatus112] = useState("ว่าง");
  const [app112, setApp112] = useState(true);
  const [cancel112, setCancel112] = useState(true);

  //113
  const [data113, setData113] = useState([]);
  const [reData113, setReData113] = useState([]);
  const [editButton113, setEditButton113] = useState(false);
  const [icon113, setIcon113] = useState(<EventAvailable />);
  const [status113, setStatus113] = useState("ว่าง");
  const [app113, setApp113] = useState(true);
  const [cancel113, setCancel113] = useState(true);

  //114
  const [data114, setData114] = useState([]);
  const [reData114, setReData114] = useState([]);
  const [editButton114, setEditButton114] = useState(false);
  const [icon114, setIcon114] = useState(<EventAvailable />);
  const [status114, setStatus114] = useState("ว่าง");
  const [app114, setApp114] = useState(true);
  const [cancel114, setCancel114] = useState(true);

  //115
  const [data115, setData115] = useState([]);
  const [reData115, setReData115] = useState([]);
  const [editButton115, setEditButton115] = useState(false);
  const [icon115, setIcon115] = useState(<EventAvailable />);
  const [status115, setStatus115] = useState("ว่าง");
  const [app115, setApp115] = useState(true);
  const [cancel115, setCancel115] = useState(true);

  //116
  const [data116, setData116] = useState([]);
  const [reData116, setReData116] = useState([]);
  const [editButton116, setEditButton116] = useState(false);
  const [icon116, setIcon116] = useState(<EventAvailable />);
  const [status116, setStatus116] = useState("ว่าง");
  const [app116, setApp116] = useState(true);
  const [cancel116, setCancel116] = useState(true);

  //131
  const [data131, setData131] = useState([]);
  const [reData131, setReData131] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [status131, setStatus131] = useState("ว่าง");
  const [app131, setApp131] = useState(true);
  const [cancel131, setCancel131] = useState(true);

  //132
  const [data132, setData132] = useState([]);
  const [reData132, setReData132] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [status132, setStatus132] = useState("ว่าง");
  const [app132, setApp132] = useState(true);
  const [cancel132, setCancel132] = useState(true);

  //133
  const [data133, setData133] = useState([]);
  const [reData133, setReData133] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [status133, setStatus133] = useState("ว่าง");
  const [app133, setApp133] = useState(true);
  const [cancel133, setCancel133] = useState(true);

  //134
  const [data134, setData134] = useState([]);
  const [reData134, setReData134] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [status134, setStatus134] = useState("ว่าง");
  const [app134, setApp134] = useState(true);
  const [cancel134, setCancel134] = useState(true);

  //135
  const [data135, setData135] = useState([]);
  const [reData135, setReData135] = useState([]);
  const [editButton135, setEditButton135] = useState(false);
  const [icon135, setIcon135] = useState(<EventAvailable />);
  const [status135, setStatus135] = useState("ว่าง");
  const [app135, setApp135] = useState(true);
  const [cancel135, setCancel135] = useState(true);

  //136
  const [data136, setData136] = useState([]);
  const [reData136, setReData136] = useState([]);
  const [editButton136, setEditButton136] = useState(false);
  const [icon136, setIcon136] = useState(<EventAvailable />);
  const [status136, setStatus136] = useState("ว่าง");
  const [app136, setApp136] = useState(true);
  const [cancel136, setCancel136] = useState(true);

  //141
  const [data141, setData141] = useState([]);
  const [reData141, setReData141] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [status141, setStatus141] = useState("ว่าง");
  const [app141, setApp141] = useState(true);
  const [cancel141, setCancel141] = useState(true);

  //142
  const [data142, setData142] = useState([]);
  const [reData142, setReData142] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [status142, setStatus142] = useState("ว่าง");
  const [app142, setApp142] = useState(true);
  const [cancel142, setCancel142] = useState(true);

  //143
  const [data143, setData143] = useState([]);
  const [reData143, setReData143] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [status143, setStatus143] = useState("ว่าง");
  const [app143, setApp143] = useState(true);
  const [cancel143, setCancel143] = useState(true);

  //144
  const [data144, setData144] = useState([]);
  const [reData144, setReData144] = useState([]);
  const [editButton144, setEditButton144] = useState(false);
  const [icon144, setIcon144] = useState(<EventAvailable />);
  const [status144, setStatus144] = useState("ว่าง");
  const [app144, setApp144] = useState(true);
  const [cancel144, setCancel144] = useState(true);

  //145
  const [data145, setData145] = useState([]);
  const [reData145, setReData145] = useState([]);
  const [editButton145, setEditButton145] = useState(false);
  const [icon145, setIcon145] = useState(<EventAvailable />);
  const [status145, setStatus145] = useState("ว่าง");
  const [app145, setApp145] = useState(true);
  const [cancel145, setCancel145] = useState(true);

  //146
  const [data146, setData146] = useState([]);
  const [reData146, setReData146] = useState([]);
  const [editButton146, setEditButton146] = useState(false);
  const [icon146, setIcon146] = useState(<EventAvailable />);
  const [status146, setStatus146] = useState("ว่าง");
  const [app146, setApp146] = useState(true);
  const [cancel146, setCancel146] = useState(true);

  //161
  const [data161, setData161] = useState([]);
  const [reData161, setReData161] = useState([]);
  const [editButton161, setEditButton161] = useState(false);
  const [icon161, setIcon161] = useState(<EventAvailable />);
  const [status161, setStatus161] = useState("ว่าง");
  const [app161, setApp161] = useState(true);
  const [cancel161, setCancel161] = useState(true);

  //162
  const [data162, setData162] = useState([]);
  const [reData162, setReData162] = useState([]);
  const [editButton162, setEditButton162] = useState(false);
  const [icon162, setIcon162] = useState(<EventAvailable />);
  const [status162, setStatus162] = useState("ว่าง");
  const [app162, setApp162] = useState(true);
  const [cancel162, setCancel162] = useState(true);

  //163
  const [data163, setData163] = useState([]);
  const [reData163, setReData163] = useState([]);
  const [editButton163, setEditButton163] = useState(false);
  const [icon163, setIcon163] = useState(<EventAvailable />);
  const [status163, setStatus163] = useState("ว่าง");
  const [app163, setApp163] = useState(true);
  const [cancel163, setCancel163] = useState(true);

  //171
  const [data171, setData171] = useState([]);
  const [reData171, setReData171] = useState([]);
  const [editButton171, setEditButton171] = useState(false);
  const [icon171, setIcon171] = useState(<EventAvailable />);
  const [status171, setStatus171] = useState("ว่าง");
  const [app171, setApp171] = useState(true);
  const [cancel171, setCancel171] = useState(true);

  //172
  const [data172, setData172] = useState([]);
  const [reData172, setReData172] = useState([]);
  const [editButton172, setEditButton172] = useState(false);
  const [icon172, setIcon172] = useState(<EventAvailable />);
  const [status172, setStatus172] = useState("ว่าง");
  const [app172, setApp172] = useState(true);
  const [cancel172, setCancel172] = useState(true);

  //173
  const [data173, setData173] = useState([]);
  const [reData173, setReData173] = useState([]);
  const [editButton173, setEditButton173] = useState(false);
  const [icon173, setIcon173] = useState(<EventAvailable />);
  const [status173, setStatus173] = useState("ว่าง");
  const [app173, setApp173] = useState(true);
  const [cancel173, setCancel173] = useState(true);

  //api

  const getdata = async () => {
    try {
      const date = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;

      const appoinment = [
        { name: "appoinment", id: "9" },
        { name: "appoinments", id: "9" },
        { name: "appoinmentss", id: "9" },
        { name: "appoinmentsss", id: "9" },
        { name: "appoinmentssss", id: "9" },
        { name: "appoinmentsssss", id: "9" },
        { name: "appoinment", id: "10" },
        { name: "appoinments", id: "10" },
        { name: "appoinmentss", id: "10" },
        { name: "appoinmentsss", id: "10" },
        { name: "appoinmentssss", id: "10" },
        { name: "appoinmentsssss", id: "10" },
        { name: "appoinment", id: "11" },
        { name: "appoinments", id: "11" },
        { name: "appoinmentss", id: "11" },
        { name: "appoinmentsss", id: "11" },
        { name: "appoinmentssss", id: "11" },
        { name: "appoinmentsssss", id: "11" },
        { name: "appoinment", id: "13" },
        { name: "appoinments", id: "13" },
        { name: "appoinmentss", id: "13" },
        { name: "appoinmentsss", id: "13" },
        { name: "appoinmentssss", id: "13" },
        { name: "appoinmentsssss", id: "13" },
        { name: "appoinment", id: "14" },
        { name: "appoinments", id: "14" },
        { name: "appoinmentss", id: "14" },
        { name: "appoinmentsss", id: "14" },
        { name: "appoinmentssss", id: "14" },
        { name: "appoinmentsssss", id: "14" },
        { name: "appoinment", id: "16" },
        { name: "appoinments", id: "16" },
        { name: "appoinmentss", id: "16" },
        { name: "appoinment", id: "17" },
        { name: "appoinments", id: "17" },
        { name: "appoinmentss", id: "17" },
      ];

      const api = appoinment.map((item) => {
        return `${URL}/${item.name}/${item.id}/${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
      });

      const [res91, res92, res93, res94, res95, res96, res101, res102, res103, res104, res105, res106, res111, res112, res113, res114, res115, res116, res131, res132, res133, res134, res135, res136, res141, res142, res143, res144, res145, res146, res161, res162, res163, res171, res172, res173] = await Promise.all([
        axios.get(api[0]),
        axios.get(api[1]),
        axios.get(api[2]),
        axios.get(api[3]),
        axios.get(api[4]),
        axios.get(api[5]),
        axios.get(api[6]),
        axios.get(api[7]),
        axios.get(api[8]),
        axios.get(api[9]),
        axios.get(api[10]),
        axios.get(api[11]),
        axios.get(api[12]),
        axios.get(api[13]),
        axios.get(api[14]),
        axios.get(api[15]),
        axios.get(api[16]),
        axios.get(api[17]),
        axios.get(api[18]),
        axios.get(api[19]),
        axios.get(api[20]),
        axios.get(api[21]),
        axios.get(api[22]),
        axios.get(api[23]),
        axios.get(api[24]),
        axios.get(api[25]),
        axios.get(api[26]),
        axios.get(api[27]),
        axios.get(api[28]),
        axios.get(api[29]),
        axios.get(api[30]),
        axios.get(api[31]),
        axios.get(api[32]),
        axios.get(api[33]),
        axios.get(api[34]),
        axios.get(api[35]),
      ]);
      const setdata = (res, id, time, TN) => {
        const roletoken = localStorage.getItem("role");
        const defaultdata = [
          {
            AN: null,
            TN: null,
            fname: "ว่าง",
            lname: null,
            service: "ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            fname: null,
            lname: null,
            service: null,
            provider: null,
            date: null,
            time: null,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ปิดบริการ",
            lname: "วันหยุดราชการ",
            service: "วันหยุดราชการหรืออื่นๆ",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)",
            lname: null,
            service: "ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ไม่ประสงค์เผยแพร่ชื่อสกุล",
            lname: null,
            service: "ไม่ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
        ];
        const dateInPast = function (firstDate, secondDate) {
          if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
            return true;
          }
          return false;
        };
        const holidaysURL = `${URL}/holidays`;
        switch (id) {
          case "91":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData91(defaultdata[3]);
                      setEditButton91(false);
                      setIcon91(<EventAvailable />);
                      setStatus91("ว่าง");
                      setCancel91(true);
                      setApp91(true);
                      return;
                    }
                    setData91(defaultdata[3]);
                    setEditButton91(true);
                    setIcon91(<EventBusy />);
                    setStatus91("สิทธิอื่น");
                    setCancel91(true);
                    setApp91(true);
                    return;
                  }
                  setData91(defaultdata[0]);
                  setEditButton91(false);
                  setIcon91(<EventAvailable />);
                  setStatus91("ว่าง");
                  setCancel91(true);
                  setApp91(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData91(res.data[0]);
                      setEditButton91(true);
                      setIcon91(<EventBusy />);
                      setStatus91("ไม่ว่าง");
                      setApp91(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel91(false);
                        return;
                      } else {
                        setCancel91(true);
                      }
                      return;
                    } else {
                      setData91(defaultdata[4]);
                      setEditButton91(true);
                      setIcon91(<EventBusy />);
                      setStatus91("ไม่ว่าง");
                      setApp91(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel91(false);
                        return;
                      } else {
                        setCancel91(true);
                      }
                      return;
                    }
                  } else {
                    setData91(res.data[0]);
                    setEditButton91(true);
                    setIcon91(<EventBusy />);
                    setStatus91("ไม่ว่าง");
                    setApp91(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel91(false);
                      return;
                    } else {
                      setCancel91(true);
                    }
                    return;
                  }
                }
                return;
              } else {
                setData91(defaultdata[2]);
                setEditButton91(true);
                setIcon91(<EventBusy />);
                setStatus91("ไม่ว่าง");
                setApp91(true);
                setCancel91(true);
                return;
              }
            });
            break;
          case "92":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData92(defaultdata[3]);
                      setEditButton92(false);
                      setIcon92(<EventAvailable />);
                      setStatus92("ว่าง");
                      setCancel92(true);
                      setApp92(true);
                      return;
                    }
                    setData92(defaultdata[3]);
                    setEditButton92(true);
                    setIcon92(<EventBusy />);
                    setStatus92("สิทธิอื่น");
                    setCancel92(true);
                    setApp92(true);
                    return;
                  }
                  setData92(defaultdata[0]);
                  setEditButton92(false);
                  setIcon92(<EventAvailable />);
                  setStatus92("ว่าง");
                  setCancel92(true);
                  setApp92(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData92(res.data[0]);
                      setEditButton92(true);
                      setIcon92(<EventBusy />);
                      setStatus92("ไม่ว่าง");
                      setApp92(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel92(false);
                        return;
                      } else {
                        setCancel92(true);
                      }
                      return;
                    } else {
                      setData92(defaultdata[4]);
                      setEditButton92(true);
                      setIcon92(<EventBusy />);
                      setStatus92("ไม่ว่าง");
                      setApp92(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel92(false);
                        return;
                      } else {
                        setCancel92(true);
                      }
                      return;
                    }
                  } else {
                    setData92(res.data[0]);
                    setEditButton92(true);
                    setIcon92(<EventBusy />);
                    setStatus92("ไม่ว่าง");
                    setApp92(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel92(false);
                      return;
                    } else {
                      setCancel92(true);
                    }
                    return;
                  }
                }
              } else {
                setData92(defaultdata[2]);
                setEditButton92(true);
                setIcon92(<EventBusy />);
                setStatus92("ไม่ว่าง");
                setApp92(true);
                setCancel92(true);
                return;
              }
            });
            break;
          case "93":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData93(defaultdata[3]);
                      setEditButton93(false);
                      setIcon93(<EventAvailable />);
                      setStatus93("ว่าง");
                      setCancel93(true);
                      setApp93(true);
                      return;
                    }
                    setData93(defaultdata[3]);
                    setEditButton93(true);
                    setIcon93(<EventBusy />);
                    setStatus93("สิทธิอื่น");
                    setCancel93(true);
                    setApp93(true);
                    return;
                  }
                  setData93(defaultdata[0]);
                  setEditButton93(false);
                  setIcon93(<EventAvailable />);
                  setStatus93("ว่าง");
                  setCancel93(true);
                  setApp93(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData93(res.data[0]);
                      setEditButton93(true);
                      setIcon93(<EventBusy />);
                      setStatus93("ไม่ว่าง");
                      setApp93(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel93(false);
                        return;
                      } else {
                        setCancel93(true);
                      }
                      return;
                    } else {
                      setData93(defaultdata[4]);
                      setEditButton93(true);
                      setIcon93(<EventBusy />);
                      setStatus93("ไม่ว่าง");
                      setApp93(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel93(false);
                        return;
                      } else {
                        setCancel93(true);
                      }
                      return;
                    }
                  } else {
                    setData93(res.data[0]);
                    setEditButton93(true);
                    setIcon93(<EventBusy />);
                    setStatus93("ไม่ว่าง");
                    setApp93(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel93(false);
                      return;
                    } else {
                      setCancel93(true);
                    }
                    return;
                  }
                }
              } else {
                setData93(defaultdata[2]);
                setEditButton93(true);
                setIcon93(<EventBusy />);
                setStatus93("ไม่ว่าง");
                setApp93(true);
                setCancel93(true);
                return;
              }
            });
            break;
          case "94":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData94(defaultdata[1]);
                    setEditButton94(true);
                    setIcon94(<EventBusy />);
                    setStatus94("ไม่ว่าง");
                    setCancel94(true);
                    setApp94(true);
                    return;
                  }
                  setData94(defaultdata[0]);
                  setEditButton94(false);
                  setIcon94(<EventAvailable />);
                  setStatus94("ว่าง");
                  setCancel94(true);
                  setApp94(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData94(res.data[0]);
                      setEditButton94(true);
                      setIcon94(<EventBusy />);
                      setStatus94("ไม่ว่าง");
                      setApp94(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel94(false);
                        return;
                      } else {
                        setCancel94(true);
                      }
                      return;
                    } else {
                      setData94(defaultdata[4]);
                      setEditButton94(true);
                      setIcon94(<EventBusy />);
                      setStatus94("ไม่ว่าง");
                      setApp94(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel94(false);
                        return;
                      } else {
                        setCancel94(true);
                      }
                      return;
                    }
                  } else {
                    setData94(res.data[0]);
                    setEditButton94(true);
                    setIcon94(<EventBusy />);
                    setStatus94("ไม่ว่าง");
                    setApp94(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel94(false);
                      return;
                    } else {
                      setCancel94(true);
                    }
                    return;
                  }
                }
              } else {
                setData94(defaultdata[2]);
                setEditButton94(true);
                setIcon94(<EventBusy />);
                setStatus94("ไม่ว่าง");
                setApp94(true);
                setCancel94(true);
                return;
              }
            });
            break;
          case "95":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData95(defaultdata[1]);
                    setEditButton95(true);
                    setIcon95(<EventBusy />);
                    setStatus95("ไม่ว่าง");
                    setCancel95(true);
                    setApp95(true);
                    return;
                  }
                  setData95(defaultdata[0]);
                  setEditButton95(false);
                  setIcon95(<EventAvailable />);
                  setStatus95("ว่าง");
                  setCancel95(true);
                  setApp95(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData95(res.data[0]);
                      setEditButton95(true);
                      setIcon95(<EventBusy />);
                      setStatus95("ไม่ว่าง");
                      setApp95(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel95(false);
                        return;
                      } else {
                        setCancel95(true);
                      }
                      return;
                    } else {
                      setData95(defaultdata[4]);
                      setEditButton95(true);
                      setIcon95(<EventBusy />);
                      setStatus95("ไม่ว่าง");
                      setApp95(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel95(false);
                        return;
                      } else {
                        setCancel95(true);
                      }
                      return;
                    }
                  } else {
                    setData95(res.data[0]);
                    setEditButton95(true);
                    setIcon95(<EventBusy />);
                    setStatus95("ไม่ว่าง");
                    setApp95(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel95(false);
                      return;
                    } else {
                      setCancel95(true);
                    }
                    return;
                  }
                }
              } else {
                setData95(defaultdata[2]);
                setEditButton95(true);
                setIcon95(<EventBusy />);
                setStatus95("ไม่ว่าง");
                setApp95(true);
                setCancel95(true);
                return;
              }
            });
            break;
          case "96":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData96(defaultdata[1]);
                    setEditButton96(true);
                    setIcon96(<EventBusy />);
                    setStatus96("ไม่ว่าง");
                    setCancel96(true);
                    setApp96(true);
                    return;
                  }
                  setData96(defaultdata[0]);
                  setEditButton96(false);
                  setIcon96(<EventAvailable />);
                  setStatus96("ว่าง");
                  setCancel96(true);
                  setApp96(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData96(res.data[0]);
                      setEditButton96(true);
                      setIcon96(<EventBusy />);
                      setStatus96("ไม่ว่าง");
                      setApp96(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel96(false);
                        return;
                      } else {
                        setCancel96(true);
                      }
                      return;
                    } else {
                      setData96(defaultdata[4]);
                      setEditButton96(true);
                      setIcon96(<EventBusy />);
                      setStatus96("ไม่ว่าง");
                      setApp96(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel96(false);
                        return;
                      } else {
                        setCancel96(true);
                      }
                      return;
                    }
                  } else {
                    setData96(res.data[0]);
                    setEditButton96(true);
                    setIcon96(<EventBusy />);
                    setStatus96("ไม่ว่าง");
                    setApp96(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel96(false);
                      return;
                    } else {
                      setCancel96(true);
                    }
                    return;
                  }
                }
              } else {
                setData96(defaultdata[2]);
                setEditButton96(true);
                setIcon96(<EventBusy />);
                setStatus96("ไม่ว่าง");
                setApp96(true);
                setCancel96(true);
                return;
              }
            });
            break;
          case "101":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData101(defaultdata[3]);
                      setEditButton101(false);
                      setIcon101(<EventAvailable />);
                      setStatus101("ว่าง");
                      setCancel101(true);
                      setApp101(true);
                      return;
                    }
                    setData101(defaultdata[3]);
                    setEditButton101(true);
                    setIcon101(<EventBusy />);
                    setStatus101("สิทธิอื่น");
                    setCancel101(true);
                    setApp101(true);
                    return;
                  }
                  setData101(defaultdata[0]);
                  setEditButton101(false);
                  setIcon101(<EventAvailable />);
                  setStatus101("ว่าง");
                  setCancel101(true);
                  setApp101(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData101(res.data[0]);
                      setEditButton101(true);
                      setIcon101(<EventBusy />);
                      setStatus101("ไม่ว่าง");
                      setApp101(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel101(false);
                        return;
                      } else {
                        setCancel101(true);
                      }
                      return;
                    } else {
                      setData101(defaultdata[4]);
                      setEditButton101(true);
                      setIcon101(<EventBusy />);
                      setStatus101("ไม่ว่าง");
                      setApp101(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel101(false);
                        return;
                      } else {
                        setCancel101(true);
                      }
                      return;
                    }
                  } else {
                    setData101(res.data[0]);
                    setEditButton101(true);
                    setIcon101(<EventBusy />);
                    setStatus101("ไม่ว่าง");
                    setApp101(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel101(false);
                      return;
                    } else {
                      setCancel101(true);
                    }
                    return;
                  }
                }
              } else {
                setData101(defaultdata[2]);
                setEditButton101(true);
                setIcon101(<EventBusy />);
                setStatus101("ไม่ว่าง");
                setApp101(true);
                setCancel101(true);
                return;
              }
            });
            break;
          case "102":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData102(defaultdata[3]);
                      setEditButton102(false);
                      setIcon102(<EventAvailable />);
                      setStatus102("ว่าง");
                      setCancel102(true);
                      setApp102(true);
                      return;
                    }
                    setData102(defaultdata[3]);
                    setEditButton102(true);
                    setIcon102(<EventBusy />);
                    setStatus102("สิทธิอื่น");
                    setCancel102(true);
                    setApp102(true);
                    return;
                  }
                  setData102(defaultdata[0]);
                  setEditButton102(false);
                  setIcon102(<EventAvailable />);
                  setStatus102("ว่าง");
                  setCancel102(true);
                  setApp102(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData102(res.data[0]);
                      setEditButton102(true);
                      setIcon102(<EventBusy />);
                      setStatus102("ไม่ว่าง");
                      setApp102(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel102(false);
                        return;
                      } else {
                        setCancel102(true);
                      }
                      return;
                    } else {
                      setData102(defaultdata[4]);
                      setEditButton102(true);
                      setIcon102(<EventBusy />);
                      setStatus102("ไม่ว่าง");
                      setApp102(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel102(false);
                        return;
                      } else {
                        setCancel102(true);
                      }
                      return;
                    }
                  } else {
                    setData102(res.data[0]);
                    setEditButton102(true);
                    setIcon102(<EventBusy />);
                    setStatus102("ไม่ว่าง");
                    setApp102(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel102(false);
                      return;
                    } else {
                      setCancel102(true);
                    }
                    return;
                  }
                }
              } else {
                setData102(defaultdata[2]);
                setEditButton102(true);
                setIcon102(<EventBusy />);
                setStatus102("ไม่ว่าง");
                setApp102(true);
                setCancel102(true);
                return;
              }
            });
            break;
          case "103":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData103(defaultdata[3]);
                      setEditButton103(false);
                      setIcon103(<EventAvailable />);
                      setStatus103("ว่าง");
                      setCancel103(true);
                      setApp103(true);
                      return;
                    }
                    setData103(defaultdata[3]);
                    setEditButton103(true);
                    setIcon103(<EventBusy />);
                    setStatus103("สิทธิอื่น");
                    setCancel103(true);
                    setApp103(true);
                    return;
                  }
                  setData103(defaultdata[0]);
                  setEditButton103(false);
                  setIcon103(<EventAvailable />);
                  setStatus103("ว่าง");
                  setCancel103(true);
                  setApp103(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData103(res.data[0]);
                      setEditButton103(true);
                      setIcon103(<EventBusy />);
                      setStatus103("ไม่ว่าง");
                      setApp103(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel103(false);
                        return;
                      } else {
                        setCancel103(true);
                      }
                      return;
                    } else {
                      setData103(defaultdata[4]);
                      setEditButton103(true);
                      setIcon103(<EventBusy />);
                      setStatus103("ไม่ว่าง");
                      setApp103(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel103(false);
                        return;
                      } else {
                        setCancel103(true);
                      }
                      return;
                    }
                  } else {
                    setData103(res.data[0]);
                    setEditButton103(true);
                    setIcon103(<EventBusy />);
                    setStatus103("ไม่ว่าง");
                    setApp103(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel103(false);
                      return;
                    } else {
                      setCancel103(true);
                    }
                    return;
                  }
                }
              } else {
                setData103(defaultdata[2]);
                setEditButton103(true);
                setIcon103(<EventBusy />);
                setStatus103("ไม่ว่าง");
                setApp103(true);
                setCancel103(true);
                return;
              }
            });
            break;
          case "104":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData104(defaultdata[1]);
                    setEditButton104(true);
                    setIcon104(<EventBusy />);
                    setStatus104("ไม่ว่าง");
                    setCancel104(true);
                    setApp104(true);
                    return;
                  }
                  setData104(defaultdata[0]);
                  setEditButton104(false);
                  setIcon104(<EventAvailable />);
                  setStatus104("ว่าง");
                  setCancel104(true);
                  setApp104(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData104(res.data[0]);
                      setEditButton104(true);
                      setIcon104(<EventBusy />);
                      setStatus104("ไม่ว่าง");
                      setApp104(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel104(false);
                        return;
                      } else {
                        setCancel104(true);
                      }
                      return;
                    } else {
                      setData104(defaultdata[4]);
                      setEditButton104(true);
                      setIcon104(<EventBusy />);
                      setStatus104("ไม่ว่าง");
                      setApp104(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel104(false);
                        return;
                      } else {
                        setCancel104(true);
                      }
                      return;
                    }
                  } else {
                    setData104(res.data[0]);
                    setEditButton104(true);
                    setIcon104(<EventBusy />);
                    setStatus104("ไม่ว่าง");
                    setApp104(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel104(false);
                      return;
                    } else {
                      setCancel104(true);
                    }
                    return;
                  }
                }
              } else {
                setData104(defaultdata[2]);
                setEditButton104(true);
                setIcon104(<EventBusy />);
                setStatus104("ไม่ว่าง");
                setApp104(true);
                setCancel104(true);
                return;
              }
            });
            break;
          case "105":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData105(defaultdata[1]);
                    setEditButton105(true);
                    setIcon105(<EventBusy />);
                    setStatus105("ไม่ว่าง");
                    setCancel105(true);
                    setApp105(true);
                    return;
                  }
                  setData105(defaultdata[0]);
                  setEditButton105(false);
                  setIcon105(<EventAvailable />);
                  setStatus105("ว่าง");
                  setCancel105(true);
                  setApp105(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData105(res.data[0]);
                      setEditButton105(true);
                      setIcon105(<EventBusy />);
                      setStatus105("ไม่ว่าง");
                      setApp105(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel105(false);
                        return;
                      } else {
                        setCancel105(true);
                      }
                      return;
                    } else {
                      setData105(defaultdata[4]);
                      setEditButton105(true);
                      setIcon105(<EventBusy />);
                      setStatus105("ไม่ว่าง");
                      setApp105(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel105(false);
                        return;
                      } else {
                        setCancel105(true);
                      }
                      return;
                    }
                  } else {
                    setData105(res.data[0]);
                    setEditButton105(true);
                    setIcon105(<EventBusy />);
                    setStatus105("ไม่ว่าง");
                    setApp105(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel105(false);
                      return;
                    } else {
                      setCancel105(true);
                    }
                    return;
                  }
                }
              } else {
                setData105(defaultdata[2]);
                setEditButton105(true);
                setIcon105(<EventBusy />);
                setStatus105("ไม่ว่าง");
                setApp105(true);
                setCancel105(true);
                return;
              }
            });
            break;
          case "106":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData106(defaultdata[1]);
                    setEditButton106(true);
                    setIcon106(<EventBusy />);
                    setStatus106("ไม่ว่าง");
                    setCancel106(true);
                    setApp106(true);
                    return;
                  }
                  setData106(defaultdata[0]);
                  setEditButton106(false);
                  setIcon106(<EventAvailable />);
                  setStatus106("ว่าง");
                  setCancel106(true);
                  setApp106(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData106(res.data[0]);
                      setEditButton106(true);
                      setIcon106(<EventBusy />);
                      setStatus106("ไม่ว่าง");
                      setApp106(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel106(false);
                        return;
                      } else {
                        setCancel106(true);
                      }
                      return;
                    } else {
                      setData106(defaultdata[4]);
                      setEditButton106(true);
                      setIcon106(<EventBusy />);
                      setStatus106("ไม่ว่าง");
                      setApp106(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel106(false);
                        return;
                      } else {
                        setCancel106(true);
                      }
                      return;
                    }
                  } else {
                    setData106(res.data[0]);
                    setEditButton106(true);
                    setIcon106(<EventBusy />);
                    setStatus106("ไม่ว่าง");
                    setApp106(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel106(false);
                      return;
                    } else {
                      setCancel106(true);
                    }
                    return;
                  }
                }
              } else {
                setData106(defaultdata[2]);
                setEditButton106(true);
                setIcon106(<EventBusy />);
                setStatus106("ไม่ว่าง");
                setApp106(true);
                setCancel106(true);
                return;
              }
            });
            break;
          case "111":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData111(defaultdata[3]);
                      setEditButton111(false);
                      setIcon111(<EventAvailable />);
                      setStatus111("ว่าง");
                      setCancel111(true);
                      setApp111(true);
                      return;
                    }
                    setData111(defaultdata[3]);
                    setEditButton111(true);
                    setIcon111(<EventBusy />);
                    setStatus111("สิทธิอื่น");
                    setCancel111(true);
                    setApp111(true);
                    return;
                  }
                  setData111(defaultdata[0]);
                  setEditButton111(false);
                  setIcon111(<EventAvailable />);
                  setStatus111("ว่าง");
                  setCancel111(true);
                  setApp111(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData111(res.data[0]);
                      setEditButton111(true);
                      setIcon111(<EventBusy />);
                      setStatus111("ไม่ว่าง");
                      setApp111(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel111(false);
                        return;
                      } else {
                        setCancel111(true);
                      }
                      return;
                    } else {
                      setData111(defaultdata[4]);
                      setEditButton111(true);
                      setIcon111(<EventBusy />);
                      setStatus111("ไม่ว่าง");
                      setApp111(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel111(false);
                        return;
                      } else {
                        setCancel111(true);
                      }
                      return;
                    }
                  } else {
                    setData111(res.data[0]);
                    setEditButton111(true);
                    setIcon111(<EventBusy />);
                    setStatus111("ไม่ว่าง");
                    setApp111(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel111(false);
                      return;
                    } else {
                      setCancel111(true);
                    }
                    return;
                  }
                }
              } else {
                setData111(defaultdata[2]);
                setEditButton111(true);
                setIcon111(<EventBusy />);
                setStatus111("ไม่ว่าง");
                setApp111(true);
                setCancel111(true);
                return;
              }
            });
            break;
          case "112":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData112(defaultdata[3]);
                      setEditButton112(false);
                      setIcon112(<EventAvailable />);
                      setStatus112("ว่าง");
                      setCancel112(true);
                      setApp112(true);
                      return;
                    }
                    setData112(defaultdata[3]);
                    setEditButton112(true);
                    setIcon112(<EventBusy />);
                    setStatus112("สิทธิอื่น");
                    setCancel112(true);
                    setApp112(true);
                    return;
                  }
                  setData112(defaultdata[0]);
                  setEditButton112(false);
                  setIcon112(<EventAvailable />);
                  setStatus112("ว่าง");
                  setCancel112(true);
                  setApp112(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData112(res.data[0]);
                      setEditButton112(true);
                      setIcon112(<EventBusy />);
                      setStatus112("ไม่ว่าง");
                      setApp112(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel112(false);
                        return;
                      } else {
                        setCancel112(true);
                      }
                      return;
                    } else {
                      setData112(defaultdata[4]);
                      setEditButton112(true);
                      setIcon112(<EventBusy />);
                      setStatus112("ไม่ว่าง");
                      setApp112(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel112(false);
                        return;
                      } else {
                        setCancel112(true);
                      }
                      return;
                    }
                  } else {
                    setData112(res.data[0]);
                    setEditButton112(true);
                    setIcon112(<EventBusy />);
                    setStatus112("ไม่ว่าง");
                    setApp112(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel112(false);
                      return;
                    } else {
                      setCancel112(true);
                    }
                    return;
                  }
                }
              } else {
                setData112(defaultdata[2]);
                setEditButton112(true);
                setIcon112(<EventBusy />);
                setStatus112("ไม่ว่าง");
                setApp112(true);
                setCancel112(true);
                return;
              }
            });
            break;
          case "113":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData113(defaultdata[3]);
                      setEditButton113(false);
                      setIcon113(<EventAvailable />);
                      setStatus113("ว่าง");
                      setCancel113(true);
                      setApp113(true);
                      return;
                    }
                    setData113(defaultdata[3]);
                    setEditButton113(true);
                    setIcon113(<EventBusy />);
                    setStatus113("สิทธิอื่น");
                    setCancel113(true);
                    setApp113(true);
                    return;
                  }
                  setData113(defaultdata[0]);
                  setEditButton113(false);
                  setIcon113(<EventAvailable />);
                  setStatus113("ว่าง");
                  setCancel113(true);
                  setApp113(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData113(res.data[0]);
                      setEditButton113(true);
                      setIcon113(<EventBusy />);
                      setStatus113("ไม่ว่าง");
                      setApp113(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel113(false);
                        return;
                      } else {
                        setCancel113(true);
                      }
                      return;
                    } else {
                      setData113(defaultdata[4]);
                      setEditButton113(true);
                      setIcon113(<EventBusy />);
                      setStatus113("ไม่ว่าง");
                      setApp113(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel113(false);
                        return;
                      } else {
                        setCancel113(true);
                      }
                      return;
                    }
                  } else {
                    setData113(res.data[0]);
                    setEditButton113(true);
                    setIcon113(<EventBusy />);
                    setStatus113("ไม่ว่าง");
                    setApp113(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel113(false);
                      return;
                    } else {
                      setCancel113(true);
                    }
                    return;
                  }
                }
              } else {
                setData113(defaultdata[2]);
                setEditButton113(true);
                setIcon113(<EventBusy />);
                setStatus113("ไม่ว่าง");
                setApp113(true);
                setCancel113(true);
                return;
              }
            });
            break;
          case "114":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData114(defaultdata[1]);
                    setEditButton114(true);
                    setIcon114(<EventBusy />);
                    setStatus114("ไม่ว่าง");
                    setCancel114(true);
                    setApp114(true);
                    return;
                  }
                  setData114(defaultdata[0]);
                  setEditButton114(false);
                  setIcon114(<EventAvailable />);
                  setStatus114("ว่าง");
                  setCancel114(true);
                  setApp114(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData114(res.data[0]);
                      setEditButton114(true);
                      setIcon114(<EventBusy />);
                      setStatus114("ไม่ว่าง");
                      setApp114(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel114(false);
                        return;
                      } else {
                        setCancel114(true);
                      }
                      return;
                    } else {
                      setData114(defaultdata[4]);
                      setEditButton114(true);
                      setIcon114(<EventBusy />);
                      setStatus114("ไม่ว่าง");
                      setApp114(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel114(false);
                        return;
                      } else {
                        setCancel114(true);
                      }
                      return;
                    }
                  } else {
                    setData114(res.data[0]);
                    setEditButton114(true);
                    setIcon114(<EventBusy />);
                    setStatus114("ไม่ว่าง");
                    setApp114(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel114(false);
                      return;
                    } else {
                      setCancel114(true);
                    }
                    return;
                  }
                }
              } else {
                setData114(defaultdata[2]);
                setEditButton114(true);
                setIcon114(<EventBusy />);
                setStatus114("ไม่ว่าง");
                setApp114(true);
                setCancel114(true);
                return;
              }
            });
            break;
          case "115":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData115(defaultdata[1]);
                    setEditButton115(true);
                    setIcon115(<EventBusy />);
                    setStatus115("ไม่ว่าง");
                    setCancel115(true);
                    setApp115(true);
                    return;
                  }
                  setData115(defaultdata[0]);
                  setEditButton115(false);
                  setIcon115(<EventAvailable />);
                  setStatus115("ว่าง");
                  setCancel115(true);
                  setApp115(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData115(res.data[0]);
                      setEditButton115(true);
                      setIcon115(<EventBusy />);
                      setStatus115("ไม่ว่าง");
                      setApp115(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel115(false);
                        return;
                      } else {
                        setCancel115(true);
                      }
                      return;
                    } else {
                      setData115(defaultdata[4]);
                      setEditButton115(true);
                      setIcon115(<EventBusy />);
                      setStatus115("ไม่ว่าง");
                      setApp115(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel115(false);
                        return;
                      } else {
                        setCancel115(true);
                      }
                      return;
                    }
                  } else {
                    setData115(res.data[0]);
                    setEditButton115(true);
                    setIcon115(<EventBusy />);
                    setStatus115("ไม่ว่าง");
                    setApp115(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel115(false);
                      return;
                    } else {
                      setCancel115(true);
                    }
                    return;
                  }
                }
              } else {
                setData115(defaultdata[2]);
                setEditButton115(true);
                setIcon115(<EventBusy />);
                setStatus115("ไม่ว่าง");
                setApp115(true);
                setCancel115(true);
                return;
              }
            });
            break;
          case "116":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData116(defaultdata[1]);
                    setEditButton116(true);
                    setIcon116(<EventBusy />);
                    setStatus116("ไม่ว่าง");
                    setCancel116(true);
                    setApp116(true);
                    return;
                  }
                  setData116(defaultdata[0]);
                  setEditButton116(false);
                  setIcon116(<EventAvailable />);
                  setStatus116("ว่าง");
                  setCancel116(true);
                  setApp116(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData116(res.data[0]);
                      setEditButton116(true);
                      setIcon116(<EventBusy />);
                      setStatus116("ไม่ว่าง");
                      setApp116(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel116(false);
                        return;
                      } else {
                        setCancel116(true);
                      }
                      return;
                    } else {
                      setData116(defaultdata[4]);
                      setEditButton116(true);
                      setIcon116(<EventBusy />);
                      setStatus116("ไม่ว่าง");
                      setApp116(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel116(false);
                        return;
                      } else {
                        setCancel116(true);
                      }
                      return;
                    }
                  } else {
                    setData116(res.data[0]);
                    setEditButton116(true);
                    setIcon116(<EventBusy />);
                    setStatus116("ไม่ว่าง");
                    setApp116(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel116(false);
                      return;
                    } else {
                      setCancel116(true);
                    }
                    return;
                  }
                }
              } else {
                setData116(defaultdata[2]);
                setEditButton116(true);
                setIcon116(<EventBusy />);
                setStatus116("ไม่ว่าง");
                setApp116(true);
                setCancel116(true);
                return;
              }
            });
            break;
          case "131":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData131(defaultdata[3]);
                      setEditButton131(false);
                      setIcon131(<EventAvailable />);
                      setStatus131("ว่าง");
                      setCancel131(true);
                      setApp131(true);
                      return;
                    }
                    setData131(defaultdata[3]);
                    setEditButton131(true);
                    setIcon131(<EventBusy />);
                    setStatus131("สิทธิอื่น");
                    setCancel131(true);
                    setApp131(true);
                    return;
                  }
                  setData131(defaultdata[0]);
                  setEditButton131(false);
                  setIcon131(<EventAvailable />);
                  setStatus131("ว่าง");
                  setCancel131(true);
                  setApp131(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData131(res.data[0]);
                      setEditButton131(true);
                      setIcon131(<EventBusy />);
                      setStatus131("ไม่ว่าง");
                      setApp131(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel131(false);
                        return;
                      } else {
                        setCancel131(true);
                      }
                      return;
                    } else {
                      setData131(defaultdata[4]);
                      setEditButton131(true);
                      setIcon131(<EventBusy />);
                      setStatus131("ไม่ว่าง");
                      setApp131(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel131(false);
                        return;
                      } else {
                        setCancel131(true);
                      }
                      return;
                    }
                  } else {
                    setData131(res.data[0]);
                    setEditButton131(true);
                    setIcon131(<EventBusy />);
                    setStatus131("ไม่ว่าง");
                    setApp131(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel131(false);
                      return;
                    } else {
                      setCancel131(true);
                    }
                    return;
                  }
                }
              } else {
                setData131(defaultdata[2]);
                setEditButton131(true);
                setIcon131(<EventBusy />);
                setStatus131("ไม่ว่าง");
                setApp131(true);
                setCancel131(true);
                return;
              }
            });
            break;
          case "132":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData132(defaultdata[3]);
                      setEditButton132(false);
                      setIcon132(<EventAvailable />);
                      setStatus132("ว่าง");
                      setCancel132(true);
                      setApp132(true);
                      return;
                    }
                    setData132(defaultdata[3]);
                    setEditButton132(true);
                    setIcon132(<EventBusy />);
                    setStatus132("สิทธิอื่น");
                    setCancel132(true);
                    setApp132(true);
                    return;
                  }
                  setData132(defaultdata[0]);
                  setEditButton132(false);
                  setIcon132(<EventAvailable />);
                  setStatus132("ว่าง");
                  setCancel132(true);
                  setApp132(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData132(res.data[0]);
                      setEditButton132(true);
                      setIcon132(<EventBusy />);
                      setStatus132("ไม่ว่าง");
                      setApp132(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel132(false);
                        return;
                      } else {
                        setCancel132(true);
                      }
                      return;
                    } else {
                      setData132(defaultdata[4]);
                      setEditButton132(true);
                      setIcon132(<EventBusy />);
                      setStatus132("ไม่ว่าง");
                      setApp132(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel132(false);
                        return;
                      } else {
                        setCancel132(true);
                      }
                      return;
                    }
                  } else {
                    setData132(res.data[0]);
                    setEditButton132(true);
                    setIcon132(<EventBusy />);
                    setStatus132("ไม่ว่าง");
                    setApp132(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel132(false);
                      return;
                    } else {
                      setCancel132(true);
                    }
                    return;
                  }
                }
              } else {
                setData132(defaultdata[2]);
                setEditButton132(true);
                setIcon132(<EventBusy />);
                setStatus132("ไม่ว่าง");
                setApp132(true);
                setCancel132(true);
                return;
              }
            });
            break;
          case "133":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    if (roletoken === "admin" || roletoken === "A2") {
                      setData133(defaultdata[3]);
                      setEditButton133(false);
                      setIcon133(<EventAvailable />);
                      setStatus133("ว่าง");
                      setCancel133(true);
                      setApp133(true);
                      return;
                    }
                    setData133(defaultdata[3]);
                    setEditButton133(true);
                    setIcon133(<EventBusy />);
                    setStatus133("สิทธิอื่น");
                    setCancel133(true);
                    setApp133(true);
                    return;
                  }
                  setData133(defaultdata[0]);
                  setEditButton133(false);
                  setIcon133(<EventAvailable />);
                  setStatus133("ว่าง");
                  setCancel133(true);
                  setApp133(true);
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData133(res.data[0]);
                      setEditButton133(true);
                      setIcon133(<EventBusy />);
                      setStatus133("ไม่ว่าง");
                      setApp133(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel133(false);
                        return;
                      } else {
                        setCancel133(true);
                      }
                      return;
                    } else {
                      setData133(defaultdata[4]);
                      setEditButton133(true);
                      setIcon133(<EventBusy />);
                      setStatus133("ไม่ว่าง");
                      setApp133(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel133(false);
                        return;
                      } else {
                        setCancel133(true);
                      }
                      return;
                    }
                  } else {
                    setData133(res.data[0]);
                    setEditButton133(true);
                    setIcon133(<EventBusy />);
                    setStatus133("ไม่ว่าง");
                    setApp133(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel133(false);
                      return;
                    } else {
                      setCancel133(true);
                    }
                    return;
                  }
                }
              } else {
                setData133(defaultdata[2]);
                setEditButton133(true);
                setIcon133(<EventBusy />);
                setStatus133("ไม่ว่าง");
                setApp133(true);
                setCancel133(true);
                return;
              }
            });
            break;
          case "134":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData134(defaultdata[1]);
                    setEditButton134(true);
                    setIcon134(<EventBusy />);
                    setStatus134("ไม่ว่าง");
                    setCancel134(true);
                    setApp134(true);
                    return;
                  }
                  setData134(defaultdata[0]);
                  setEditButton134(false);
                  setIcon134(<EventAvailable />);
                  setStatus134("ว่าง");
                  setCancel134(true);
                  setApp134(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData134(res.data[0]);
                      setEditButton134(true);
                      setIcon134(<EventBusy />);
                      setStatus134("ไม่ว่าง");
                      setApp134(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel134(false);
                        return;
                      } else {
                        setCancel134(true);
                      }
                      return;
                    } else {
                      setData134(defaultdata[4]);
                      setEditButton134(true);
                      setIcon134(<EventBusy />);
                      setStatus134("ไม่ว่าง");
                      setApp134(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel134(false);
                        return;
                      } else {
                        setCancel134(true);
                      }
                      return;
                    }
                  } else {
                    setData134(res.data[0]);
                    setEditButton134(true);
                    setIcon134(<EventBusy />);
                    setStatus134("ไม่ว่าง");
                    setApp134(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel134(false);
                      return;
                    } else {
                      setCancel134(true);
                    }
                    return;
                  }
                }
              } else {
                setData134(defaultdata[2]);
                setEditButton134(true);
                setIcon134(<EventBusy />);
                setStatus134("ไม่ว่าง");
                setApp134(true);
                setCancel134(true);
                return;
              }
            });
            break;
          case "135":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData135(defaultdata[1]);
                    setEditButton135(true);
                    setIcon135(<EventBusy />);
                    setStatus135("ไม่ว่าง");
                    setCancel135(true);
                    setApp135(true);
                    return;
                  }
                  setData135(defaultdata[0]);
                  setEditButton135(false);
                  setIcon135(<EventAvailable />);
                  setStatus135("ว่าง");
                  setCancel135(true);
                  setApp135(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData135(res.data[0]);
                      setEditButton135(true);
                      setIcon135(<EventBusy />);
                      setStatus135("ไม่ว่าง");
                      setApp135(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel135(false);
                        return;
                      } else {
                        setCancel135(true);
                      }
                      return;
                    } else {
                      setData135(defaultdata[4]);
                      setEditButton135(true);
                      setIcon135(<EventBusy />);
                      setStatus135("ไม่ว่าง");
                      setApp135(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel135(false);
                        return;
                      } else {
                        setCancel135(true);
                      }
                      return;
                    }
                  } else {
                    setData135(res.data[0]);
                    setEditButton135(true);
                    setIcon135(<EventBusy />);
                    setStatus135("ไม่ว่าง");
                    setApp135(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel135(false);
                      return;
                    } else {
                      setCancel135(true);
                    }
                    return;
                  }
                }
              } else {
                setData135(defaultdata[2]);
                setEditButton135(true);
                setIcon135(<EventBusy />);
                setStatus135("ไม่ว่าง");
                setApp135(true);
                setCancel135(true);
                return;
              }
            });
            break;
          case "136":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData136(defaultdata[1]);
                    setEditButton136(true);
                    setIcon136(<EventBusy />);
                    setStatus136("ไม่ว่าง");
                    setCancel136(true);
                    setApp136(true);
                    return;
                  }
                  setData136(defaultdata[0]);
                  setEditButton136(false);
                  setIcon136(<EventAvailable />);
                  setStatus136("ว่าง");
                  setCancel136(true);
                  setApp136(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData136(res.data[0]);
                      setEditButton136(true);
                      setIcon136(<EventBusy />);
                      setStatus136("ไม่ว่าง");
                      setApp136(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel136(false);
                        return;
                      } else {
                        setCancel136(true);
                      }
                      return;
                    } else {
                      setData136(defaultdata[4]);
                      setEditButton136(true);
                      setIcon136(<EventBusy />);
                      setStatus136("ไม่ว่าง");
                      setApp136(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel136(false);
                        return;
                      } else {
                        setCancel136(true);
                      }
                      return;
                    }
                  } else {
                    setData136(res.data[0]);
                    setEditButton136(true);
                    setIcon136(<EventBusy />);
                    setStatus136("ไม่ว่าง");
                    setApp136(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel136(false);
                      return;
                    } else {
                      setCancel136(true);
                    }
                    return;
                  }
                }
              } else {
                setData136(defaultdata[2]);
                setEditButton136(true);
                setIcon136(<EventBusy />);
                setStatus136("ไม่ว่าง");
                setApp136(true);
                setCancel136(true);
                return;
              }
            });
            break;
          case "141":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData141(defaultdata[1]);
                    setEditButton141(true);
                    setIcon141(<EventBusy />);
                    setStatus141("ไม่ว่าง");
                    setCancel141(true);
                    setApp141(true);
                    return;
                  }
                  setData141(defaultdata[0]);
                  setEditButton141(false);
                  setIcon141(<EventAvailable />);
                  setStatus141("ว่าง");
                  setCancel141(true);
                  setApp141(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData141(res.data[0]);
                      setEditButton141(true);
                      setIcon141(<EventBusy />);
                      setStatus141("ไม่ว่าง");
                      setApp141(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel141(false);
                        return;
                      } else {
                        setCancel141(true);
                      }
                      return;
                    } else {
                      setData141(defaultdata[4]);
                      setEditButton141(true);
                      setIcon141(<EventBusy />);
                      setStatus141("ไม่ว่าง");
                      setApp141(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel141(false);
                        return;
                      } else {
                        setCancel141(true);
                      }
                      return;
                    }
                  } else {
                    setData141(res.data[0]);
                    setEditButton141(true);
                    setIcon141(<EventBusy />);
                    setStatus141("ไม่ว่าง");
                    setApp141(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel141(false);
                      return;
                    } else {
                      setCancel141(true);
                    }
                    return;
                  }
                }
              } else {
                setData141(defaultdata[2]);
                setEditButton141(true);
                setIcon141(<EventBusy />);
                setStatus141("ไม่ว่าง");
                setApp141(true);
                setCancel141(true);
                return;
              }
            });
            break;
          case "142":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData142(defaultdata[1]);
                    setEditButton142(true);
                    setIcon142(<EventBusy />);
                    setStatus142("ไม่ว่าง");
                    setCancel142(true);
                    setApp142(true);
                    return;
                  }
                  setData142(defaultdata[0]);
                  setEditButton142(false);
                  setIcon142(<EventAvailable />);
                  setStatus142("ว่าง");
                  setCancel142(true);
                  setApp142(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData142(res.data[0]);
                      setEditButton142(true);
                      setIcon142(<EventBusy />);
                      setStatus142("ไม่ว่าง");
                      setApp142(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel142(false);
                        return;
                      } else {
                        setCancel142(true);
                      }
                      return;
                    } else {
                      setData142(defaultdata[4]);
                      setEditButton142(true);
                      setIcon142(<EventBusy />);
                      setStatus142("ไม่ว่าง");
                      setApp142(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel142(false);
                        return;
                      } else {
                        setCancel142(true);
                      }
                      return;
                    }
                  } else {
                    setData142(res.data[0]);
                    setEditButton142(true);
                    setIcon142(<EventBusy />);
                    setStatus142("ไม่ว่าง");
                    setApp142(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel142(false);
                      return;
                    } else {
                      setCancel142(true);
                    }
                    return;
                  }
                }
              } else {
                setData142(defaultdata[2]);
                setEditButton142(true);
                setIcon142(<EventBusy />);
                setStatus142("ไม่ว่าง");
                setApp142(true);
                setCancel142(true);
                return;
              }
            });
            break;
          case "143":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData143(defaultdata[1]);
                    setEditButton143(true);
                    setIcon143(<EventBusy />);
                    setStatus143("ไม่ว่าง");
                    setCancel143(true);
                    setApp143(true);
                    return;
                  }
                  setData143(defaultdata[0]);
                  setEditButton143(false);
                  setIcon143(<EventAvailable />);
                  setStatus143("ว่าง");
                  setCancel143(true);
                  setApp143(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData143(res.data[0]);
                      setEditButton143(true);
                      setIcon143(<EventBusy />);
                      setStatus143("ไม่ว่าง");
                      setApp143(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel143(false);
                        return;
                      } else {
                        setCancel143(true);
                      }
                      return;
                    } else {
                      setData143(defaultdata[4]);
                      setEditButton143(true);
                      setIcon143(<EventBusy />);
                      setStatus143("ไม่ว่าง");
                      setApp143(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel143(false);
                        return;
                      } else {
                        setCancel143(true);
                      }
                      return;
                    }
                  } else {
                    setData143(res.data[0]);
                    setEditButton143(true);
                    setIcon143(<EventBusy />);
                    setStatus143("ไม่ว่าง");
                    setApp143(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel143(false);
                      return;
                    } else {
                      setCancel143(true);
                    }
                    return;
                  }
                }
              } else {
                setData143(defaultdata[2]);
                setEditButton143(true);
                setIcon143(<EventBusy />);
                setStatus143("ไม่ว่าง");
                setApp143(true);
                setCancel143(true);
                return;
              }
            });
            break;
          case "144":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData144(defaultdata[1]);
                    setEditButton144(true);
                    setIcon144(<EventBusy />);
                    setStatus144("ไม่ว่าง");
                    setCancel144(true);
                    setApp144(true);
                    return;
                  }
                  setData144(defaultdata[0]);
                  setEditButton144(false);
                  setIcon144(<EventAvailable />);
                  setStatus144("ว่าง");
                  setCancel144(true);
                  setApp144(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData144(res.data[0]);
                      setEditButton144(true);
                      setIcon144(<EventBusy />);
                      setStatus144("ไม่ว่าง");
                      setApp144(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel144(false);
                        return;
                      } else {
                        setCancel144(true);
                      }
                      return;
                    } else {
                      setData144(defaultdata[4]);
                      setEditButton144(true);
                      setIcon144(<EventBusy />);
                      setStatus144("ไม่ว่าง");
                      setApp144(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel144(false);
                        return;
                      } else {
                        setCancel144(true);
                      }
                      return;
                    }
                  } else {
                    setData144(res.data[0]);
                    setEditButton144(true);
                    setIcon144(<EventBusy />);
                    setStatus144("ไม่ว่าง");
                    setApp144(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel144(false);
                      return;
                    } else {
                      setCancel144(true);
                    }
                    return;
                  }
                }
              } else {
                setData144(defaultdata[2]);
                setEditButton144(true);
                setIcon144(<EventBusy />);
                setStatus144("ไม่ว่าง");
                setApp144(true);
                setCancel144(true);
                return;
              }
            });
            break;
          case "145":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData145(defaultdata[1]);
                    setEditButton145(true);
                    setIcon145(<EventBusy />);
                    setStatus145("ไม่ว่าง");
                    setCancel145(true);
                    setApp145(true);
                    return;
                  }
                  setData145(defaultdata[0]);
                  setEditButton145(false);
                  setIcon145(<EventAvailable />);
                  setStatus145("ว่าง");
                  setCancel145(true);
                  setApp145(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData145(res.data[0]);
                      setEditButton145(true);
                      setIcon145(<EventBusy />);
                      setStatus145("ไม่ว่าง");
                      setApp145(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel145(false);
                        return;
                      } else {
                        setCancel145(true);
                      }
                      return;
                    } else {
                      setData145(defaultdata[4]);
                      setEditButton145(true);
                      setIcon145(<EventBusy />);
                      setStatus145("ไม่ว่าง");
                      setApp145(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel145(false);
                        return;
                      } else {
                        setCancel145(true);
                      }
                      return;
                    }
                  } else {
                    setData145(res.data[0]);
                    setEditButton145(true);
                    setIcon145(<EventBusy />);
                    setStatus145("ไม่ว่าง");
                    setApp145(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel145(false);
                      return;
                    } else {
                      setCancel145(true);
                    }
                    return;
                  }
                }
              } else {
                setData145(defaultdata[2]);
                setEditButton145(true);
                setIcon145(<EventBusy />);
                setStatus145("ไม่ว่าง");
                setApp145(true);
                setCancel145(true);
                return;
              }
            });
            break;
          case "146":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData146(defaultdata[1]);
                    setEditButton146(true);
                    setIcon146(<EventBusy />);
                    setStatus146("ไม่ว่าง");
                    setCancel146(true);
                    setApp146(true);
                    return;
                  }
                  setData146(defaultdata[0]);
                  setEditButton146(false);
                  setIcon146(<EventAvailable />);
                  setStatus146("ว่าง");
                  setCancel146(true);
                  setApp146(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData146(res.data[0]);
                      setEditButton146(true);
                      setIcon146(<EventBusy />);
                      setStatus146("ไม่ว่าง");
                      setApp146(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel146(false);
                        return;
                      } else {
                        setCancel146(true);
                      }
                      return;
                    } else {
                      setData146(defaultdata[4]);
                      setEditButton146(true);
                      setIcon146(<EventBusy />);
                      setStatus146("ไม่ว่าง");
                      setApp146(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel146(false);
                        return;
                      } else {
                        setCancel146(true);
                      }
                      return;
                    }
                  } else {
                    setData146(res.data[0]);
                    setEditButton146(true);
                    setIcon146(<EventBusy />);
                    setStatus146("ไม่ว่าง");
                    setApp146(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel146(false);
                      return;
                    } else {
                      setCancel146(true);
                    }
                    return;
                  }
                }
              } else {
                setData146(defaultdata[2]);
                setEditButton146(true);
                setIcon146(<EventBusy />);
                setStatus146("ไม่ว่าง");
                setApp146(true);
                setCancel146(true);
                return;
              }
            });
            break;
          case "161":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData161(defaultdata[1]);
                    setEditButton161(true);
                    setIcon161(<EventBusy />);
                    setStatus161("ไม่ว่าง");
                    setCancel161(true);
                    setApp161(true);
                    return;
                  }
                  setData161(defaultdata[0]);
                  setEditButton161(false);
                  setIcon161(<EventAvailable />);
                  setStatus161("ว่าง");
                  setCancel161(true);
                  setApp161(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData161(res.data[0]);
                      setEditButton161(true);
                      setIcon161(<EventBusy />);
                      setStatus161("ไม่ว่าง");
                      setApp161(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel161(false);
                        return;
                      } else {
                        setCancel161(true);
                      }
                      return;
                    } else {
                      setData161(defaultdata[4]);
                      setEditButton161(true);
                      setIcon161(<EventBusy />);
                      setStatus161("ไม่ว่าง");
                      setApp161(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel161(false);
                        return;
                      } else {
                        setCancel161(true);
                      }
                      return;
                    }
                  } else {
                    setData161(res.data[0]);
                    setEditButton161(true);
                    setIcon161(<EventBusy />);
                    setStatus161("ไม่ว่าง");
                    setApp161(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel161(false);
                      return;
                    } else {
                      setCancel161(true);
                    }
                    return;
                  }
                }
              } else {
                setData161(defaultdata[2]);
                setEditButton161(true);
                setIcon161(<EventBusy />);
                setStatus161("ไม่ว่าง");
                setApp161(true);
                setCancel161(true);
                return;
              }
            });
            break;
          case "162":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData162(defaultdata[1]);
                    setEditButton162(true);
                    setIcon162(<EventBusy />);
                    setStatus162("ไม่ว่าง");
                    setCancel162(true);
                    setApp162(true);
                    return;
                  }
                  setData162(defaultdata[0]);
                  setEditButton162(false);
                  setIcon162(<EventAvailable />);
                  setStatus162("ว่าง");
                  setCancel162(true);
                  setApp162(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData162(res.data[0]);
                      setEditButton162(true);
                      setIcon162(<EventBusy />);
                      setStatus162("ไม่ว่าง");
                      setApp162(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel162(false);
                        return;
                      } else {
                        setCancel162(true);
                      }
                      return;
                    } else {
                      setData162(defaultdata[4]);
                      setEditButton162(true);
                      setIcon162(<EventBusy />);
                      setStatus162("ไม่ว่าง");
                      setApp162(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel162(false);
                        return;
                      } else {
                        setCancel162(true);
                      }
                      return;
                    }
                  } else {
                    setData162(res.data[0]);
                    setEditButton162(true);
                    setIcon162(<EventBusy />);
                    setStatus162("ไม่ว่าง");
                    setApp162(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel162(false);
                      return;
                    } else {
                      setCancel162(true);
                    }
                    return;
                  }
                }
              } else {
                setData162(defaultdata[2]);
                setEditButton162(true);
                setIcon162(<EventBusy />);
                setStatus162("ไม่ว่าง");
                setApp162(true);
                setCancel162(true);
                return;
              }
            });
            break;
          case "163":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData163(defaultdata[1]);
                    setEditButton163(true);
                    setIcon163(<EventBusy />);
                    setStatus163("ไม่ว่าง");
                    setCancel163(true);
                    setApp163(true);
                    return;
                  }
                  setData163(defaultdata[0]);
                  setEditButton163(false);
                  setIcon163(<EventAvailable />);
                  setStatus163("ว่าง");
                  setCancel163(true);
                  setApp163(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData163(res.data[0]);
                      setEditButton163(true);
                      setIcon163(<EventBusy />);
                      setStatus163("ไม่ว่าง");
                      setApp163(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel163(false);
                        return;
                      } else {
                        setCancel163(true);
                      }
                      return;
                    } else {
                      setData163(defaultdata[4]);
                      setEditButton163(true);
                      setIcon163(<EventBusy />);
                      setStatus163("ไม่ว่าง");
                      setApp163(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel163(false);
                        return;
                      } else {
                        setCancel163(true);
                      }
                      return;
                    }
                  } else {
                    setData163(res.data[0]);
                    setEditButton163(true);
                    setIcon163(<EventBusy />);
                    setStatus163("ไม่ว่าง");
                    setApp163(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel163(false);
                      return;
                    } else {
                      setCancel163(true);
                    }
                    return;
                  }
                }
              } else {
                setData163(defaultdata[2]);
                setEditButton163(true);
                setIcon163(<EventBusy />);
                setStatus163("ไม่ว่าง");
                setApp163(true);
                setCancel163(true);
                return;
              }
            });
            break;
          case "171":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData171(defaultdata[1]);
                    setEditButton171(true);
                    setIcon171(<EventBusy />);
                    setStatus171("ไม่ว่าง");
                    setCancel171(true);
                    setApp171(true);
                    return;
                  }
                  setData171(defaultdata[0]);
                  setEditButton171(false);
                  setIcon171(<EventAvailable />);
                  setStatus171("ว่าง");
                  setCancel171(true);
                  setApp171(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData171(res.data[0]);
                      setEditButton171(true);
                      setIcon171(<EventBusy />);
                      setStatus171("ไม่ว่าง");
                      setApp171(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel171(false);
                        return;
                      } else {
                        setCancel171(true);
                      }
                      return;
                    } else {
                      setData171(defaultdata[4]);
                      setEditButton171(true);
                      setIcon171(<EventBusy />);
                      setStatus171("ไม่ว่าง");
                      setApp171(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel171(false);
                        return;
                      } else {
                        setCancel171(true);
                      }
                      return;
                    }
                  } else {
                    setData171(res.data[0]);
                    setEditButton171(true);
                    setIcon171(<EventBusy />);
                    setStatus171("ไม่ว่าง");
                    setApp171(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel171(false);
                      return;
                    } else {
                      setCancel171(true);
                    }
                    return;
                  }
                }
              } else {
                setData171(defaultdata[2]);
                setEditButton171(true);
                setIcon171(<EventBusy />);
                setStatus171("ไม่ว่าง");
                setApp171(true);
                setCancel171(true);
                return;
              }
            });
            break;
          case "172":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData172(defaultdata[1]);
                    setEditButton172(true);
                    setIcon172(<EventBusy />);
                    setStatus172("ไม่ว่าง");
                    setCancel172(true);
                    setApp172(true);
                    return;
                  }
                  setData172(defaultdata[0]);
                  setEditButton172(false);
                  setIcon172(<EventAvailable />);
                  setStatus172("ว่าง");
                  setCancel172(true);
                  setApp172(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData172(res.data[0]);
                      setEditButton172(true);
                      setIcon172(<EventBusy />);
                      setStatus172("ไม่ว่าง");
                      setApp172(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel172(false);
                        return;
                      } else {
                        setCancel172(true);
                      }
                      return;
                    } else {
                      setData172(defaultdata[4]);
                      setEditButton172(true);
                      setIcon172(<EventBusy />);
                      setStatus172("ไม่ว่าง");
                      setApp172(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel172(false);
                        return;
                      } else {
                        setCancel172(true);
                      }
                      return;
                    }
                  } else {
                    setData172(res.data[0]);
                    setEditButton172(true);
                    setIcon172(<EventBusy />);
                    setStatus172("ไม่ว่าง");
                    setApp172(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel172(false);
                      return;
                    } else {
                      setCancel172(true);
                    }
                    return;
                  }
                }
              } else {
                setData172(defaultdata[2]);
                setEditButton172(true);
                setIcon172(<EventBusy />);
                setStatus172("ไม่ว่าง");
                setApp172(true);
                setCancel172(true);
                return;
              }
            });
            break;
          case "173":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 2 || startDate.getDay() === 5 || startDate.getDay() === 6) {
                    setData173(defaultdata[1]);
                    setEditButton173(true);
                    setIcon173(<EventBusy />);
                    setStatus173("ไม่ว่าง");
                    setCancel173(true);
                    setApp173(true);
                    return;
                  }
                  setData173(defaultdata[0]);
                  setEditButton173(false);
                  setIcon173(<EventAvailable />);
                  setStatus173("ว่าง");
                  setCancel173(true);
                  setApp173(true);
                  return;
                } else if (res.data.length >= 1) {
                  if (res.data[0].type == 1) {
                    if (res.data[0].TN == tntoken) {
                      setData173(res.data[0]);
                      setEditButton173(true);
                      setIcon173(<EventBusy />);
                      setStatus173("ไม่ว่าง");
                      setApp173(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel173(false);
                        return;
                      } else {
                        setCancel173(true);
                      }
                      return;
                    } else {
                      setData173(defaultdata[4]);
                      setEditButton173(true);
                      setIcon173(<EventBusy />);
                      setStatus173("ไม่ว่าง");
                      setApp173(false);
                      if (TN[0] === Number(tntoken)) {
                        setCancel173(false);
                        return;
                      } else {
                        setCancel173(true);
                      }
                      return;
                    }
                  } else {
                    setData173(res.data[0]);
                    setEditButton173(true);
                    setIcon173(<EventBusy />);
                    setStatus173("ไม่ว่าง");
                    setApp173(false);
                    if (TN[0] === Number(tntoken)) {
                      setCancel173(false);
                      return;
                    } else {
                      setCancel173(true);
                    }
                    return;
                  }
                }
              } else {
                setData173(defaultdata[2]);
                setEditButton173(true);
                setIcon173(<EventBusy />);
                setStatus173("ไม่ว่าง");
                setApp173(true);
                setCancel173(true);
                return;
              }
            });
            break;
          default:
            console.log("fail");
        }
      };

      setdata(
        res91,
        "91",
        "08:50",
        res91.data.map((item) => item.TN)
      );
      setdata(
        res92,
        "92",
        "08:50",
        res92.data.map((item) => item.TN)
      );
      setdata(
        res93,
        "93",
        "08:50",
        res93.data.map((item) => item.TN)
      );
      setdata(
        res94,
        "94",
        "08:50",
        res94.data.map((item) => item.TN)
      );
      setdata(
        res95,
        "95",
        "08:50",
        res95.data.map((item) => item.TN)
      );
      setdata(
        res96,
        "96",
        "08:50",
        res96.data.map((item) => item.TN)
      );
      setdata(
        res101,
        "101",
        "09:55",
        res101.data.map((item) => item.TN)
      );
      setdata(
        res102,
        "102",
        "09:55",
        res102.data.map((item) => item.TN)
      );
      setdata(
        res103,
        "103",
        "09:55",
        res103.data.map((item) => item.TN)
      );
      setdata(
        res104,
        "104",
        "09:55",
        res104.data.map((item) => item.TN)
      );
      setdata(
        res105,
        "105",
        "09:55",
        res105.data.map((item) => item.TN)
      );
      setdata(
        res106,
        "106",
        "09:55",
        res106.data.map((item) => item.TN)
      );
      setdata(
        res111,
        "111",
        "11:00",
        res111.data.map((item) => item.TN)
      );
      setdata(
        res112,
        "112",
        "11:00",
        res112.data.map((item) => item.TN)
      );
      setdata(
        res113,
        "113",
        "11:00",
        res113.data.map((item) => item.TN)
      );
      setdata(
        res114,
        "114",
        "11:00",
        res114.data.map((item) => item.TN)
      );
      setdata(
        res115,
        "115",
        "11:00",
        res115.data.map((item) => item.TN)
      );
      setdata(
        res116,
        "116",
        "11:00",
        res116.data.map((item) => item.TN)
      );
      setdata(
        res131,
        "131",
        "13:30",
        res131.data.map((item) => item.TN)
      );
      setdata(
        res132,
        "132",
        "13:30",
        res132.data.map((item) => item.TN)
      );
      setdata(
        res133,
        "133",
        "13:30",
        res133.data.map((item) => item.TN)
      );
      setdata(
        res134,
        "134",
        "13:30",
        res134.data.map((item) => item.TN)
      );
      setdata(
        res135,
        "135",
        "13:30",
        res135.data.map((item) => item.TN)
      );
      setdata(
        res136,
        "136",
        "13:30",
        res136.data.map((item) => item.TN)
      );
      setdata(
        res141,
        "141",
        "14:45",
        res141.data.map((item) => item.TN)
      );
      setdata(
        res142,
        "142",
        "14:45",
        res142.data.map((item) => item.TN)
      );
      setdata(
        res143,
        "143",
        "14:45",
        res143.data.map((item) => item.TN)
      );
      setdata(
        res144,
        "144",
        "14:45",
        res144.data.map((item) => item.TN)
      );
      setdata(
        res145,
        "145",
        "14:45",
        res145.data.map((item) => item.TN)
      );
      setdata(
        res146,
        "146",
        "14:45",
        res146.data.map((item) => item.TN)
      );
      setdata(
        res161,
        "161",
        "16:15",
        res161.data.map((item) => item.TN)
      );
      setdata(
        res162,
        "162",
        "16:15",
        res162.data.map((item) => item.TN)
      );
      setdata(
        res163,
        "163",
        "16:15",
        res163.data.map((item) => item.TN)
      );
      setdata(
        res171,
        "171",
        "17:30",
        res171.data.map((item) => item.TN)
      );
      setdata(
        res172,
        "172",
        "17:30",
        res172.data.map((item) => item.TN)
      );
      setdata(
        res173,
        "173",
        "17:30",
        res173.data.map((item) => item.TN)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, [
    startDate,
    reData91,
    reData92,
    reData93,
    reData94,
    reData95,
    reData96,
    reData101,
    reData102,
    reData103,
    reData104,
    reData105,
    reData106,
    reData111,
    reData112,
    reData113,
    reData114,
    reData115,
    reData116,
    reData131,
    reData132,
    reData133,
    reData134,
    reData135,
    reData136,
    reData141,
    reData142,
    reData143,
    reData144,
    reData145,
    reData146,
    reData161,
    reData162,
    reData163,
    reData171,
    reData172,
    reData173,
    URL,
  ]);
  //api

  //handle
  const handleSubmit = (appurl, appid, timestring, timeid) => {
    const PostURL = `${URL}/${appurl}/${timeid}/${selectdate}`;
    axios.get(PostURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen(false);
            recheck(appid);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addappoinment(appurl, appid, timestring, timeid);
        }
      });
    });
  };
  const handleCancel = (TN, AN, appurl, appid, appcancel, timeid) => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelalert(AN, appurl, appid, appcancel, timeid);
      }
    });
  };
  const handlecheck = () => {
    if (
      data91.TN === Number(tntoken) ||
      data92.TN === Number(tntoken) ||
      data93.TN === Number(tntoken) ||
      data94.TN === Number(tntoken) ||
      data95.TN === Number(tntoken) ||
      data96.TN === Number(tntoken) ||
      data101.TN === Number(tntoken) ||
      data102.TN === Number(tntoken) ||
      data103.TN === Number(tntoken) ||
      data104.TN === Number(tntoken) ||
      data105.TN === Number(tntoken) ||
      data106.TN === Number(tntoken) ||
      data131.TN === Number(tntoken) ||
      data132.TN === Number(tntoken) ||
      data133.TN === Number(tntoken) ||
      data134.TN === Number(tntoken) ||
      data135.TN === Number(tntoken) ||
      data136.TN === Number(tntoken) ||
      data141.TN === Number(tntoken) ||
      data142.TN === Number(tntoken) ||
      data143.TN === Number(tntoken) ||
      data144.TN === Number(tntoken) ||
      data145.TN === Number(tntoken) ||
      data146.TN === Number(tntoken)
    ) {
      Swal.fire({
        title: "ท่านมีรายการนัดหมายวันนี้แล้ว",
        html: "<div>ระบบให้นัดหมายได้เพียง 1 ช่วงเวลาต่อวันเท่านั้น</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        confirmButtonColor: "#d33",
        confirmButtonText: "ปิด",
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
    } else {
      if (datablock >= 3) {
        Swal.fire({
          title: "ท่านผิดนัดหมายเกินกำหนด",
          html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "ปิด",
          confirmButtonText: "ตรวจสอบนัด",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/search");
          }
        });
        return;
      } else if (dataL >= 3) {
        Swal.fire({
          title: "ท่านนัดหมายเกินกำหนด",
          html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "ปิด",
          confirmButtonText: "ตรวจสอบนัด",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/search");
          }
        });
        return;
      } else {
        openmadal();
      }
    }
  };
  //handle

  //modal
  const [selecttn, setSelecttn] = useState("");
  const [service, setService] = useState("");
  const [selectname, setSelectname] = useState("");
  const [selectdate, setSelectdate] = useState(`${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`);
  const selectpatient = (TN, prefix, fname, lname) => {
    setSelecttn(TN);
    setSelectname(`${prefix}${fname} ${lname}`);
  };
  const [open, setOpen] = useState(false);
  const openmadal = () => {
    setOpen(true);
    return;
  };
  const closemadal = () => {
    setSelecttn("");
    setSelectname("");
    setOpen(false);
    return;
  };
  //modal

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "10px 10px 10px 10px" }}>
          <DatePicker
            onChange={(Date) => {
              setStartDate(Date);
              setSelectdate(`${Date.getDate()}-${Date.getMonth() + 1}-${Date.getFullYear()}`);
              return;
            }}
            value={startDate}
            format="dd-MM-y"
            locale="th-TH"
            minDate={new Date()}
            calendarClassName="calendarclass"
            className="picker"
          />
          <span style={{ marginLeft: "10px", fontSize: "small" }}>{dayname}</span>
        </h2>
      </div>
      <div className="testtest">
        <table className="ondate">
          <tbody>
            {/* หัวข้อ */}
            <tr className="head">
              <th className="headtime">เวลา</th>
              <th className="headname">ชื่อ-สกุล</th>
              <th className="headservice">หัตถการ</th>
              <th className="headprovider">ผู้ให้บริการ</th>
              <th className="headstatus">สถานะ</th>
            </tr>
            {/* หัวข้อ */}
            {/* 08:50 */}
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data91.prefix}
                {data91.fname} {data91.lname}
              </td>
              <td className="bodyservice">{data91.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon91}
                  disabled={editButton91}
                  style={{ margin: "0 0 0 4px", display: app91 ? "" : "none" }}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("91");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                >
                  {status91}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel91}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app91 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data91.TN, data91.AN, "appoinment", "91", "clearwomen", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data92.prefix}
                {data92.fname} {data92.lname}
              </td>
              <td className="bodyservice">{data92.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon92}
                  disabled={editButton92}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("92");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app92 ? "" : "none" }}
                >
                  {status92}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel92}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app92 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data92.TN, data92.AN, "appoinments", "92", "clearwomens", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data93.prefix}
                {data93.fname} {data93.lname}
              </td>
              <td className="bodyservice">{data93.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon93}
                  disabled={editButton93}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("93");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app93 ? "" : "none" }}
                >
                  {status93}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel93}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app93 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data93.TN, data93.AN, "appoinmentss", "93", "clearwomenss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data94.prefix}
                {data94.fname} {data94.lname}
              </td>
              <td className="bodyservice">{data94.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon94}
                  disabled={editButton94}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("94");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app94 ? "" : "none" }}
                >
                  {status94}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel94}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app94 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data94.TN, data94.AN, "appoinmentsss", "94", "clearwomensss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data95.prefix}
                {data95.fname} {data95.lname}
              </td>
              <td className="bodyservice">{data95.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon95}
                  disabled={editButton95}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("95");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app95 ? "" : "none" }}
                >
                  {status95}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel95}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app95 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data95.TN, data95.AN, "appoinmentssss", "95", "clearwomenssss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodynine" style={{ display: "none" }}>
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data96.prefix}
                {data96.fname} {data96.lname}
              </td>
              <td className="bodyservice">{data96.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon96}
                  disabled={editButton96}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("96");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app96 ? "" : "none" }}
                >
                  {status96}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel96}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app96 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data96.TN, data96.AN, "appoinmentsssss", "96", "clearwomensssss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 08:50 */}
            {/* 09:55 */}
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data101.prefix}
                {data101.fname} {data101.lname}
              </td>
              <td className="bodyservice">{data101.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon101}
                  disabled={editButton101}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("101");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app101 ? "" : "none" }}
                >
                  {status101}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel101}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app101 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data101.TN, data101.AN, "appoinment", "101", "clearwomen", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data102.prefix}
                {data102.fname} {data102.lname}
              </td>
              <td className="bodyservice">{data102.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon102}
                  disabled={editButton102}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("102");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app102 ? "" : "none" }}
                >
                  {status102}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel102}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app102 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data102.TN, data102.AN, "appoinments", "102", "clearwomens", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data103.prefix}
                {data103.fname} {data103.lname}
              </td>
              <td className="bodyservice">{data103.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon103}
                  disabled={editButton103}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("103");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app103 ? "" : "none" }}
                >
                  {status103}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel103}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app103 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data103.TN, data103.AN, "appoinmentss", "103", "clearwomenss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data104.prefix}
                {data104.fname} {data104.lname}
              </td>
              <td className="bodyservice">{data104.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon104}
                  disabled={editButton104}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("104");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app104 ? "" : "none" }}
                >
                  {status104}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel104}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app104 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data104.TN, data104.AN, "appoinmentsss", "104", "clearwomensss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data105.prefix}
                {data105.fname} {data105.lname}
              </td>
              <td className="bodyservice">{data105.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon105}
                  disabled={editButton105}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("105");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app105 ? "" : "none" }}
                >
                  {status105}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel105}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app105 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data105.TN, data105.AN, "appoinmentssss", "105", "clearwomenssss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data106.prefix}
                {data106.fname} {data106.lname}
              </td>
              <td className="bodyservice">{data106.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon106}
                  disabled={editButton106}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("106");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app106 ? "" : "none" }}
                >
                  {status106}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel106}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app106 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data106.TN, data106.AN, "appoinmentsssss", "106", "clearwomensssss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 09:55 */}
            {/* 11.00 */}
            <tr className="bodyeleven">
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data111.prefix}
                {data111.fname} {data111.lname}
              </td>
              <td className="bodyservice">{data111.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon111}
                  disabled={editButton111}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("111");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app111 ? "" : "none" }}
                >
                  {status111}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel111}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app111 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data111.TN, data111.AN, "appoinment", "111", "clearwomen", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data112.prefix}
                {data112.fname} {data112.lname}
              </td>
              <td className="bodyservice">{data112.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon112}
                  disabled={editButton112}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("112");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app112 ? "" : "none" }}
                >
                  {status112}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel112}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app112 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data112.TN, data112.AN, "appoinments", "112", "clearwomens", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data113.prefix}
                {data113.fname} {data113.lname}
              </td>
              <td className="bodyservice">{data113.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon113}
                  disabled={editButton113}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("113");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app113 ? "" : "none" }}
                >
                  {status113}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel113}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app113 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data113.TN, data113.AN, "appoinmentss", "113", "clearwomenss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data114.prefix}
                {data114.fname} {data114.lname}
              </td>
              <td className="bodyservice">{data114.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon114}
                  disabled={editButton114}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("114");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app114 ? "" : "none" }}
                >
                  {status114}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel114}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app114 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data114.TN, data114.AN, "appoinmentsss", "114", "clearwomensss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data115.prefix}
                {data115.fname} {data115.lname}
              </td>
              <td className="bodyservice">{data115.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon115}
                  disabled={editButton115}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("115");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app115 ? "" : "none" }}
                >
                  {status115}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel115}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app115 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data115.TN, data115.AN, "appoinmentssss", "115", "clearwomenssss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven" style={{ display: "none" }}>
              <td className="bodytime">{time11[0]} น.</td>
              <td className="bodyname">
                {data116.prefix}
                {data116.fname} {data116.lname}
              </td>
              <td className="bodyservice">{data116.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon116}
                  disabled={editButton116}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("116");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app116 ? "" : "none" }}
                >
                  {status116}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel116}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app116 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data116.TN, data116.AN, "appoinmentsssss", "116", "clearwomensssss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 11.00 */}
            {/* 13.30 */}
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data131.prefix}
                {data131.fname} {data131.lname}
              </td>
              <td className="bodyservice">{data131.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon131}
                  disabled={editButton131}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("131");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app131 ? "" : "none" }}
                >
                  {status131}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel131}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app131 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data131.TN, data131.AN, "appoinment", "131", "clearwomen", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data132.prefix}
                {data132.fname} {data132.lname}
              </td>
              <td className="bodyservice">{data132.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon132}
                  disabled={editButton132}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("132");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app132 ? "" : "none" }}
                >
                  {status132}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel132}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app132 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data132.TN, data132.AN, "appoinments", "132", "clearwomens", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data133.prefix}
                {data133.fname} {data133.lname}
              </td>
              <td className="bodyservice">{data133.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon133}
                  disabled={editButton133}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("133");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app133 ? "" : "none" }}
                >
                  {status133}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel133}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app133 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data133.TN, data133.AN, "appoinmentss", "133", "clearwomenss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data134.prefix}
                {data134.fname} {data134.lname}
              </td>
              <td className="bodyservice">{data134.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon134}
                  disabled={editButton134}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("134");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app134 ? "" : "none" }}
                >
                  {status134}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel134}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app134 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data134.TN, data134.AN, "appoinmentsss", "134", "clearwomensss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data135.prefix}
                {data135.fname} {data135.lname}
              </td>
              <td className="bodyservice">{data135.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon135}
                  disabled={editButton135}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("135");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app135 ? "" : "none" }}
                >
                  {status135}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel135}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app135 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data135.TN, data135.AN, "appoinmentssss", "135", "clearwomenssss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data136.prefix}
                {data136.fname} {data136.lname}
              </td>
              <td className="bodyservice">{data136.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon136}
                  disabled={editButton136}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("136");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app136 ? "" : "none" }}
                >
                  {status136}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel136}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app136 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data136.TN, data136.AN, "appoinmentsssss", "136", "clearwomensssss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 13.30 */}
            {/* 14.45 */}
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data141.prefix}
                {data141.fname} {data141.lname}
              </td>
              <td className="bodyservice">{data141.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon141}
                  disabled={editButton141}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("141");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app141 ? "" : "none" }}
                >
                  {status141}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel141}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app141 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data141.TN, data141.AN, "appoinment", "141", "clearwomen", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data142.prefix}
                {data142.fname} {data142.lname}
              </td>
              <td className="bodyservice">{data142.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon142}
                  disabled={editButton142}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("142");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app142 ? "" : "none" }}
                >
                  {status142}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel142}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app142 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data142.TN, data142.AN, "appoinments", "142", "clearwomens", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data143.prefix}
                {data143.fname} {data143.lname}
              </td>
              <td className="bodyservice">{data143.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon143}
                  disabled={editButton143}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("143");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app143 ? "" : "none" }}
                >
                  {status143}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel143}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app143 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data143.TN, data143.AN, "appoinmentss", "143", "clearwomenss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data144.prefix}
                {data144.fname} {data144.lname}
              </td>
              <td className="bodyservice">{data144.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon144}
                  disabled={editButton144}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("144");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app144 ? "" : "none" }}
                >
                  {status144}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel144}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app144 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data144.TN, data144.AN, "appoinmentsss", "144", "clearwomensss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data145.prefix}
                {data145.fname} {data145.lname}
              </td>
              <td className="bodyservice">{data145.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon145}
                  disabled={editButton145}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("145");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app145 ? "" : "none" }}
                >
                  {status145}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel145}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app145 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data145.TN, data145.AN, "appoinmentssss", "145", "clearwomenssss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body14" style={{ display: "none" }}>
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data146.prefix}
                {data146.fname} {data146.lname}
              </td>
              <td className="bodyservice">{data146.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon146}
                  disabled={editButton146}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("145");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app146 ? "" : "none" }}
                >
                  {status146}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel146}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app146 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data146.TN, data146.AN, "appoinmentsssss", "146", "clearwomensssss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 14.45 */}
            {/* 16.15 */}
            <tr className="body16">
              <td className="bodytime">{time16[0]} น.</td>
              <td className="bodyname">
                {data161.prefix}
                {data161.fname} {data161.lname}
              </td>
              <td className="bodyservice">{data161.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon161}
                  disabled={editButton161}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("161");
                    setTimeString(time16[0]);
                    setTimeId(time16[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app161 ? "" : "none" }}
                >
                  {status161}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel161}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app161 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data161.TN, data161.AN, "appoinment", "161", "clearwomen", time16[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body16">
              <td className="bodytime">{time16[0]} น.</td>
              <td className="bodyname">
                {data162.prefix}
                {data162.fname} {data162.lname}
              </td>
              <td className="bodyservice">{data162.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon162}
                  disabled={editButton162}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("162");
                    setTimeString(time16[0]);
                    setTimeId(time16[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app162 ? "" : "none" }}
                >
                  {status162}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel162}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app162 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data162.TN, data162.AN, "appoinments", "162", "clearwomens", time16[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body16">
              <td className="bodytime">{time16[0]} น.</td>
              <td className="bodyname">
                {data163.prefix}
                {data163.fname} {data163.lname}
              </td>
              <td className="bodyservice">{data163.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon163}
                  disabled={editButton163}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("163");
                    setTimeString(time16[0]);
                    setTimeId(time16[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app163 ? "" : "none" }}
                >
                  {status163}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel163}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app163 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data163.TN, data163.AN, "appoinmentss", "163", "clearwomenss", time16[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 16.15 */}
            {/* 17.30 */}
            <tr className="body17">
              <td className="bodytime">{time17[0]} น.</td>
              <td className="bodyname">
                {data171.prefix}
                {data171.fname} {data171.lname}
              </td>
              <td className="bodyservice">{data171.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon171}
                  disabled={editButton171}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("171");
                    setTimeString(time17[0]);
                    setTimeId(time17[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app171 ? "" : "none" }}
                >
                  {status171}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel171}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app171 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data171.TN, data171.AN, "appoinment", "171", "clearwomen", time17[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body17">
              <td className="bodytime">{time17[0]} น.</td>
              <td className="bodyname">
                {data172.prefix}
                {data172.fname} {data172.lname}
              </td>
              <td className="bodyservice">{data172.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon172}
                  disabled={editButton172}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("172");
                    setTimeString(time17[0]);
                    setTimeId(time17[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app172 ? "" : "none" }}
                >
                  {status172}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel172}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app172 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data172.TN, data172.AN, "appoinments", "172", "clearwomens", time17[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            <tr className="body17">
              <td className="bodytime">{time17[0]} น.</td>
              <td className="bodyname">
                {data173.prefix}
                {data173.fname} {data173.lname}
              </td>
              <td className="bodyservice">{data173.service}</td>
              <td className="bodyprovider">หญิง</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon173}
                  disabled={editButton173}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("173");
                    setTimeString(time17[0]);
                    setTimeId(time17[1]);
                    setProvider(providername[0]);
                    handlecheck();
                  }}
                  style={{ margin: "0 0 0 4px", display: app173 ? "" : "none" }}
                >
                  {status173}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel173}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px", display: app173 ? "none" : "" }}
                  onClick={() => {
                    handleCancel(data173.TN, data173.AN, "appoinmentss", "173", "clearwomenss", time17[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
              </td>
            </tr>
            {/* 16.15 */}   

            <Modal keepMounted open={open} onClose={closemadal} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
              <Paper>
                <Box sx={style}>
                  <Typography id="keep-mounted-modal-title" style={{ textAlign: "center", marginBottom: 16 }} variant="h6" component="h2">
                    กรุณาตรวจสอบความถูกต้อง
                  </Typography>
                  <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="ชื่อ-สกุล"
                          id="outlined-name"
                          value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="TN"
                          id="outlined-name"
                          value={tntoken}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="date"
                          label="วันที่"
                          variant="standard"
                          placeholder="วันที่"
                          value={`${selectdate}`}
                          InputProps={{
                            readOnly: true,
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="เวลา"
                          id="outlined-name"
                          value={`${timeString}`}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="ผู้ให้บริการ"
                          id="outlined-name"
                          value={`${provider}`}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-standard-label">กรุณาเลือกหัตถการที่ต้องการ</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={service}
                            onChange={(e) => {
                              setService(e.target.value);
                            }}
                            label="service"
                          >
                            <MenuItem value="นวด">นวด</MenuItem>
                            <MenuItem value="ประคบ">ประคบ</MenuItem>
                            <MenuItem value="อบสมุนไพร">อบสมุนไพร</MenuItem>
                            <MenuItem value="นวดและประคบ">นวดและประคบ</MenuItem>
                            <MenuItem value="บริบาลมารดาหลังคลอด">บริบาลมารดาหลังคลอด</MenuItem>
                            <MenuItem value="นวดเท้าเพื่อสุขภาพ">นวดเท้าเพื่อสุขภาพ</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={8}>
                        <Button fullWidth variant="contained" color="primary" onClick={() => handleSubmit(appUrl, appId, timeString, timeId)}>
                          ยืนยัน
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button fullWidth variant="contained" color="error" onClick={closemadal}>
                          ยกเลิก
                        </Button>
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Modal>
          </tbody>
        </table>
      </div>
    </div>
  );
}
