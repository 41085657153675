import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import { Button } from "@mui/material";
import { AddCircleOutlined, DeleteOutlined, EditOutlined } from "@mui/icons-material/";
import "./ondate.css";
import { Box } from "@mui/system";
import { TextField, Grid, Modal } from "@mui/material";
import Swal from "sweetalert2";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function Note(props) {
  const startDate = props.date;
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const [getnote, setGetnote] = useState([]);
  const [getnoteall, setGetnoteall] = useState([]);
  const [note, setNote] = useState("");
  const [openaddnote, setAddnote] = useState(false);
  const [editnote, setEditnote] = useState(null);
  const [editnoteNN, setEditnoteNN] = useState(null);
  const [openeditnote, setOpeneditnote] = useState(false);

  const getnotes = async () => {
    try {
      const notedate = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
      const urlnote = `${URL}/note/${notedate}`;
      const urlnoteall = `${URL}/note`;
      const [resnote, resnoteall] = await Promise.all([axios.get(urlnote), axios.get(urlnoteall)]);
      setGetnote(resnote.data);
      setGetnoteall(resnoteall.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getnotes();
  }, [startDate, URL]);

  const handleOpenaddnote = () => setAddnote(true);
  const handleCloseaddnote = () => setAddnote(false);

  const submitnote = (event) => {
    event.preventDefault();
    const datenote = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    axios
      .post(`${URL}/note`, {
        note: note,
        date: datenote,
      })
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "เพิ่มรายการเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            setNote("");
            getnotes();
            handleCloseaddnote();
            return;
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "ผิดพลาด",
            text: "ไม่สามารถเพิ่มรายการได้",
            showConfirmButton: false,
            timer: 2500,
          }).then((result) => {
            setNote("");
            getnotes();
            handleCloseaddnote();
            return;
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const senddeletenote = (NN) => {
    Swal.fire({
      title: "ยืนยันการลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        deletenote(NN);
      }
    });
  };

  const deletenote = (NN) => {
    var data = JSON.stringify({
      NN: NN,
    });

    var config = {
      method: "delete",
      url: `${URL}/note`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการลบข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          getnotes();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCloseeditnote = () => setOpeneditnote(false);
  const handleOpeneditnote = (NN, note) => {
    setEditnoteNN(NN);
    setEditnote(note);
    setOpeneditnote(true);
    return;
  };

  const submiteditnote = (event) => {
    event.preventDefault();
    axios
      .put(`${URL}/note/${editnoteNN}`, {
        note: editnote,
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data["status"] === "OK") {
          Swal.fire({
            icon: "success",
            title: "เสร็จสิ้น",
            text: "แก้ไขหมายเหตุเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            getnotes();
            handleCloseeditnote();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* <table>
        <thead>
          <tr>
            <th
              className="note"
              style={{
                textDecoration: "underline",
                width: "70%",
              }}
            >
              หมายเหตุประจำวัน:
            </th>
            <td>
              <Button variant="contained" color="success" className="btnstatus" size="small" startIcon={<AddCircleOutlined />} onClick={handleOpenaddnote} style={{ margin: "0 0 0 4px", textAlign: "center" }}>
                เพิ่ม
              </Button>
            </td>
          </tr>
        </thead>
        <tbody>
          {getnote.map((item) => {
            return (
              <tr style={{ backgroundColor: "#ccf3f4" }} key={item.NN}>
                <td className="note" style={{ textAlign: "left", paddingLeft: "20px" }}>
                  {item.note}
                </td>
                <td>
                  <Button variant="contained" color="warning" size="small" startIcon={<EditOutlined />} style={{ margin: "0 0 0 4px" }} onClick={() => handleOpeneditnote(item.NN, item.note)} className="btnstatus">
                    แก้ไข
                  </Button>
                  <Modal open={openeditnote} onClose={handleCloseeditnote} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <form onSubmit={submiteditnote}>
                        <Grid margin={1}>
                          <h1
                            style={{
                              textAlign: "center",
                              marginTop: "20px",
                              marginBottom: "40px",
                            }}
                          >
                            รายละเอียด
                          </h1>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                id="tels"
                                label="หมายเหตุ"
                                ariant="outlined"
                                fullWidth
                                onChange={(event) => {
                                  setEditnote(event.target.value);
                                }}
                                value={editnote}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                                ยืนยัน
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Button onClick={handleCloseeditnote} variant="contained" color="error" size="large" fullWidth>
                                ยกเลิก
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Modal>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    className="btnstatus"
                    startIcon={<DeleteOutlined />}
                    style={{ margin: "0 0 0 4px" }}
                    onClick={() => {
                      return senddeletenote(item.NN);
                    }}
                  >
                    ลบ
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
      <table style={{ marginTop: "30px" }}>
        <thead>
          <tr>
            <th
              className="note"
              style={{
                textDecoration: "underline",
                width: "70%",
              }}
            >
              หมายเหตุทั้งหมด:
            </th>
            <td>
              <Button variant="contained" color="success" className="btnstatus" size="small" startIcon={<AddCircleOutlined />} onClick={handleOpenaddnote} style={{ margin: "0 0 0 4px", textAlign: "center" }}>
                เพิ่ม
              </Button>
            </td>
          </tr>
        </thead>
        <tbody>
          {getnoteall.map((item) => {
            return (
              <tr style={{ backgroundColor: "#ccf3f4" }} key={item.NN}>
                <td className="note" style={{ textAlign: "left", paddingLeft: "20px" }}>
                  {item.note}
                </td>
                <td>
                  <Button variant="contained" color="warning" size="small" startIcon={<EditOutlined />} style={{ margin: "0 0 0 4px" }} onClick={() => handleOpeneditnote(item.NN, item.note)} className="btnstatus">
                    แก้ไข
                  </Button>
                  <Modal open={openeditnote} onClose={handleCloseeditnote} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <form onSubmit={submiteditnote}>
                        <Grid margin={1}>
                          <h1
                            style={{
                              textAlign: "center",
                              marginTop: "20px",
                              marginBottom: "40px",
                            }}
                          >
                            รายละเอียด
                          </h1>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                id="tels"
                                label="หมายเหตุ"
                                ariant="outlined"
                                fullWidth
                                onChange={(event) => {
                                  setEditnote(event.target.value);
                                }}
                                value={editnote}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                                ยืนยัน
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Button onClick={handleCloseeditnote} variant="contained" color="error" size="large" fullWidth>
                                ยกเลิก
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Modal>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    className="btnstatus"
                    startIcon={<DeleteOutlined />}
                    style={{ margin: "0 0 0 4px" }}
                    onClick={() => {
                      return senddeletenote(item.NN);
                    }}
                  >
                    ลบ
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal open={openaddnote} onClose={handleCloseaddnote} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={stylee}>
          <form onSubmit={submitnote}>
            <Grid margin={1}>
              <h1
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "40px",
                }}
              >
                หมายเหตุ
              </h1>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="หมายเหตุ"
                    ariant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setNote(event.target.value);
                    }}
                    value={note}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                    ยืนยัน
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button onClick={handleCloseaddnote} variant="contained" color="error" size="large" fullWidth>
                    ยกเลิก
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
