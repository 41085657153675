import * as React from "react";
import Paper from "@mui/material/Paper";
import Navback from "./Navbarback";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, TextField, Grid, CssBaseline, FormControl, InputLabel, Select, MenuItem, Switch } from "@mui/material";
import { DeleteForever, Edit, AddCircle, RestartAltOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Footer from "../fronthome/footer/footer";
import "./users.css";
import Swal from "sweetalert2";
import Usercreate from "./Usercreate";
import Searchrole from "./Searchrole";
import { type } from "@testing-library/user-event/dist/type";

const styleeeee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function Users() {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  //ตรวจสอบ role
  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK" && (role === "admin" || role === "root")) {
          // alert('login success')
        } else if (response.data.status === "OK" && (role !== "admin" || role !== "root")) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  //ตรวจสอบrole

  //ดึงข้อuser

  const [data, setData] = useState([]);

  const UsersGet = () => {
    axios.get(`${URL}/users`).then((res) => {
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      setData(res.data);
    });
  };

  //การสร้างuser

  const [openusercreate, setOpenusercreate] = useState(false);

  const handleOpenusercrate = () => setOpenusercreate(true);
  const handleCloseusercrate = () => setOpenusercreate(false);

  //การสร้างuser

  useEffect(() => {
    UsersGet();
  }, [URL]);

  //ดึงข้อuser

  //เปิดปิดการลบ,การรีเซ็ตรหัสผ่าน

  const [checked, setChecked] = useState(false);
  const [deletereset, setDeletereset] = useState(true);

  const handleswitch = (event) => {
    setChecked(event.target.checked);
    setDeletereset(checked);
  };

  //เปิดปิดการลบ,การรีเซ็ตรหัสผ่าน

  //การแก้ไขข้อมูลusers

  const [oldTN, setoldTN] = useState(null);
  const [userTN, setUserTN] = useState(null);
  const [userprefix, setUserprefix] = useState(null);
  const [userfname, setUserfname] = useState(null);
  const [userlname, setUserlname] = useState(null);
  const [usertel, setUsertel] = useState(null);
  const [rolelist, setRolelist] = useState();
  const [showname, setShowname] = useState();
  const [uuid, setUuid] = useState();
  const [hn, setHN] = useState('');
  const [cid, setCid] = useState('');

  const rolelistChange = (event) => {
    setRolelist(event.target.value);
  };

  const shownamechang = (event) => {
    setShowname(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (TN, prefix, fname, lname, tel, role, uuid, hn, cid, type) => {
    setoldTN(TN);
    setUserTN(TN);
    setUserprefix(prefix);
    setUserfname(fname);
    setUserlname(lname);
    setUsertel(tel);
    setRolelist(role);
    setUuid(uuid);
    setHN(hn);
    setCid(cid);
    setShowname(type);
    setOpen(true);
    return;
  };
  const handleClose = () => setOpen(false);

  const edituserdata = (event) => {
    event.preventDefault();
    axios
      .put(`${URL}/users/${oldTN}`, {
        TN: userTN,
        prefix: userprefix,
        fname: userfname,
        lname: userlname,
        tel: usertel,
        role: rolelist,
        uuid: uuid,
        hn: hn,
        cid: cid,
        type: showname
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data["status"] === "OK") {
          Swal.fire({
            icon: "success",
            title: "เสร็จสิ้น",
            text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            UsersGet();
            handleClose();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //การแก้ไขข้อมูลusers

  // การลบข้อมูล

  const deleteuser = (TN) => {
    Swal.fire({
      title: "คำเตือน",
      text: "การลบข้อมูลจะไม่สามารถกู้คืนกลับมาได้อีก",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteuserdata(TN);
      }
    });
  };

  function deleteuserdata(TN) {
    var data = JSON.stringify({
      TN: TN,
    });

    var config = {
      method: "delete",
      url: `${URL}/users`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            icon: "success",
            title: "เสร็จสิ้น",
            text: "ทำการลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 2000,
          }).then((result) => {
            UsersGet();
            return;
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [search, setSearch] = useState("");

  const testtt = (e) => {
    return setSearch(e.target.value);
  };
  //การลบข้อมูล

  //reset password=>{}

  const resetpassword = (tel) => {
    var data = JSON.stringify({
      tel: tel,
      password: tel,
    });

    var config = {
      method: "put",
      url: `${URL}/repassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "รีเซ็ตรหัสผ่านเรียบร้อย",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch(function (error) {
        UsersGet();
      });
  };

  const handleresetpassword = (tel) => {
    Swal.fire({
      title: "คำเตือน",
      text: "การรีเซ็ตรหัสผ่านควรได้รับการยินยอมจากผู้รับบริการก่อน",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "ยืนยันการรีเซ็ตรหัสผ่านอีกครั้ง",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            resetpassword(tel);
          }
        });
      }
    });
  };

  const setdata = (data) => {
    setHN(Number(data.hn).toString())
    setCid(data.cid)
    return;
  };

  return (
    <>
      <Navback />
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Paper style={{ marginTop: "100px", marginBottom: "65px" }}>
          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
            <Grid item xs={5} style={{ marginLeft: "10px", marginTop: "3px" }}>
              <TextField id="outlined-basic" label="ค้นหา..." variant="outlined" size="small" onChange={testtt} fullWidth />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" color="success" size="large" startIcon={<AddCircle />} onClick={handleOpenusercrate}>
                เพิ่มสมาชิก
              </Button>
              <Modal open={openusercreate} onClose={handleCloseusercrate} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={styleeeee}>
                  <Usercreate />
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <div>
            <table>
              <thead>
                <tr className="head">
                  <th className="headusertn">HN</th>
                  <th className="headusername">ชื่อ-สกุล</th>
                  <th className="headusertel">เบอร์โทรศัพท์</th>
                  <th className="headuserrole">สิทธิ์</th>
                  <th className="headuseraction">
                    สถานะ
                    <Switch checked={checked} onChange={handleswitch} inputProps={{ "aria-label": "controlled" }} color="error" />
                  </th>
                </tr>
              </thead>
              <tbody className="users">
                {data
                  .sort(function (a, b) {
                    if (a.TN > b.TN) {
                      return -1;
                    } else if (a.TN < b.TN) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })
                  .filter((e) => {
                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search) || e.tel.includes(search) || e.hn.includes(search);
                  })
                  .filter((element, index) => {
                    return index <= 14;
                  })
                  .sort(function (a, b) {
                    if (a.TN < b.TN) {
                      return -1;
                    } else if (a.TN > b.TN) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }).map((item) => {
                    return (
                      <tr key={item.TN}>
                        <td align="center">{item.hn.padStart(9, "0")}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.prefix}
                          {item.fname} {item.lname}
                        </td>
                        <td align="center" className="bodyusertel">
                          {item.tel}
                        </td>
                        <td align="center" className="bodyuserrole">
                          {item.role}
                        </td>
                        <td align="center" className="bodyuseraction">
                          {/* <Link
                            to="/useredit"
                            style={{ textDecoration: "none" }}
                          > */}
                          <Button variant="contained" color="secondary" size="small" className="editusercom" startIcon={<Edit />} style={{ margin: "0 0 0 8px" }} onClick={() => handleOpen(item.TN, item.prefix, item.fname, item.lname, item.tel, item.role, item.uuid,item.hn,item.cid, item.type)}>
                            แก้ไขข้อมูล
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            disabled={deletereset}
                            className="deleteuser"
                            onClick={() => deleteuser(item.TN)}
                            startIcon={<DeleteForever />}
                            style={{ margin: "0 0 0 8px" }}
                          >
                            ลบข้อมูล
                          </Button>
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            disabled={deletereset}
                            className="resetusercom"
                            onClick={() => {
                              return handleresetpassword(item.tel);
                            }}
                            startIcon={<RestartAltOutlined />}
                            style={{ margin: "0 0 0 8px" }}
                          >
                            รีเซ็ตรหัสผ่าน
                          </Button>
                          <div className="divbutton">
                            <Button variant="contained" color="secondary" size="small" className="editusermobile" onClick={() => handleOpen(item.TN, item.prefix, item.fname, item.lname, item.tel, item.role)}>
                              <Edit color="secondary" />
                            </Button>
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                              onClick={() => {
                                return handleresetpassword(item.tel);
                              }}
                              disabled={deletereset}
                              className="resetusermobile"
                            >
                              <RestartAltOutlined color="warning" style={{ marginLeft: "10px" }} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* การแก้ไขข้อมูล */}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={styleeeee}>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ p: 2 }}>
                  <Searchrole setdata={setdata} />
                  <Typography variant="h6" gutterBottom component="div">
                    แก้ไขข้อมูล
                  </Typography>
                  <form onSubmit={edituserdata}>
                    <Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={3.5} sm={3.5}>
                          <TextField
                            label="คำนำหน้า"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setUserprefix(event.target.value);
                            }}
                            value={`${userprefix}`}
                          />
                        </Grid>
                        <Grid item xs={4.25} sm={4.25}>
                          <TextField
                            label="ชื่อ"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setUserfname(event.target.value);
                            }}
                            value={`${userfname}`}
                          />
                        </Grid>
                        <Grid item xs={4.25} sm={4.25}>
                          <TextField
                            label="นามสกุล"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setUserlname(event.target.value);
                            }}
                            value={`${userlname}`}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <TextField
                            label="HN"
                            variant="outlined"
                            fullWidth
                            onChange={(event) => {
                              setHN(event.target.value);
                            }}
                            value={hn}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8}>
                          <TextField
                            label="เลขที่บัตรประชาชน"
                            variant="outlined"
                            fullWidth
                            onChange={(event) => {
                              setCid(event.target.value);
                            }}
                            value={cid}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <FormControl variant="outlined" fullWidth required>
                            <InputLabel id="demo-simple-select-standard-label">สิทธิ์</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="role"
                              onChange={rolelistChange}
                              value={`${rolelist}`}
                            >
                              <MenuItem value="admin">ผู้ดูแลระบบ</MenuItem>
                              <MenuItem value="user">ผู้ใช้งานทั่วไป</MenuItem>
                              <MenuItem value="UC">บัตรทอง</MenuItem>
                              <MenuItem value="A2">ข้าราชการ</MenuItem>
                              <MenuItem value="A7">ประกันสังคม</MenuItem>
                              <MenuItem value="A1">ชำระเงิน</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <FormControl variant="outlined" fullWidth required>
                            <InputLabel id="demo-simple-select-standard-label">การแสดงชื่อ</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="role"
                              onChange={shownamechang}
                              value={`${showname}`}
                            >
                              <MenuItem value="0">เปิดเผย</MenuItem>
                              <MenuItem value="1">ไม่เปิดเผย</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={6} sm={6}>
                          <TextField
                            label="HN"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setUserTN(event.target.value);
                            }}
                            value={`${userTN}`}
                          />
                        </Grid> */}
                        {/* <Grid item xs={6} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">สิทธิ์</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={`${rolelist}`} onChange={rolelistChange} label="role">
                              <MenuItem value="admin">ผู้ดูแลระบบ</MenuItem>
                              <MenuItem value="user">ผู้ใช้งานทั่วไป</MenuItem>
                              <MenuItem value="UC">บัตรทอง</MenuItem>
                              <MenuItem value="A2">ข้าราชการ</MenuItem>
                              <MenuItem value="A7">ประกันสังคม</MenuItem>
                              <MenuItem value="A1">ชำระเงิน</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          <TextField
                            label="เบอร์โทรศัพท์"
                            variant="outlined"
                            fullWidth
                            required
                            value={`${usertel}`}
                            onChange={(event) => {
                              setUsertel(event.target.value);
                            }}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                          <TextField
                            label="UserID"
                            variant="outlined"
                            fullWidth
                            value={`${uuid}`}
                            onChange={(event) => {
                              setUuid(event.target.value);
                            }}
                          />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          <Button variant="contained" color="primary" size="large" type="submit" fullWidth>
                            ยืนยันการแก้ไข้ข้อมูล
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </Box>
            </Modal>
          </div>
          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
            <Grid item xs={5} style={{ marginLeft: "10px", marginTop: "20px", marginBottom: "20px" }}>
              <TextField id="outlined-basics" label="ค้นหา..." variant="outlined" size="small" onChange={testtt} fullWidth />
            </Grid>
            <Grid item xs={6} textAlign="right" style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Button variant="contained" color="success" size="large" startIcon={<AddCircle />} onClick={handleOpenusercrate}>
                เพิ่มสมาชิก
              </Button>
              <Modal open={openusercreate} onClose={handleCloseusercrate} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={styleeeee}>
                  <Usercreate getuser={UsersGet} close={setOpenusercreate} />
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

// onSubmit={editform}
// onChange={prefix}
// onChange={(event)=>{setFname(event.target.value)}}
// onChange={(event)=>{setLname(event.target.value)}}
// onChange={(event)=>{setTNN(event.target.value)}}
// onChange={(event)=>{setRole(event.target.value)}}
// onChange={(event)=>{setTel(event.target.value)}}
