import Navbar from "./Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { Button, CssBaseline, Container, TextField } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import Footer from "./footer/footer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Searchhistory from "./Searchhistory";

export default function Searchapp() {
  const tntoken = localStorage.getItem("TN");
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const navigate = useNavigate();
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const URLAlert = "https://lineapi.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "กรุณาเข้าสู่ระบบก่อนใช้งาน",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            navigate("/login");
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);

  //ดึงข้อมูลผิดนัด
  const [datablock, setDatablock] = useState([]);
  const getblock = () => {
    const tntoken = localStorage.getItem("TN");
    const URLblocklist = `${URL}/getblock/${tntoken}`;
    axios.get(URLblocklist).then((res) => {
      setDatablock(res.data);
    });
  };
  //ดึงข้อมูลผิดนัด

  //จำนวนครั้งที่นัด
  const [data, setData] = useState([]);

  const CheckL = async () => {
    try {
      const appoinment = [
        { name: "appoinment", id: "1" },
        { name: "appoinments", id: "2" },
        { name: "appoinmentss", id: "3" },
        { name: "appoinmentsss", id: "4" },
        { name: "appoinmentssss", id: "5" },
        { name: "appoinmentsssss", id: "6" },
      ];
      const api = appoinment.map((item) => {
        return `${URL}/${item.name}/${tntoken}`;
      });
      const [res1, res2, res3, res4, res5, res6] = await Promise.all([axios.get(api[0]), axios.get(api[1]), axios.get(api[2]), axios.get(api[3]), axios.get(api[4]), axios.get(api[5])]);
      const arr = res1.data.concat(res2.data, res3.data, res4.data, res5.data, res6.data);
      const arrfilter = arr.filter(function (item) {
        return item !== "";
      });
      const arrsort = arrfilter.sort((a, b) => {
        const d1 = new Date(a.dateapp);
        const d2 = new Date(b.dateapp);
        if (d1 > d2) {
          return 1;
        } else if (d1 < d2) {
          return -1;
        } else {
          return 0;
        }
      });
      setData(arrsort);
    } catch (error) {
      console.log(error);
    }
  };
  //จำนวนครั้งที่นัด

  useEffect(() => {
    getblock();
    CheckL();
  }, []);

  //cancel
  const alertline = (date) => {
    const date2 = () => {
      return new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear();
    };
    if (date === date2()) {
      var config = {
        method: "post",
        url: `${URLAlert}/alertline`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
      return;
    } else {
      return;
    }
  };

  const confirmcancel = (AN, time, date, cancel) => {
    Swal.fire({
      title: "คำเตือน",
      text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยกเลิกนัดหมาย",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelalert(AN, time, date, cancel);
      }
    });
  };

  const cancelalert = (AN, time, date, cancel) => {
    const setappurl = () => {
      if (cancel === "clearwomen") {
        return "appoinment";
      } else if (cancel === "clearwomens") {
        return "appoinments";
      } else if (cancel === "clearwomenss") {
        return "appoinmentss";
      } else if (cancel === "clearwomensss") {
        return "appoinmentsss";
      } else if (cancel === "clearwomenssss") {
        return "appoinmentssss";
      } else if (cancel === "clearwomensssss") {
        return "appoinmentsssss";
      } else {
        return;
      }
    };
    const settime = () => {
      if (time === "08:50") {
        return "9";
      } else if (time === "09:55") {
        return "10";
      } else if (time === "11:00") {
        return "11";
      } else if (time === "13:30") {
        return "13";
      } else if (time === "14:45") {
        return "14";
      } else if (time === "16:15") {
        return "16";
      } else if (time === "17:30") {
        return "17";
      } else {
        return;
      }
    };

    var data = JSON.stringify({
      appurl: setappurl(),
      timeid: settime(),
      date: date,
    });
    var config = {
      method: "post",
      url: `${URLAlert}/cancelappoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        canceldata(AN, cancel, date);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "ผิดพลาด",
          text: "ไม่สามารถยกเลิกนัดหมายได่้",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          console.log(error);
        });
        return;
      });
  };

  const canceldata = (AN, cancel, date) => {
    var data = JSON.stringify({
      AN: AN,
    });
    var config = {
      method: "delete",
      url: `${URL}/${cancel}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          alertline(date);
          CheckL();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //cancel

  return (
    <>
      <Box>
        <Paper
          style={{
            marginBottom: "20px",
            marginTop: "100px",
            marginRight: "16px",
            marginLeft: "16px",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <h2 style={{ textAlign: "center" }}>ปฏิทินนัดนวด</h2>
            <h3 style={{ textAlign: "center", color: "black" }}>
              คุณ{fnametoken} {lnametoken}
            </h3>
            <h4 style={{ textAlign: "center", color: "black" }}>จำนวนที่นัดทั้งหมด = {data.length}</h4>
          </div>
          <TableContainer component={Paper}>
            <table>
              <thead>
                <tr className="head">
                  <td>
                    <h4>วันที่</h4>
                  </td>
                  <td>
                    <h4>เวลา</h4>
                  </td>
                  <td>
                    <h4>เจ้าหน้าที่</h4>
                  </td>
                  <td>
                    <h4>สถานะ</h4>
                  </td>
                </tr>
              </thead>

              <tbody className="search">
                {data.map((row) => (
                  <tr key={uuidv4()}>
                    <td>{row.date}</td>
                    <td>{row.time}</td>
                    <td>{row.provider}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        startIcon={<CancelOutlined />}
                        style={{ margin: "0 0 0 4px" }}
                        onClick={() => {
                          return confirmcancel(row.AN, row.time, row.date, row.cancel);
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Paper>
      </Box>
      <Box>
        <Paper
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            marginRight: "16px",
            marginLeft: "16px",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <h2 style={{ textAlign: "center" }}>ประวัติการผิดนัดหมาย</h2>
            <h3 style={{ textAlign: "center", color: "black" }}>
              คุณ{fnametoken} {lnametoken}
            </h3>
            <h4 style={{ textAlign: "center", color: "black" }}>จำนวนที่ผิดนัดในเดือนนี้ทั้งหมด = {datablock.length} ครั้ง</h4>
            <h5 style={{ textAlign: "center", color: "red" }}>ระบบนัดหมายด้วยตนเองสามารถผิดนัดได้ไม่เกิน 3 ครั้ง/เดือน</h5>
          </div>
          <TableContainer component={Paper}>
            <table>
              <thead>
                <tr className="headblock">
                  <td>
                    <h4>วันที่</h4>
                  </td>
                  <td>
                    <h4>เวลา</h4>
                  </td>
                  <td>
                    <h4>เจ้าหน้าที่</h4>
                  </td>
                </tr>
              </thead>

              <tbody className="searchblock">
                {datablock.map((row) => (
                  <tr key={uuidv4()}>
                    <td>{row.date}</td>
                    <td>{row.time}</td>
                    <td>{row.provider}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Paper>
      </Box>
      <Box>
        <div style={{ margin: "10px", marginBottom: "80px" }}>
          <Searchhistory />
        </div>
      </Box>
    </>
  );
}
