import Navbar from "./Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { Button, CssBaseline, Container,TextField } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import Footer from "./footer/footer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Searchapp from "./Searchapp";

const Search = () => {
  const navigate = useNavigate()
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            navigate("/login");
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);

  return (
    <div>
      <Navbar />
      <CssBaseline />
      <Container>
          <Searchapp/>
      </Container>
      <Footer />
    </div>
  );
};

export default Search ;
