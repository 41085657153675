import { useEffect} from "react";
import axios from "axios";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { CssBaseline, Container} from "@mui/material";
import { useNavigate } from "react-router-dom";
import liff from '@line/liff';
import Cancelapp from "./Cancelapp";

export default function Cancelappline(){
  const navigate = useNavigate()
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  useEffect(() => {
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN")
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          liff.init({ liffId: '1657187328-LX0jDbA5' }, () => {
            liff.getProfile().then(profile => {
              axios.put(`${URL}/uuidline/${TN}`, {
                uuid: profile.userId
              })
              .then(function (response) {
                navigate("/cancelappline")
              })
              .catch(function (error) {
                console.log(error);
              });
              }).catch(err => console.error(err));
            }, err => console.error(err));
          } else {
          localStorage.removeItem('fname')
          localStorage.removeItem('lname')
          localStorage.removeItem('TN')
          localStorage.removeItem('role')
          localStorage.removeItem('prefix')
          localStorage.removeItem('uuid')
          navigate("/cancelloginline");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);

  return (
    <div className="aboutpages" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Container>
        <CssBaseline />
        <Box>
          <Paper style={{ marginBottom: "10px", marginTop: "10px", paddingTop: "10px", border: "1px solid #e1932c" }}>
            <Cancelapp/>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};
