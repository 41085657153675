import * as React from "react";
import { Button, TextField, Grid} from "@mui/material";
import { useState } from "react";
import axios from "axios";

export default function Searchrole({setdata}) {
  const URLlocal = "http://192.168.4.5:3333";
  const URL = "https://api.thaipadi.org";
  //cidmodal
  const [cid, setCid] = useState("");
  const [cid2, setCid2] = useState("");
  const [fullname, setFullname] = useState("");
  const [tel, setTel] = useState("");
  const [hn, setHN] = useState("");
  const [rolename, setRolename] = useState("");

  const checktel =(tel)=>{
    const teldata = tel.replace("-", "")
    if(teldata.length===0){
      setTel("ไม่พบเบอร์ติดต่อ")
      return
    }
    else if(teldata.length===10){
      setTel(teldata)
      return
    }
    else{
      setTel(`${teldata}(จำนวนหมายเลขไม่ถูกต้อง)`)
      return
    }
  }

  const submitform = (event) => {
    event.preventDefault();
        const cidtext = (cid) =>{
        if(cid.length < 13){
          return String(cid).padStart(9, "0");
        }
        return cid
    } 
    if (cid === "") {
      setFullname("");
      setRolename("กรุณาระบบเลขบัตรประชาชน");
      setTel(`${tel}(จำนวนหมายเลขไม่ถูกต้อง)`);
      return;
    } else {
      axios
        .get(`${URLlocal}/user/${cidtext(cid)}`, {timeout: 5000})
        .then((res) => {
          if (res.data.length === 0) {
            setFullname("");
            setRolename("ไม่พบข้อมูลในระบบ");
            setTel("");
            return;
          }
          setdata(res.data[0])
          setFullname(`${res.data[0].pname}${res.data[0].fname} ${res.data[0].lname}`);
          setHN(Number(res.data[0].hn).toString())
          setCid2(res.data[0].cid)
          checktel(res.data[0].hometel);
          setRolename(res.data[0].name);
          return;
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED') {
            setFullname("");
            setRolename("ไม่สามารถเชื่อมต่อฐานข้อมูลได้");
            setTel("");
          } else {
            setFullname("");
            setRolename("ไม่สามารถเชื่อมต่อฐานข้อมูลได้");
            setTel("");
            return;
          }
        });
    }
  };
  //cidmodal

  return (
    <div className="mb-4">
      <form onSubmit={submitform}>
      <TextField
          label="กรุณากรอก HN หรือเลขบัตรประชาชน"
          size="small"
          fullWidth
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onChange={(event) => {
            setCid(event.target.value);
          }}
          value={cid}
        />
        <Button style={{marginBottom:'20px'}} type="submit" variant="contained" color="secondary" size="large" fullWidth>
          ค้นหารายชื่อ
        </Button>
        <div className="m-2">
        <Grid>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={8}>
                <TextField label="ชื่อ-สกุล" variant="standard" fullWidth value={fullname} />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="HN"
                  variant="standard"
                  fullWidth
                  value={hn.padStart(7, "0")}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="เลขที่บัตรประชาชน"
                  variant="standard"
                  fullWidth
                  value={cid2}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="เบอร์โทรศัพท์"
                  type="number"
                  variant="standard"
                  fullWidth
                  value={tel}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
              <TextField
                  label="สิทธิ์การรักษา"
                  variant="standard"
                  fullWidth
                  value={rolename}
                />
              </Grid>
            </Grid>
        </Grid>
        </div>
      </form>
    </div>
  );
}
