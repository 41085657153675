import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";


const Navbar = () => {
  const tntoken = localStorage.getItem("token");
  const roletoken = localStorage.getItem("role");
  const usertoken = localStorage.getItem("TN");
  const [isMobile, setIsMobile] = useState(!true);
  const navigate = useNavigate()

  const clearpage = () =>{
    localStorage.clear()
    navigate('/');
  }

  
  if (tntoken !== null && (roletoken === "admin" || roletoken === "root")) {
    return (
      <nav className="navbar">
        <h3>
          <Link to="/backhome" className="logo">
            THAI PADI
          </Link>
        </h3>
        <ul
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => {
            setIsMobile(false);
          }}
        >
          <Link to="/" className="home">
            <li>หน้าหลัก</li>
          </Link>
          <Link to="/backhome" className="about">
            <li>หลังบ้าน</li>
          </Link>
          <Link to="/calendar" className="calendar">
            <li>ปฏิทินนัด</li>
          </Link>
          <Link to="/search" className="cancel">
            <li>ตรวจสอบนัด</li>
          </Link>
          <Link to="/cpass" className="cancel">
            <li>เปลี่ยนรหัสผ่าน</li>
          </Link>
          <Link to="/scheduleusers" className="about">
            <li>เวร</li>
          </Link>
          <Link to="/" onClick={() => {return localStorage.clear()}} className="logout">
            <li>ออกจากระบบ</li>
          </Link>
        </ul>
        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </button>
      </nav>
    );
    
  } else if (tntoken !== null  && (roletoken !== "admin" || roletoken !== "root")) {
    if(usertoken === "123798" || usertoken === "123797" || usertoken === "123796" || usertoken === "123800" || usertoken === "123799" ){
      return (
        <nav className="navbar">
          <h3>
            <Link to="/" className="logo">
              THAI PADI
            </Link>
          </h3>
          <ul
            className={isMobile ? "nav-links-mobile" : "nav-links"}
            onClick={() => {
              setIsMobile(false);
            }}
          >
            <Link to="/" className="home">
              <li>หน้าหลัก</li>
            </Link>
            <Link to="/calendar" className="calendar">
              <li>ปฏิทินนัด</li>
            </Link>
            <Link to="/search" className="cancel">
              <li>ตรวจสอบนัด</li>
            </Link>
            <Link to="/aboutme" className="about">
              <li>เกี่ยวกับเรา</li>
            </Link>
            <Link to="/cpass" className="cancel">
              <li>เปลี่ยนรหัสผ่าน</li>
            </Link>
            <Link to="/scheduleusers" className="about">
              <li>เวร</li>
            </Link>
            <Link to="/" onClick={clearpage} className="logout">
              <li>ออกจากระบบ</li>
            </Link>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsMobile(!isMobile)}
          >
            {isMobile ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </nav>
      );
    }
    else {
      return (
        <nav className="navbar">
          <h3>
            <Link to="/" className="logo">
              THAI PADI
            </Link>
          </h3>
          <ul
            className={isMobile ? "nav-links-mobile" : "nav-links"}
            onClick={() => {
              setIsMobile(false);
            }}
          >
            <Link to="/" className="home">
              <li>หน้าหลัก</li>
            </Link>
            <Link to="/calendar" className="calendar">
              <li>ปฏิทินนัด</li>
            </Link>
            <Link to="/search" className="cancel">
              <li>ตรวจสอบนัด</li>
            </Link>
            <Link to="/aboutme" className="about">
              <li>เกี่ยวกับเรา</li>
            </Link>
            <Link to="/cpass" className="cancel">
              <li>เปลี่ยนรหัสผ่าน</li>
            </Link>
            <Link to="/" onClick={clearpage} className="logout">
              <li>ออกจากระบบ</li>
            </Link>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsMobile(!isMobile)}
          >
            {isMobile ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </nav>
      );
    }
  }  else {
   return (
        <nav className="navbar">
          <h3>
            <Link to="/" className="logo">
              THAI PADI
            </Link>
          </h3>
          <ul
            className={isMobile ? "nav-links-mobile" : "nav-links"}
            onClick={() => {
              setIsMobile(false);
            }}
          >
            <Link to="/" className="home">
              <li>หน้าหลัก</li>
            </Link>
            <Link to="/calendarusers" className="calendar">
              <li>ปฏิทินนัด</li>
            </Link>
            <Link to="/search" className="cancel">
              <li>ตรวจสอบนัด</li>
            </Link>
            <Link to="/aboutme" className="about">
              <li>เกี่ยวกับเรา</li>
            </Link>
            <Link to="/login" className="login">
              <li>เข้าสู่ระบบ
                 {/* | สมัครสมาชิก */}
                 </li>
            </Link>
          </ul>
          <button
            className="mobile-menu-icon"
            onClick={() => setIsMobile(!isMobile)}
          >
            {isMobile ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </nav>
      );
    };
  }


export default Navbar;
