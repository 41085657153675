import "./Calendar.css";
import * as React from "react";
import Webcalendar from "./WebCalendar";
import Navbar from "./Navbar/Navbar";
import Footer from "./footer/footer";

const Calendarusers = () => {
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: "100px", marginBottom: "80px" }}>
        <Webcalendar />
      </div>
      <Footer />
    </div>
  );
};
export default Calendarusers;
