import Footer from "./footer/footer";
import Navbar from "./Navbar/Navbar";


const Aboutme =()=>{
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
    return(
        <div>
            <Navbar/>
            <div style={{marginTop:'120px',textAlign:'center',marginBottom:'50px'}}>
              {/* <img src="https://drive.google.com/uc?id=1O4UGu-0e4Jwasu1NECjjSmTk0eENPFBP" alt="/aboutme" style={{height:'150px'}} /> */}
            </div>
            <div className="officerttm">
              <img className="imgttm" src="https://drive.google.com/uc?id=151holFNjAJyPtyXImOmy13wsXzKrAp5-" alt="julapit" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1aLvdQRgfjlN7HDzGw0Sim3Frt2DywrzQ" alt="arofat" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1zfdf6jaIVS9RUzReCHZ_hYaNqIsFz5s4" alt="aneera" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1zGP_AET-VG8IVu7bYd5GrOpF075igUru" alt="asuwa" />
            </div>
            <div className="servicettm">
              <img className="imgttm" src="https://drive.google.com/uc?id=1afmlwjpMZk5NWbd34VURfwRvznEhkUwY" alt="service" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1AGE8x08j33vktuylvab5n4rk-es3nLkR" alt="service" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1XvIPuJk2M6t_Uvg8esGMPxpNQPmx1SCE" alt="service" />
              <img className="imgttm" src="https://drive.google.com/uc?id=1oG9StlyqShMxM7N_O1gm-55trXxYzo17" alt="service" />
            </div>
            <div className="aboutttm" style={{textAlign:'left'}}>
              <div className="locationttm">
                <div>
                  <h2 style={{color:"white"}}>ข้อมูลหน่วยงาน</h2>
                  <p>
                    คลินิกแพทย์แผนไทย โรงพยาบาลสุไหงปาดี เปิดให้บริการภายใต้การดำเนินงานของกลุ่มงานการแพทย์แผนไทยและการแพทย์ทางเลือก โรงพยาบาลสุไหงปาดี ซึ่งมีเจ้าหน้าที่ที่มีใบประกอบวิชาเวชกรรมไทยเป็นผู้ให้บริการ 
                    โดยคลินิกแพทย์แผนไทยเปิดให้บริการเกี่ยวกับการให้บริการด้านสุขภาพด้วยศาสตร์ทางการแพทย์แผนไทย ประกอบด้วย การตรวจวินิฉัยโรคทางการแพทย์แผนไทย หัตถการการนวดรักษาอาการ หัตถการประคบสมุนไพร หัตถการอบสมุนไพร หัตถการมารดาหลังคลอด การจ่ายยาสมุนไพร และหัตถการด้านการแพทย์แผนไทยอื่น ๆ 
                  </p>
                  <div style={{display:'flex'}}>
                    <div style={{width:'50%'}}>
                      <div>
                        <h2 style={{color:"white"}}>ที่ตั้ง</h2> 
                        <p>กลุ่มงานการแพทย์แผนไทยและการแพทย์ทางเลือก อาคารกายภาพบำบัด โรงพยาบาลสุไหงปาดี</p> 
                        <p>306 ถ.จารุเสถียร ต.ปะลุรู อ.สุไหงปาดี จ.นราธิวาส 96140</p> 
                      </div>
                      <div>
                        <h2 style={{color:"white"}}>โทร.</h2> 
                        <p>073 651 169 ต่อ 154 หรือ 090 523 2588</p> 
                      </div>               
                    </div>
                    <div style={{width:'50%'}}>
                      <h2 style={{color:"white"}}>social</h2> 
                      <p>email: ttm.sungaipadi@gmail.com</p> 
                      <p>facebook: https://www.facebook.com/thaipadi</p> 
                      <p>Line@: @thaipadi</p> 
                      <p>Line ID: 090 523 2588</p>
                      <div style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                      <a style={{marginRight:'20px'}} href="https://www.facebook.com/thaipadi" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://www.kan2.go.th/offline/images/ita/facebook-logo-3.png" alt="facebook fanpages" /></a> 
                      <a style={{marginRight:'20px'}} href="https://line.me/ti/p/JKSk2eP0-W" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" alt="line id" /></a> 
                      <a style={{marginRight:'20px'}} href="https://lin.ee/O3J5WsW" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://d13kiiruo3x6iv.cloudfront.net/73309/image/images/normal/287c97da72cb861c0207665700916fde7950fabc-2021-05-30T15:34:19Z.png" alt="line id" /></a> 
                      <a href="https://www.youtube.com/channel/UC-xQu6uZE9jZogeD4r4mB5A" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://icons-for-free.com/download-icon-tube+video+you+youtube+icon-1320185153402885670_512.png" alt="line id" /></a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="locationttm">
                <div>
                  <h2 style={{color:"white"}}>ข้อมูลโรงพยาบาล</h2>
                  <p>
                    โรงพยาบาลสุไหงปาดี เป็นโรงพยาบาลชุมชนที่อยู่ในเขตพื้นที่ อำเภอสุไหงปาดี จังหวัดนราธิวาส เปิดให้บริการด้านสุขภาพแก่ประชาชนในพื้นที่ โดยดำเนินการภายใต้กรอบนโยบาย
                    ที่กำหนดโดยกระทรวงสาธารณสุข และดำเนินการตามพันธกิจ 5 ด้านคือ 1.เป็นโรงพยาบาลชุมชนที่ให้บริการ ส่งเสริม ป้องกัน รักษา ฟื้นฟู แบบองค์รวม
                    2.พัฒนาระบบบริการให้ได้มาตรฐาน 3.พัฒนาบุคลากรให้มีคุณภาพ พัฒนางานด้วยนวัตกรรมและเสริมสร้างความสุขในการทำงาน 4.พัฒนาองค์กรให้มีระบบบริหารจัดการที่ดี
                    5.เสริมสร้างศักยภาพและความร่วมมือภาคีเครือข่ายในการดูแลสุขภาพประชาชน
                  </p>
                  <div style={{display:'flex'}}>
                    <div style={{width:'50%'}}>
                      <div>
                        <h2 style={{color:"white"}}>ที่ตั้ง</h2> 
                        <p>โรงพยาบาลสุไหงปาดี 306 ถ.จารุเสถียร ต.ปะลุรู อ.สุไหงปาดี จ.นราธิวาส 96140</p> 
                      </div>
                      <div>
                        <h2 style={{color:"white"}}>โทร.</h2> 
                        <p>073 651 169</p> 
                      </div>               
                    </div>
                    <div style={{width:'50%'}}>
                      <h2 style={{color:"white"}}>social</h2> 
                      <p>website: https://www.sph.go.th/</p> 
                      <p>facebook: https://www.facebook.com/spdhos</p> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutttmmobile" style={{textAlign:'left'}}>
              <div className="locationttm">
                <div>
                  <h2 style={{color:"white"}}>ข้อมูลหน่วยงาน</h2>
                  <div>
                    <p>
                      คลินิกแพทย์แผนไทย โรงพยาบาลสุไหงปาดี เปิดให้บริการภายใต้การดำเนินงานของกลุ่มงานการแพทย์แผนไทยและการแพทย์ทางเลือก โรงพยาบาลสุไหงปาดี ซึ่งมีเจ้าหน้าที่ที่มีใบประกอบวิชาเวชกรรมไทยเป็นผู้ให้บริการ 
                      โดยคลินิกแพทย์แผนไทยเปิดให้บริการเกี่ยวกับการให้บริการด้านสุขภาพด้วยศาสตร์ทางการแพทย์แผนไทย ประกอบด้วย การตรวจวินิฉัยโรคทางการแพทย์แผนไทย หัตถการการนวดรักษาอาการ หัตถการประคบสมุนไพร หัตถการอบสมุนไพร หัตถการมารดาหลังคลอด 
                      การจ่ายยาสมุนไพร และหัตถการด้านการแพทย์แผนไทยอื่น ๆ 
                    </p>
                  </div>
                  <div>
                    <div>
                      <div>
                        <h2 style={{color:"white"}}>ที่ตั้ง</h2> 
                        <p>กลุ่มงานการแพทย์แผนไทยและการแพทย์ทางเลือก อาคารกายภาพบำบัด โรงพยาบาลสุไหงปาดี</p> 
                        <p>306 ถ.จารุเสถียร ต.ปะลุรู อ.สุไหงปาดี จ.นราธิวาส 96140</p> 
                      </div>
                      <div>
                        <h2 style={{color:"white"}}>โทร.</h2> 
                        <p>073 651 169 ต่อ 154 หรือ 090 523 2588</p> 
                      </div>               
                    <div>
                      <h2 style={{color:"white"}}>social</h2> 
                      <p>email: ttm.sungaipadi@gmail.com</p> 
                      <p>facebook: https://www.facebook.com/thaipadi</p> 
                      <p>Line@: @thaipadi</p> 
                      <p>Line ID: 090 523 2588</p>
                      <div style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                      <a style={{marginRight:'20px'}} href="https://www.facebook.com/thaipadi" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://www.kan2.go.th/offline/images/ita/facebook-logo-3.png" alt="facebook fanpages" /></a> 
                      <a style={{marginRight:'20px'}} href="https://line.me/ti/p/JKSk2eP0-W" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" alt="line id" /></a> 
                      <a style={{marginRight:'20px'}} href="https://lin.ee/O3J5WsW" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="http://www.thaieasytools.com/wp-content/uploads/2021/01/line-oa.png" alt="line id" /></a> 
                      <a href="https://www.youtube.com/channel/UC-xQu6uZE9jZogeD4r4mB5A" target="_blank" rel="noreferrer" ><img style={{height:'30px'}} src="https://icons-for-free.com/download-icon-tube+video+you+youtube+icon-1320185153402885670_512.png" alt="line id" /></a> 
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="locationttm">
                <div>
                  <h2 style={{color:"white"}}>ข้อมูลโรงพยาบาล</h2>
                  <div>
                  <p>
                    โรงพยาบาลสุไหงปาดี เป็นโรงพยาบาลชุมชนที่อยู่ในเขตพื้นที่ อำเภอสุไหงปาดี จังหวัดนราธิวาส เปิดให้บริการด้านสุขภาพแก่ประชาชนในพื้นที่ โดยดำเนินการภายใต้กรอบนโยบาย
                    ที่กำหนดโดยกระทรวงสาธารณสุข และดำเนินการตามพันธกิจ 5 ด้านคือ 1.เป็นโรงพยาบาลชุมชนที่ให้บริการ ส่งเสริม ป้องกัน รักษา ฟื้นฟู แบบองค์รวม
                    2.พัฒนาระบบบริการให้ได้มาตรฐาน 3.พัฒนาบุคลากรให้มีคุณภาพ พัฒนางานด้วยนวัตกรรมและเสริมสร้างความสุขในการทำงาน 4.พัฒนาองค์กรให้มีระบบบริหารจัดการที่ดี
                    5.เสริมสร้างศักยภาพและความร่วมมือภาคีเครือข่ายในการดูแลสุขภาพประชาชน
                  </p>
                  </div>
                  <div>
                    <div>
                      <div>
                        <h2 style={{color:"white"}}>ที่ตั้ง</h2> 
                        <div>
                        <p>โรงพยาบาลสุไหงปาดี 306 ถ.จารุเสถียร ต.ปะลุรู อ.สุไหงปาดี จ.นราธิวาส 96140</p> 
                        </div>
                      </div>
                      <div>
                        <h2 style={{color:"white"}}>โทร.</h2> 
                        <p>073 651 169</p> 
                      </div>               
                    <div>
                      <h2 style={{color:"white"}}>social</h2> 
                      <p>website: https://www.sph.go.th/</p> 
                      <p>facebook: https://www.facebook.com/spdhos</p> 
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Aboutme;
