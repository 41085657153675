import * as React from "react";
import { useEffect } from "react";
import "../fronthome/Calendar.css";
import { Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import "./ondate.css";
import Appline from "./appline";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import One from "./one";

const Ondateline = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === "OK" && (role === "admin" || role === "root")) {
          // alert('login success')
        } else if (response.data.status === "OK" && (role !== "admin" || role !== "root")) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            navigate("/calendarusersline");
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            navigate("/loginline");
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);

  return (
    <div className="aboutpages" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CssBaseline />
      <Container>
        <Box>
          <Paper style={{ marginBottom: "10px", marginTop: "10px", paddingTop: "10px", border: "1px solid #e1932c" }}>
            <One />
          </Paper>
        </Box>
      </Container>
    </div>
  );
};
export default Ondateline;
