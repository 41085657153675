import axios from "axios";
import { useEffect,useState } from "react";
// import { useNavigate } from "react-router-dom";
import * as React from 'react';
import DatePicker from "react-date-picker";
import Footer from "../fronthome/footer/footer";
import { Box } from "@mui/system";
import {CssBaseline, Container} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import {
  TextField,
} from "@mui/material";
import Navback from "./Navbarback";



const Schedule = () => {
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const prefixtoken = localStorage.getItem("prefix");
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const schedule = "https://script.google.com/macros/s/AKfycbxspdCNuixRp_vSsSrJUbN7BozeUKxbDx-JaGqPy44BorKi9Hzk8wbdZyNg3LrhUMtF4g/exec?action=getUsers"
  const schedule2 = "https://script.google.com/macros/s/AKfycbzoOo07wrYQnLl3L69cnYjafwyg8C0x2JtNHSrjZtO695kCP-5KRSTBOoaweS3fdj83cw/exec?action=getUsers"
  const TN = localStorage.getItem("TN");


  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (
          response.data.status === "OK" &&
          (role === "admin" || role === "root")
        ) {
          // alert('login success')
        } else if (
          response.data.status === "OK" &&
          (role !== "admin" || role !== "root")
        ) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  const [startDate, setStartDate] = useState(new Date());
  const [dayname, setDayname] = useState("");

  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);
  

  const [ttmschedule, setTtmschedule] = useState([]);
  const [ttmschedule2, setTtmschedule2] = useState([]);
  const [scheduletoday, setScheduletoday] = useState([]);
  const [scheduletoday2, setScheduletoday2] = useState([]);





  useEffect(() => {
        const holiday = [{
          date: "ยังไม่กำหนดเวร",
          asuwa:"ยังไม่กำหนดเวร",
          arofat:"ยังไม่กำหนดเวร",
          aneera:"ยังไม่กำหนดเวร",
        }]
        const holiday2 = [{
          date: "ยังไม่กำหนดเวร",
          fateemoh: "ยังไม่กำหนดเวร",
          wanareena:"ยังไม่กำหนดเวร",
          nuraida:"ยังไม่กำหนดเวร",
          seeteefateemoh:"ยังไม่กำหนดเวร",
          abdulloh:"ยังไม่กำหนดเวร",
        }]
        axios.get(`${schedule}`).then((res) => {
          if((res.data.filter((e) => new Date(e.date).toLocaleDateString('th-TH') === startDate.toLocaleDateString('th-TH'))).length === 0){
            setScheduletoday(holiday)
            return
          }
          setScheduletoday(res.data.filter((e) => new Date(e.date).toLocaleDateString('th-TH') === startDate.toLocaleDateString('th-TH')));
        });
        axios.get(`${schedule2}`).then((res) => {
          if((res.data.filter((e) => new Date(e.date).toLocaleDateString('th-TH') === startDate.toLocaleDateString('th-TH'))).length === 0){
            setScheduletoday2(holiday2)
            return
          }
          setScheduletoday2(res.data.filter((e) => new Date(e.date).toLocaleDateString('th-TH') === startDate.toLocaleDateString('th-TH')));
        });
  }, [URL,startDate]);



  useEffect(() => {
    axios.get(`${schedule}`).then((res) => {
      setTtmschedule(res.data);
    });
    axios.get(`${schedule2}`).then((res) => {
      setTtmschedule2(res.data);
    });
  }, [URL,startDate]);




    const [searchschedule, setSearchschedule] = useState(ttmschedule);
    const [searchschedule2, setSearchschedule2] = useState(ttmschedule2);

    const [searchschedule3, setSearchschedule3] = useState(ttmschedule);
    const [searchschedule4, setSearchschedule4] = useState(ttmschedule2);
    
    const testttt = (e) => {
        return setSearchschedule(e.target.value);
    };
    const testtttt = (e) => {
        return setSearchschedule2(e.target.value);
    };


    const testttttt = (e) => {
      if(TN === "10" || TN === "12" || TN === "13"){
        return setSearchschedule3(e.target.value);
      }
      else{
        return setSearchschedule4(e.target.value);
      }
    };





    const checkttm = (check) =>{
      if(check === "ซักประวัติ"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#A8D08D",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "คีย์ข้อมูล"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#B4C6E7",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "งานเอกสาร"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#FFE599",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "หยุด"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#767171",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เวร"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#00FFFF",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "สกรีนเช้า"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#00B050",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "สกรีนบ่าย"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#F4656D",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "วันหยุดราชการ"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#767171",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else{
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#D9D9D9",fontFamily:'Athiti',fontWeight:'bold'}
      }
    }

    const checkttm2 = (check) =>{
      if(check === "หัวหน้าผู้ช่วย"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#F2C7CD",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เตรียมตู้อบสมุนไพร"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#FFAE92",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เตรียมลูกประคบ"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#DADD45",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เคลียตู้อบสมุนไพร"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#Fbd4ad",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เคลียลูกประคบ"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#A5B59C",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "หยุด"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#767171",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "เวร"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#00FFFF",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "สกรีนเช้า"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#00B050",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "สกรีนบ่าย"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#F4656D",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else if(check === "วันหยุดราชการ"){
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#767171",fontFamily:'Athiti',fontWeight:'bold'}
      }
      else{
        return {tableLayout:'fixed', width: "200px",height: "40px",backgroundColor: "#D9D9D9",fontFamily:'Athiti',fontWeight:'bold'}
      }
    }


    const arofattest=()=>{
        const nextdate = function (firstDate, secondDate) {
          if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
              return true;
            }
            return false;
        };
        const dayschedule = (day) => {
          if (day.getDay() === 0) {
            return "อาทิตย์"
          } else if (day.getDay() === 1) {
            return "จันทร์"
          } else if (day.getDay() === 2) {
            return "อังคาร"
          } else if (day.getDay() === 3) {
            return "พุธ"
          } else if (day.getDay() === 4) {
            return "พฤหัส"
          } else if (day.getDay() === 5) {
            return "ศุกร์"
          } else if (day.getDay() === 6) {
            return "เสาร์"
          }
        }
        if(TN === "13"){
          return(
            ttmschedule
            .filter((e) => {
              return (
                e.date.includes(searchschedule3) ||
                e.asuwa.includes(searchschedule3)
              );
            })
            .filter((e) => nextdate(new Date(),new Date(e.date)))
            .map((item)=>{
              return(
                <tr key={uuidv4()}>
                  <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                  <th style={checkttm(item.asuwa)}>{item.asuwa}</th>
                </tr>
              )
            })
            )
        }
        else if(TN === "10"){
          return(
            ttmschedule
            .filter((e) => {
              return (
                e.date.includes(searchschedule3) ||
                e.arofat.includes(searchschedule3)
              );
            })
            .filter((e) => nextdate(new Date(),new Date(e.date)))
            .map((item)=>{
              return(
                <tr key={uuidv4()}>
                  <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                  <th style={checkttm(item.arofat)}>{item.arofat}</th>
                </tr>
              )
            })
            )
        }
        else if(TN === "12"){
          return(
            ttmschedule
            .filter((e) => {
              return (
                e.date.includes(searchschedule3) ||
                e.aneera.includes(searchschedule3)
              );
            })
            .filter((e) => nextdate(new Date(),new Date(e.date)))
            .map((item)=>{
              return(
                <tr key={uuidv4()}>
                  <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                  <th style={checkttm(item.aneera)}>{item.aneera}</th>
                </tr>
              )
            })
            )
        }
        else if(TN === "123797"){
          return(
              ttmschedule2
              .filter((e) => {
                return (
                  e.date.includes(searchschedule4) ||
                  e.fateemoh.includes(searchschedule4)
                );
              })
              .filter((e) => nextdate(new Date(),new Date(e.date)))
              .filter((e) => new Date(e.date).toLocaleDateString('th-TH') >= new Date().toLocaleDateString('th-TH'))
              .map((item)=>{
                return(
                  <tr key={uuidv4()}>
                    <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                    <th style={checkttm2(item.fateemoh)}>{item.fateemoh}</th>
                  </tr>
                )
              })
              )
        }
        else if(TN === "123796"){
          return(
              ttmschedule2
              .filter((e) => {
                return (
                  e.date.includes(searchschedule4) ||
                  e.wanareena.includes(searchschedule4)
                );
              })
              .filter((e) => nextdate(new Date(),new Date(e.date)))
              .map((item)=>{
                return(
                  <tr key={uuidv4()}>
                    <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                    <th style={checkttm2(item.wanareena)}>{item.wanareena}</th>
                  </tr>
                )
              })
              )
        }
        else if(TN === "123800"){
          return(
              ttmschedule2
              .filter((e) => {
                return (
                  e.date.includes(searchschedule4) ||
                  e.nuraida.includes(searchschedule4)
                );
              })
              .filter((e) => nextdate(new Date(),new Date(e.date)))
              .map((item)=>{
                return(
                  <tr key={uuidv4()}>
                    <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                    <th style={checkttm2(item.nuraida)}>{item.nuraida}</th>
                  </tr>
                )
              })
              )
        }
        else if(TN === "123798"){
          return(
              ttmschedule2
              .filter((e) => {
                return (
                  e.date.includes(searchschedule4) ||
                  e.seeteefateemoh.includes(searchschedule4)
                );
              })
              .filter((e) => nextdate(new Date(),new Date(e.date)))
              .map((item)=>{
                return(
                  <tr key={uuidv4()}>
                    <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                    <th style={checkttm2(item.seeteefateemoh)}>{item.seeteefateemoh}</th>
                  </tr>
                )
              })
              )
        }
        else if(TN === "123799"){
          return(
              ttmschedule2
              .filter((e) => {
                return (
                  e.date.includes(searchschedule4) ||
                  e.abdulloh.includes(searchschedule4)
                );
              })
              .filter((e) => nextdate(new Date(),new Date(e.date)))
              .map((item)=>{
                return(
                  <tr key={uuidv4()}>
                    <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                    <th style={checkttm2(item.abdulloh)}>{item.abdulloh}</th>
                  </tr>
                )
              })
              )
        }
        else{
          return
        }
      }


    const dateInPast = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }
        return false;
    };

    const nextdate = function (firstDate, secondDate) {
      if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }
        return false;
    };

    const dayschedule = (day) => {
      if (day.getDay() === 0) {
        return "อาทิตย์"
      } else if (day.getDay() === 1) {
        return "จันทร์"
      } else if (day.getDay() === 2) {
        return "อังคาร"
      } else if (day.getDay() === 3) {
        return "พุธ"
      } else if (day.getDay() === 4) {
        return "พฤหัส"
      } else if (day.getDay() === 5) {
        return "ศุกร์"
      } else if (day.getDay() === 6) {
        return "เสาร์"
      }
    }


  return (
    <div>
      <Navback />
      <CssBaseline />
      <Container maxWidth="xl" >
        <Box>
          <Paper style={{marginBottom:'30px',marginTop:'100px'}}>
          <div style={{ textAlign: "center" }}>
            <h2 className="scheduletop">
              <span style={{ marginRight: "10px"}}>
                เวรประจำ <span style={{textDecoration:'underline'}}>{dayname}</span> ที่
              </span>
              <DatePicker
                onChange={(Date) => setStartDate(Date)}
                value={startDate}
                format="dd-MM-y"
                locale="th-TH"
                calendarClassName="calendarclass"
                className="picker"
              />
            </h2>
          </div>
            <TableContainer style={{marginBottom:"10px"}}>
            <Table>
              <thead className="scheduletophead">
                <tr>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}} >อาซูวา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>อารอฟัต</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>อานีรา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>ฟาตีเม๊าะ</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>วันอารีนา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>นูรไอดา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>สีตีฟาตีเมาะ</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>ตำแหน่งว่าง</th>
                </tr>
              </thead>
              <tbody className="scheduletopbody">
                    {scheduletoday
                        .map((item) => {
                          if(dateInPast(startDate,new Date())){
                            return (
                              <tr key={uuidv4()}>
                                    <td colSpan="8" style={{backgroundColor:'#767171'}}>วันที่ผ่านไปแล้ว</td>
                              </tr>
                            )                            
                          }
                          else{
                            if(item.asuwa === "วันหยุดราชการ"){
                              return (
                                <tr key={uuidv4()}>
                                      <td colSpan="8" style={{backgroundColor:'#767171'}} >วันหยุดราชการหรืออื่นๆ</td>
                                </tr>
                              )
                            }
                            else{
                            return (
                              <tr key={uuidv4()}>
                                <td style={checkttm(item.asuwa)}>{item.asuwa}</td>
                                <td style={checkttm(item.arofat)}>{item.arofat}</td>
                                <td style={checkttm(item.aneera)}>{item.aneera}</td>
                      {scheduletoday2
                          .map((item) => {
                            return (
                                <td key={uuidv4()} style={checkttm2(item.fateemoh)}>{item.fateemoh}</td>
                            );
                          })}
                      {scheduletoday2
                          .map((item) => {
                            return (
                                <td key={uuidv4()} style={checkttm2(item.wanareena)}>{item.wanareena}</td>
                            );
                          })}
                      {scheduletoday2
                          .map((item) => {
                            return (
                                <td key={uuidv4()} style={checkttm2(item.nuraida)}>{item.nuraida}</td>
                            );
                          })}
                      {scheduletoday2
                          .map((item) => {
                            return (
                                <td key={uuidv4()} style={checkttm2(item.seeteefateemoh)}>{item.seeteefateemoh}</td>
                            );
                          })}
                      {scheduletoday2
                          .map((item) => {
                            return (
                                <td key={uuidv4()} style={checkttm2(item.abdulloh)}>{item.abdulloh}</td>
                            );
                          })}
                              </tr>
                            )}
                          };
                        })}
              </tbody>
            </Table>
            </TableContainer>
          </Paper>
          <Paper style={{marginBottom:'30px',marginTop:'10px'}}>
            <div
              style={{
                marginTop: "4px",
                marginBottom:'10px',
              }}
            >
              <div>
              <h2 style={{textAlign:'center'}}>ตารางเวร</h2>
              <h2 className="scheduletop" style={{textAlign:'center'}}>{prefixtoken}{fnametoken} {lnametoken}</h2>
              <div style={{textAlign:'center'}}>
              <TextField
                  id="fullWidth"
                  label="ค้นหา..."
                  align="center"
                  variant="outlined"
                  onChange={testttttt}
                  size="small"
                />
              </div>
              </div>
            </div>
            <TableContainer sx={{ height: "600px"}} style={{marginBottom:"10px"}}>
            <Table stickyHeader aria-label="sticky table" style={{marginBottom:"10px"}}>
              <thead className="scheduletophead">
                <tr>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>วันที่</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>หน้าที่</th>
                </tr>
              </thead>
              <tbody className="passlist" >
                  {arofattest()}
              </tbody>
            </Table>
            </TableContainer>
          </Paper>
          <Paper style={{marginBottom:'30px',marginTop:'10px'}}>
          <div
              style={{
                marginTop: "4px",
                marginBottom:'10px',
              }}
            >
              <h1 style={{textAlign:'center',margin:'10px',fontSize:'24px'}}>ตารางเวรแพทย์แผนไทย</h1>
              <div style={{textAlign:'center'}}>
                <TextField
                  id="fullWidth"
                  label="ค้นหาเวร..."
                  align="center"
                  variant="outlined"
                  onChange={testttt}
                  size="small"
                />
              </div>
            </div>
            <TableContainer sx={{ height: "600px"}} style={{marginBottom:"10px"}}>
            <Table stickyHeader aria-label="sticky table" style={{marginBottom:"10px"}}>
              <thead className="scheduletophead">
                <tr>
                  <th style={{tableLayout:'fixed', width: "250px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>วันที่</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>อาซูวา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>อารอฟัต</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>อานีรา</th>
                </tr>
              </thead>
              <tbody className="passlist">
                    {ttmschedule
                                                        .filter((e) => {
                                                          return (
                                                            e.date.includes(searchschedule)||
                                                            e.arofat.includes(searchschedule)||
                                                            e.asuwa.includes(searchschedule)||
                                                            e.aneera.includes(searchschedule)
                                                          );
                                                        })
                                                        .filter((e) => nextdate(new Date(),new Date(e.date)))
                        .map((item) => {
                          return (
                            <tr key={uuidv4()}>
                              <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                              <th style={checkttm(item.asuwa)}>{item.asuwa}</th>
                              <th style={checkttm(item.arofat)}>{item.arofat}</th>
                              <th style={checkttm(item.aneera)}>{item.aneera}</th>
                            </tr>
                          );
                        })}
              </tbody>
            </Table>
            </TableContainer>
          </Paper>
          <Paper style={{marginBottom:'100px',marginTop:'10px'}}>
          <div
              style={{
                marginTop: "4px",
                marginBottom:'10px',
              }}
            >
              <h1 style={{textAlign:'center',margin:'10px',fontSize:'24px'}}>ตารางเวรผู้ช่วยแพทย์แผนไทย</h1>
              <div style={{textAlign:'center'}}>
                <TextField
                  id="fullWidth"
                  label="ค้นหาเวร..."
                  align="center"
                  variant="outlined"
                  onChange={testtttt}
                  size="small"
                />
              </div>
            </div>
            <TableContainer sx={{ height: "600px"}} style={{marginBottom:"10px"}}>
            <Table stickyHeader aria-label="sticky table" style={{marginBottom:"10px"}}>
              <thead className="scheduletophead">
                <tr>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>วันที่</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>ฟาตีเม๊าะ</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>วันอารีนา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>นูรไอดา</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>สีตีฟาตีเมาะ</th>
                  <th style={{tableLayout:'fixed', width: "200px",height: "60px",backgroundColor: "#1bb3b3",fontFamily:'Athiti',fontWeight:'bold'}}>ตำแหน่งว่าง</th>
                </tr>
              </thead>
              <tbody className="passlist">
                    {ttmschedule2
                                                        .filter((e) => {
                                                          return (
                                                            e.date.includes(searchschedule2)||
                                                            e.fateemoh.includes(searchschedule2)||
                                                            e.wanareena.includes(searchschedule2)||
                                                            e.nuraida.includes(searchschedule2)||
                                                            e.seeteefateemoh.includes(searchschedule2)||
                                                            e.abdulloh.includes(searchschedule2)
                                                          );
                                                        })
                                                        .filter((e) => nextdate(new Date(),new Date(e.date)))
                        .map((item) => {
                          return (
                            <tr key={uuidv4()}>
                              <th><div>{dayschedule(new Date(item.date))}</div>{new Date(item.date).toLocaleDateString('th-TH')}</th>
                              <th style={checkttm2(item.fateemoh)}>{item.fateemoh}</th>
                              <th style={checkttm2(item.wanareena)}>{item.wanareena}</th>
                              <th style={checkttm2(item.nuraida)}>{item.nuraida}</th>
                              <th style={checkttm2(item.seeteefateemoh)}>{item.seeteefateemoh}</th>
                              <th style={checkttm2(item.abdulloh)}>{item.abdulloh}</th>
                            </tr>
                          );
                        })}
              </tbody>
            </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
      <Footer/>
    </div>
  );
};
export default Schedule;
