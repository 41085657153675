import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import { v4 as uuidv4 } from "uuid";
import "../backhome/ondate.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TextField,
} from "@mui/material";


export default function Searchhistory() {

  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  
  const getdata = async () => {
    try {
      const appoinment = [{name:"appoinment",id:'1'},{name:"appoinments",id:'2'},{name:"appoinmentss",id:'3'},{name:"appoinmentsss",id:'4'},{name:"appoinmentssss",id:'5'},{name:"appoinmentsssss",id:'6'},{name:"passconfirmapp",id:'7'},{name:"passblockapp",id:'8'}];
      const api = appoinment.map((item) => {
        return `${URL}/${item.name}`;
      });
      const [res1, res2, res3, res4, res5, res6, res7, res8] = await Promise.all([axios.get(api[0]), axios.get(api[1]), axios.get(api[2]), axios.get(api[3]), axios.get(api[4]), axios.get(api[5]), axios.get(api[6]), axios.get(api[7])]);
      setAppdata(
        res1.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatas(
        res2.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatass(
        res3.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasss(
        res4.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatassss(
        res5.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasssss(
        res6.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setPassconfirmapp(
        res7.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setPassblockapp(
        res8.data.filter((e) => {
          return e.TN > 9;
        })
      );

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata()
  }, [URL]);

  const [appdata, setAppdata] = useState([]);
  const [appdatas, setAppdatas] = useState([]);
  const [appdatass, setAppdatass] = useState([]);
  const [appdatasss, setAppdatasss] = useState([]);
  const [appdatassss, setAppdatassss] = useState([]);
  const [appdatasssss, setAppdatasssss] = useState([]);
  const [passconfirmapp, setPassconfirmapp] = useState([]);
  const [passblockapp, setPassblockapp] = useState([]);

  const appalldata = [
    ...appdata,
    ...appdatas,
    ...appdatass,
    ...appdatasss,
    ...appdatassss,
    ...appdatasssss,
    ...passconfirmapp,
    ...passblockapp,
  ];

  const [appsearch, setAppsearch] = useState(null);
  const apptesttt = (e) => {
    if (e.target.value === "") {
      return setAppsearch(null);
    } else {
      return setAppsearch(e.target.value);
    }
  };

  return (
    <div style={{marginTop:'10px'}}>
        <Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <h1>ค้นหานัดหมายผู้รับบริการ</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={apptesttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "200px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackdate"
                    >
                      วันที่
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "50px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbacktime"
                    >
                      เวลา
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        maxWidth: "90px",
                        padding: "0 0 0 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackname"
                    >
                      ชื่อ-สกุล
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appalldata
                    .filter((e) => {
                      return (
                        e.fname.includes(appsearch) ||
                        e.lname.includes(appsearch) ||
                        e.TN.toString().includes(appsearch) ||
                        e.tel.includes(appsearch)
                      );
                    })
                    .map((item) => {
                      return (
                        <TableRow key={uuidv4()} className="searchusersrow">
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 1px 5px 1px",
                            }}
                            // className="searchbackdate"
                          >
                            {item.date}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "40px",
                              padding: "5px 0 5px 0",
                            }}
                            // className="searchbacktime"
                          >
                            {item.time} น.
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              maxWidth: "90px",
                              maxHeight: "10px",
                              padding: "0 0 0 10px",
                            }}
                            // className="searchbackname"
                          >
                            {item.prefix}
                            {item.fname} {item.lname}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
    </div>
  );
};
