import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { Button, CssBaseline, Container, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Cancelapp from "./Cancelapp";

const Cancelappgroupline = () => {
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const navigate = useNavigate();
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "กรุณาเข้าสู่ระบบก่อนใช้งาน",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.removeItem("fname");
            localStorage.removeItem("lname");
            localStorage.removeItem("TN");
            localStorage.removeItem("role");
            localStorage.removeItem("prefix");
            localStorage.removeItem("uuid");
            navigate("/logingroupline");
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);

  //สลับหน้า
  const move = () => {
    navigate("/calendargroupline");
  };
  //สลับหน้า

  //ออกจากระบบ
  const movelogout = () => {
    Swal.fire({
      icon: "info",
      title: "<strong>แจ้งเตือน</strong>",
      html: "<h4>ท่านต้องการออกจากระบบใช่หรือไม่</h4>",
      showCancelButton: true,
      confirmButtonColor: "error",
      cancelButtonColor: "warning",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ออกจากระบบ",
    }).then((result) => {
      if (result.isConfirmed) {
        clearpage();
      }
    });
    return;
  };
  const clearpage = () => {
    localStorage.clear();
    navigate("/calendarusersgroupline");
  };
  //ออกจากระบบ

  return (
    <div className="aboutpages" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Container>
        <CssBaseline />
        <Box>
          <Paper style={{ marginBottom: "10px", marginTop: "10px", paddingTop: "10px", border: "1px solid #e1932c" }}>
            <div style={{ textAlign: "right", marginRight: "10px" }}>
              <Button variant="contained" color="secondary" size="small" onClick={move} className="btnstatus" style={{ marginRight: "10px" }}>
                <p>ปฏิทินนัด</p>
              </Button>
              <Button variant="contained" color="error" size="small" onClick={movelogout} className="btnstatus">
                <p>ออกจากระบบ</p>
              </Button>
            </div>
            <Cancelapp/>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default Cancelappgroupline;
