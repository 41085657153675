import * as React from "react";
import Paper from "@mui/material/Paper";
import Navback from "./Navbarback";
import Footer from "../fronthome/footer/footer";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import axios from "axios";
import { Button, Container, TextField, Grid, CssBaseline } from "@mui/material";
import { DeleteForever, AddCircle } from "@mui/icons-material";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./users.css";
import Swal from "sweetalert2";

const styleeeee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function Holidays() {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  //ตรวจสอบ role
  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK" && (role === "admin" || role === "root")) {
          // alert('login success')
        } else if (response.data.status === "OK" && (role !== "admin" || role !== "root")) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  //ตรวจสอบrole

  //ดึงข้อมูลวันหยุด

  const [holidays, setHolidays] = useState([]);

  const UsersGet = () => {
    axios.get(`${URL}/holidays`).then((res) => {
      setHolidays(res.data);
    });
  };

  useEffect(() => {
    UsersGet();
  }, [URL]);

  //ดึงข้อuser

  //การลบข้อมูล

  const deleteholiday = (DN, dateget) => {
    Swal.fire({
      title: "แจ้งเตือน",
      text: "ยืนยันการยกเลิกวันหยุดราชการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteappdata(dateget);
        deleteholidaydata(DN);
      }
    });
  };

  function deleteholidaydata(DN) {
    var data = JSON.stringify({
      DN: DN,
    });

    var config = {
      method: "delete",
      url: `${URL}/holidays`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            icon: "success",
            title: "เสร็จสิ้น",
            text: "ทำการลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 2000,
          }).then((result) => {
            UsersGet();
            return;
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const deleteappdata = (dateget) => {
    var data = JSON.stringify({
      date: dateget,
    });

    var config = {
      method: "delete",
      url: `${URL}/appholidays`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const cancelappdata = () => {

  //   var data = JSON.stringify({
  //     AN: AN,
  //   });

  //   var config = {
  //     method: "delete",
  //     url: `${URL}/clearwomen`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "เสร็จสิ้น",
  //         text: "ทำการยกเลิกเรียบร้อย",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       }).then((result) => {
  //         recheck91();
  //         recheck92();
  //         recheck93();
  //         recheck94();
  //         recheck101();
  //         recheck102();
  //         recheck103();
  //         recheck104();
  //         recheck131();
  //         recheck132();
  //         recheck133();
  //         recheck134();
  //         recheck141();
  //         recheck142();
  //         recheck143();
  //         recheck144();
  //         recheck95();
  //         recheck105();
  //         recheck135();
  //         recheck145();
  //         ldataall();
  //         ldatas();
  //         ldatass();
  //         ldatasss();
  //         appdatafive()
  //         ldataman();
  //         appdataone()
  //         appdatatwo()
  //         appdatatree()
  //         appdatafour()
  //       });
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const [search, setSearch] = useState("");

  const testtt = (e) => {
    return setSearch(e.target.value);
  };
  //การลบข้อมูล

  const [openholiday, setOpenholiday] = useState(false);

  const handleOpenholiday = () => setOpenholiday(true);
  const handleCloseholiday = () => setOpenholiday(false);

  //addholiday
  const [holiday, setHoliday] = useState(new Date());

  const submitform = (event) => {
    event.preventDefault();

    const bbb = holiday.getDate() + "-" + (holiday.getMonth() + 1) + "-" + holiday.getFullYear();

    var data = JSON.stringify({
      date: holiday.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
      dateget: bbb,
    });
    var config = {
      method: "post",
      url: `${URL}/holidays`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.status));
        if (response.data["status"] === "OK") {
          addmanaappoinment91();
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "เพิ่มข้อมูลวันหยุดผิดพลาด",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            return;
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addmanaappoinment91 = () => {
    const bbb = holiday.getDate() + "-" + (holiday.getMonth() + 1) + "-" + holiday.getFullYear();
    var data = JSON.stringify({
      TN: 7,
      date: bbb,
      time: "09:00",
      service: "วันหยุดราชการหรืออื่นๆ",
      provider: "หญิง",
      dateapp: holiday
    });

    var config = {
      method: "post",
      url: `${URL}/appoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "เพิ่มวันหยุดราชการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          UsersGet();
          setOpenholiday(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };
  //addholiday

  return (
    <>
      <Navback />
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Paper style={{ marginTop: "100px", marginBottom: "65px" }}>
          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
            <Grid item xs={5} style={{ marginLeft: "10px", marginTop: "3px" }}>
              <TextField id="outlined-basic" label="ค้นหา..." variant="outlined" size="small" onChange={testtt} fullWidth />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" color="success" size="large" startIcon={<AddCircle />} onClick={handleOpenholiday}>
                เพิ่มวันหยุดราชการ
              </Button>
              <Modal open={openholiday} onClose={handleCloseholiday} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={styleeeee}>
                  <Paper>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 2 }}>
                      <Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={16} sm={16}>
                            <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                              กำหนดวันหยุดราชการ
                            </Typography>
                          </Grid>
                          <Grid item xs={16} sm={16} style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                            <DatePicker onChange={(Date) => setHoliday(Date)} value={holiday} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                          </Grid>
                          <Grid item xs={8}>
                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={submitform}>
                              ยืนยัน
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button fullWidth variant="contained" color="error" onClick={handleCloseholiday}>
                              ยกเลิก
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Container>
                  </Paper>
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <div>
            <table>
              <thead>
                <tr className="head">
                  <th className="headusername">รายละเอียดวันหยุด</th>
                  <th className="headuseraction">สถานะ</th>
                </tr>
              </thead>
              <tbody className="users">
                {holidays
                  .filter((e) => {
                    return e.date.includes(search);
                  })
                  .map((item) => {
                    return (
                      <tr key={item.DN}>
                        <td style={{ textAlign: "left" }}>{item.date}</td>
                        <td align="center" className="bodyuseraction">
                          <Button variant="contained" color="error" size="small" className="btnstatus" onClick={() => deleteholiday(item.DN, item.dateget)} startIcon={<DeleteForever />}>
                            ลบข้อมูล
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <Grid container spacing={2} style={{ marginBottom: "16px" }}>
            <Grid item xs={5} style={{ marginLeft: "10px", marginTop: "20px", marginBottom: "20px" }}>
              <TextField id="outlined-basics" label="ค้นหา..." variant="outlined" size="small" onChange={testtt} fullWidth />
            </Grid>
            <Grid item xs={6} textAlign="right" style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Button variant="contained" color="success" size="large" startIcon={<AddCircle />} onClick={handleOpenholiday}>
                เพิ่มวันหยุดราชการ
              </Button>
              <Modal open={openholiday} onClose={handleCloseholiday} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={styleeeee}>
                  <Paper>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 2 }}>
                      <Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={16} sm={16}>
                            <Typography variant="h5" component="h1" style={{ textAlign: "center" }}>
                              กำหนดวันหยุดราชการ
                            </Typography>
                          </Grid>
                          <Grid item xs={16} sm={16} style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                            <DatePicker onChange={(Date) => setHoliday(Date)} value={holiday} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                          </Grid>
                          <Grid item xs={8}>
                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={submitform}>
                              ยืนยัน
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button fullWidth variant="contained" color="error" onClick={handleCloseholiday}>
                              ยกเลิก
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Container>
                  </Paper>
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

// onSubmit={editform}
// onChange={prefix}
// onChange={(event)=>{setFname(event.target.value)}}
// onChange={(event)=>{setLname(event.target.value)}}
// onChange={(event)=>{setTNN(event.target.value)}}
// onChange={(event)=>{setRole(event.target.value)}}
// onChange={(event)=>{setTel(event.target.value)}}
