import "./Calendar.css";
import * as React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./footer/footer";
import Appcalendar from "./AppCalendar";
import { Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect} from "react";
import axios from "axios";
import Swal from "sweetalert2";


const Calendar = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const navigate = useNavigate();
  const tntoken = localStorage.getItem("TN");

  const checkrole = ()=>{
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          axios.get(`${URL}/user/${tntoken}`).then((res) => {
            localStorage.setItem("role", res.data[0].role);
            return
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "กรุณาเข้าสู่ระบบก่อนใช้งาน",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            navigate("/login");
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    checkrole()
  }, []);


  return (
    <div>
      <Navbar />
      <CssBaseline />
      <Container>
      <Box>
        <Paper style={{ marginTop: "100px" , marginBottom:"80px" }}>
            <Appcalendar/>
        </Paper>
      </Box>
      </Container>
      <Footer/>
    </div>
  );
};
export default Calendar;
