import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import * as React from "react";
import Navback from "./Navbarback";
import Footer from "../fronthome/footer/footer";
import { Box } from "@mui/system";
import { CssBaseline, Container } from "@mui/material";
import Swal from "sweetalert2";
import { Modal } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DeleteForever, Send } from "@mui/icons-material/";
import { TextField, Grid, Typography } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const Officerpc = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const URLofficer = "https://apiphp.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK" && (role === "admin" || role === "root")) {
          // alert('login success')
        } else if (response.data.status === "OK" && (role !== "admin" || role !== "root")) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  //ดึงรายชื่อ
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${URLofficer}/users`).then((res) => {
      setData(res.data);
    });
  }, [URL, URLofficer]);

  const [search, setSearch] = useState("");
  const testtt = (e) => {
    return setSearch(e.target.value);
  };

  const [datajob, setDatajob] = useState([]);
  const [arofatjob, setArofatjob] = useState([]);
  const [aneerajob, setAneerajob] = useState([]);
  const [asuwajob, setAsuwajob] = useState([]);
  const [julapitjob, setJulapitjob] = useState([]);
  const [fateemohjob, setFateemohjob] = useState([]);
  const [wanareenajob, setWanareenajob] = useState([]);
  const [nuraidajob, setNuraidajob] = useState([]);
  const [seeteefateemohjob, setSeeteefateemohjob] = useState([]);
  const [abdullohjob, setAbdullohjob] = useState([]);

  const getjob = () => {
    axios.get(`${URLofficer}/job`).then((res) => {
      setDatajob(res.data);
    });
    axios.get(`${URLofficer}/jobarofat`).then((res) => {
      setArofatjob(res.data);
    });
    axios.get(`${URLofficer}/jobaneera`).then((res) => {
      setAneerajob(res.data);
    });
    axios.get(`${URLofficer}/jobasuwa`).then((res) => {
      setAsuwajob(res.data);
    });
    axios.get(`${URLofficer}/jobjulapit`).then((res) => {
      setJulapitjob(res.data);
    });
    axios.get(`${URLofficer}/jobfateemoh`).then((res) => {
      setFateemohjob(res.data);
    });
    axios.get(`${URLofficer}/jobwanareena`).then((res) => {
      setWanareenajob(res.data);
    });
    axios.get(`${URLofficer}/jobnuraida`).then((res) => {
      setNuraidajob(res.data);
    });
    axios.get(`${URLofficer}/jobseeteefateemoh`).then((res) => {
      setSeeteefateemohjob(res.data);
    });
    axios.get(`${URLofficer}/jobabdulloh`).then((res) => {
      setAbdullohjob(res.data);
    });
  };

  useEffect(() => {
    getjob();
  }, [URL, URLofficer]);

  const [searchjob, setSearchjob] = useState("");
  const job = (e) => {
    return setSearchjob(e.target.value);
  };

  const [julapitsearchjob, setJulapitsearchjob] = useState("");
  const julapit = (e) => {
    return setJulapitsearchjob(e.target.value);
  };

  const [arofatsearchjob, setArofatsearchjob] = useState("");
  const arofat = (e) => {
    return setArofatsearchjob(e.target.value);
  };

  const [aneerasearchjob, setAneerasearchjob] = useState("");
  const aneera = (e) => {
    return setAneerasearchjob(e.target.value);
  };

  const [asuwasearchjob, setAsuwasearchjob] = useState("");
  const asuwa = (e) => {
    return setAsuwasearchjob(e.target.value);
  };

  const [fateemohsearchjob, setFateemohsearchjob] = useState("");
  const fateemoh = (e) => {
    return setFateemohsearchjob(e.target.value);
  };

  const [wanareenasearchjob, setWanareenasearchjob] = useState("");
  const wanareena = (e) => {
    return setWanareenasearchjob(e.target.value);
  };

  const [nuraidasearchjob, setNuraidasearchjob] = useState("");
  const nuraida = (e) => {
    return setNuraidasearchjob(e.target.value);
  };

  const [seeteefateemohsearchjob, setSeeteefateemohsearchjob] = useState("");
  const seeteefateemoh = (e) => {
    return setSeeteefateemohsearchjob(e.target.value);
  };

  const [abdullohsearchjob, setAbdullohsearchjob] = useState("");
  const abdulloh = (e) => {
    return setAbdullohsearchjob(e.target.value);
  };

  //ใบลาพักผ่อน
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selecttnrest, setSelecttnrest] = useState("");
  const [selectnamerest, setSelectnamerest] = useState("");
  const [agent, setAgent] = useState("");
  const [agentposition, setAgentposition] = useState("");
  const [openrest, setOpenrest] = useState(false);
  const [dayrestworking, setDayrestworking] = useState(false);
  // const [currentday, setCurrentday] = useState("");
  // const [currentmonth, setCurrentmonth] = useState("");
  // const [currentyear, setCurrentyear] = useState("");

  const setStartdateDate = (date) => {
    setStartDate(date);
    setStartjob(date);
    setCausejob("ลาพักผ่อน");
  };

  const setEnddateDate = (date) => {
    setEndDate(date);
    setEndjob(date);
    setCausejob("ลาพักผ่อน");
  };

  const selectpatientrest = (TN, prefix, fname, lname) => {
    setSelecttnrest(TN);
    setSelectnamerest(`${prefix}${fname} ${lname}`);
    setSelecttnjob(TN);
    setSelectnamejob(`${prefix}${fname} ${lname}`);
    setCausejob("ลาพักผ่อน");
  };

  const handleChangedayrestworking = (event) => {
    setDayrestworking(event.target.value);
    setCausejob("ลาพักผ่อน");
  };

  const handleChangeagent = (event) => {
    setAgent(event.target.value);
    setCausejob("ลาพักผ่อน");
  };

  const handleChangeagentposition = (event) => {
    setAgentposition(event.target.value);
    setCausejob("ลาพักผ่อน");
  };

  const handleOpenrest = () => setOpenrest(true);
  const handleCloserest = () => setOpenrest(false);

  //ตรวจสอบข้อมูล
  const printrest = () => {
    const datestart = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
    const dateend = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate();

    var date1 = new Date(datestart);
    var date2 = new Date(dateend);

    var diffTime = date2.getTime() - date1.getTime();
    var diffDay = diffTime / (1000 * 3600 * 24);

    const today = new Date().toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" });
    const spittoday = today.split(" ");
    if (!selecttnrest || !dayrestworking || !agent || !agentposition) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      var data = JSON.stringify({
        TN: selecttnrest,
        since: startDate.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        until: endDate.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        day: diffDay + 1,
        workingday: dayrestworking,
        agent: agent,
        agentposition: agentposition,
        currentday: spittoday[0],
        currentmonth: spittoday[1],
        currentyear: spittoday[2],
      });

      var config = {
        method: "post",
        url: `${URLofficer}/rest`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.href = "https://php.thaipadi.org/rest.php";
          handleCloserest();
          printjob();
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };
  //ตรวจสอบข้อมูล
  //ใบลาพักผ่อน

  //ใบลาป่วย
  const [startsick, setStartsick] = useState(new Date());
  const [endsick, setEndsick] = useState(new Date());
  const [selecttnsick, setSelecttnsick] = useState("");
  const [selectnamesick, setSelectnamesick] = useState("");
  const [opensick, setOpensick] = useState(false);
  const [causesick, setCausesick] = useState("");

  const setStartdatesick = (date) => {
    setStartsick(date);
    setStartjob(date);
  };

  const setEnddatesick = (date) => {
    setEndsick(date);
    setEndjob(date);
  };

  const selectpatientsick = (TN, prefix, fname, lname) => {
    setSelecttnsick(TN);
    setSelectnamesick(`${prefix}${fname} ${lname}`);
    setSelecttnjob(TN);
    setSelectnamejob(`${prefix}${fname} ${lname}`);
  };

  const handleChangecausesick = (event) => {
    setCausesick(event.target.value);
    setCausejob("ลาป่วย");
  };

  const handleOpensick = () => setOpensick(true);
  const handleClosesick = () => setOpensick(false);

  //ตรวจสอบข้อมูล
  const printsick = () => {
    const datestart = startsick.getFullYear() + "-" + (startsick.getMonth() + 1) + "-" + startsick.getDate();
    const dateend = endsick.getFullYear() + "-" + (endsick.getMonth() + 1) + "-" + endsick.getDate();

    var date1 = new Date(datestart);
    var date2 = new Date(dateend);

    var diffTime = date2.getTime() - date1.getTime();
    var diffDay = diffTime / (1000 * 3600 * 24);

    const today = new Date().toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" });
    const spittoday = today.split(" ");
    if (!selecttnsick || !causesick) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      var data = JSON.stringify({
        TN: selecttnsick,
        since: startsick.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        until: endsick.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        cause: causesick,
        day: diffDay + 1,
        currentday: spittoday[0],
        currentmonth: spittoday[1],
        currentyear: spittoday[2],
      });

      var config = {
        method: "post",
        url: `${URLofficer}/sick`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.href = "https://php.thaipadi.org/sickk.php";
          printjob();
          handleClosesick();
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };
  //ตรวจสอบข้อมูล
  //ใบลาป่วย

  //ใบลากิจ
  const [startmission, setStartmission] = useState(new Date());
  const [endmission, setEndmission] = useState(new Date());
  const [selecttnmission, setSelecttnmission] = useState("");
  const [selectnamemission, setSelectnamemission] = useState("");
  const [openmission, setOpenmission] = useState(false);
  const [causemission, setCausemission] = useState("");

  const setStartdatemission = (date) => {
    setStartmission(date);
    setStartjob(date);
  };

  const setEnddatemission = (date) => {
    setEndmission(date);
    setEndjob(date);
  };

  const selectpatientmission = (TN, prefix, fname, lname) => {
    setSelecttnmission(TN);
    setSelectnamemission(`${prefix}${fname} ${lname}`);
    setSelecttnjob(TN);
    setSelectnamejob(`${prefix}${fname} ${lname}`);
  };

  const handleChangecausemission = (event) => {
    setCausejob("ลากิจ");
    setCausemission(event.target.value);
  };

  const handleOpenmission = () => setOpenmission(true);
  const handleClosemission = () => setOpenmission(false);

  //ตรวจสอบข้อมูล
  const printmission = () => {
    const datestart = startmission.getFullYear() + "-" + (startmission.getMonth() + 1) + "-" + startmission.getDate();
    const dateend = endmission.getFullYear() + "-" + (endmission.getMonth() + 1) + "-" + endmission.getDate();

    var date1 = new Date(datestart);
    var date2 = new Date(dateend);

    var diffTime = date2.getTime() - date1.getTime();
    var diffDay = diffTime / (1000 * 3600 * 24);

    const today = new Date().toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" });
    const spittoday = today.split(" ");
    if (!selecttnmission || !causemission) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      var data = JSON.stringify({
        TN: selecttnmission,
        since: startmission.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        until: endmission.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
        cause: causemission,
        day: diffDay + 1,
        currentday: spittoday[0],
        currentmonth: spittoday[1],
        currentyear: spittoday[2],
      });

      var config = {
        method: "post",
        url: `${URLofficer}/mission`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.href = "https://php.thaipadi.org/mission.php";
          handleClosemission();
          printjob();
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };
  //ตรวจสอบข้อมูล
  //ใบลากิจ

  //ภารกิจอื่นๆ
  const [startjob, setStartjob] = useState(new Date());
  const [endjob, setEndjob] = useState(new Date());
  const [selecttnjob, setSelecttnjob] = useState("");
  const [selectnamejob, setSelectnamejob] = useState("");
  const [causejob, setCausejob] = useState("");
  const [openjob, setOpenjob] = useState(false);

  const selectpatientjob = (TN, prefix, fname, lname) => {
    setSelecttnjob(TN);
    setSelectnamejob(`${prefix}${fname} ${lname}`);
  };

  const handleChangecausejob = (event) => {
    setCausejob(event.target.value);
  };

  const handleOpenjob = () => setOpenjob(true);
  const handleClosejob = () => setOpenjob(false);

  //ตรวจสอบข้อมูล

  const confirmdeletejob = (JN) => {
    Swal.fire({
      title: "ยืนยันการลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        deletejob(JN);
      }
    });
  };

  const deletejob = (JN) => {
    var data = JSON.stringify({
      JN: JN,
    });

    var config = {
      method: "delete",
      url: `${URLofficer}/job`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการลบข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          getjob();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const printjob = () => {
    const datestart = startjob.getFullYear() + "-" + (startjob.getMonth() + 1) + "-" + startjob.getDate();

    const dateend = endjob.getFullYear() + "-" + (endjob.getMonth() + 1) + "-" + endjob.getDate();

    var date1 = new Date(datestart);
    var date2 = new Date(dateend);

    var diffTime = date2.getTime() - date1.getTime();
    var diffDay = diffTime / (1000 * 3600 * 24);

    if (!selecttnjob || !selectnamejob || !causejob) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      if (diffDay < 0) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "ท่านกรอกข้อมูลวันที่ไม่ถูกต้อง กรุณาตรวจสอบความถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      } else if (diffDay === 0) {
        postjob(startjob);
        handleClosejob();
        return;
      } else if (diffDay === 1) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        return;
      } else if (diffDay === 2) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        return;
      } else if (diffDay === 3) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        return;
      } else if (diffDay === 4) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        return;
      } else if (diffDay === 5) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        return;
      } else if (diffDay === 6) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        return;
      } else if (diffDay === 7) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        return;
      } else if (diffDay === 8) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        return;
      } else if (diffDay === 9) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        return;
      } else if (diffDay === 10) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        return;
      } else if (diffDay === 11) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        startjob.setDate(startjob.getDate() + 11);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 11);
        return;
      } else if (diffDay === 12) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        startjob.setDate(startjob.getDate() + 11);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 11);
        startjob.setDate(startjob.getDate() + 12);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 12);
        return;
      } else if (diffDay === 13) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        startjob.setDate(startjob.getDate() + 11);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 11);
        startjob.setDate(startjob.getDate() + 12);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 12);
        startjob.setDate(startjob.getDate() + 13);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 13);
        return;
      } else if (diffDay === 14) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        startjob.setDate(startjob.getDate() + 11);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 11);
        startjob.setDate(startjob.getDate() + 12);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 12);
        startjob.setDate(startjob.getDate() + 13);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 13);
        startjob.setDate(startjob.getDate() + 14);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 14);
        return;
      } else if (diffDay === 15) {
        postjob(startjob);
        startjob.setDate(startjob.getDate() + 1);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 1);
        startjob.setDate(startjob.getDate() + 2);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 2);
        startjob.setDate(startjob.getDate() + 3);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 3);
        startjob.setDate(startjob.getDate() + 4);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 4);
        startjob.setDate(startjob.getDate() + 5);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 5);
        startjob.setDate(startjob.getDate() + 6);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 6);
        startjob.setDate(startjob.getDate() + 7);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 7);
        startjob.setDate(startjob.getDate() + 8);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 8);
        startjob.setDate(startjob.getDate() + 9);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 9);
        startjob.setDate(startjob.getDate() + 10);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 10);
        startjob.setDate(startjob.getDate() + 11);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 11);
        startjob.setDate(startjob.getDate() + 12);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 12);
        startjob.setDate(startjob.getDate() + 13);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 13);
        startjob.setDate(startjob.getDate() + 14);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 14);
        startjob.setDate(startjob.getDate() + 15);
        postjob(startjob);
        startjob.setDate(startjob.getDate() - 15);
        return;
      } else if (diffDay > 15) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "ระบบไม่อนุญาตให้ระบุวันลาเกินกว่า 15 วัน",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      } else {
        return;
      }
    }
  };

  const postjob = (date) => {
    const dateget = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();

    const datesql = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " 00:00:00";

    var data = JSON.stringify({
      TN: selecttnjob,
      date: date.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
      cause: causejob,
      dateget: dateget,
      datesql: datesql,
    });

    var config = {
      method: "post",
      url: `${URLofficer}/job`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getjob();
      })
      .catch(function (error) {
        alert(error);
      });
  };

  //ตรวจสอบข้อมูล

  //ภารกิจอื่นๆ

  //ฉบับ11

  const [endeleven, setEndeleven] = useState(new Date());
  const [selecttneleven, setSelecttneleven] = useState("");
  const [selectnameeleven, setSelectnameeleven] = useState("");
  const [topmountelevent, setTopmountelevent] = useState("");
  const [priceelevent, setPriceelevent] = useState("");
  const [openeleven, setOpeneleven] = useState(false);
  //กำหนดวันเดือนปี
  const [yearelevent, setYearelevent] = useState("");
  const [mountelevent, setMountelevent] = useState("");
  const [yeartwoelevent, setYeartwoelevent] = useState("");
  const [mounttwoelevent, setMounttwoelevent] = useState("");
  const [allyearelevent, setAllyearelevent] = useState("");
  const [allmountelevent, setAllmountelevent] = useState("");
  //กำหนดวันเดือนปี

  //ตรวจสอบข้อมูล
  const printeleven = () => {
    if (!selecttneleven || !topmountelevent || !priceelevent) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      if (selecttneleven === 1) {
        const data = JSON.stringify({
          TN: selecttneleven,
          oneuntil: endeleven.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
          oneyear: yearelevent,
          onemonth: mountelevent,
          month: topmountelevent,
          price: priceelevent,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/arofateleven`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = "https://php.thaipadi.org/arofat.php";
          })
          .catch(function (error) {
            alert(error);
          });
      } else if (selecttneleven === 3) {
        const data = JSON.stringify({
          TN: selecttneleven,
          oneuntil: endeleven.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
          oneyear: yearelevent,
          onemonth: mountelevent,
          month: topmountelevent,
          price: priceelevent,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/asuwaeleven`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = "https://php.thaipadi.org/asuwa.php";
          })
          .catch(function (error) {
            alert(error);
          });
      } else if (selecttneleven === 4) {
        const data = JSON.stringify({
          TN: selecttneleven,
          oneuntil: endeleven.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
          oneyear: yearelevent,
          onemonth: mountelevent,
          month: topmountelevent,
          price: priceelevent,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/fateemoheleven`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = "https://php.thaipadi.org/fateemoh.php";
          })
          .catch(function (error) {
            alert(error);
          });
      } else if (selecttneleven === 5) {
        const data = JSON.stringify({
          TN: selecttneleven,
          oneuntil: endeleven.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
          oneyear: yearelevent,
          onemonth: mountelevent,
          month: topmountelevent,
          price: priceelevent,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/julapiteleven`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = "https://php.thaipadi.org/julapit.php";
          })
          .catch(function (error) {
            alert(error);
          });
      } else if (selecttneleven === 2) {
        const data = JSON.stringify({
          TN: selecttneleven,
          twountil: endeleven.toLocaleDateString("th-TH", { year: "numeric", month: "long", day: "numeric" }),
          twoyear: yeartwoelevent,
          twomonth: mounttwoelevent,
          allyear: allyearelevent,
          allmonth: allmountelevent,
          month: topmountelevent,
          price: priceelevent,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/aneeraeleven`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = "https://php.thaipadi.org/aneera.php";
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "ชื่อดังกล่าวไม่มีสิทธิ์ใช้งานแบบฟอร์มนี้",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
    }
  };
  //ตรวจสอบข้อมูล

  const selectpatienteleven = (TN, prefix, fname, lname) => {
    setSelecttneleven(TN);
    setSelectnameeleven(`${prefix}${fname} ${lname}`);
    sendcalculatetn(TN);
  };

  const sendcalculatetn = (TN) => {
    calculatetn(endeleven, TN);
  };

  const calculatetn = (date, TN) => {
    if (TN === 1) {
      const setsinceday = new Date("2019", "4", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (TN === 3) {
      const setsinceday = new Date("2013", "2", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (TN === 4) {
      const setsinceday = new Date("2020", "0", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (TN === 5) {
      const setsinceday = new Date("2000", "2", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (TN === 2) {
      const settwosinceday = new Date("2020", "7", "31");
      const setsinceday = new Date("2016", "5", "1");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYeartwoelevent(Math.floor(Math.round((setuntilday - settwosinceday) / 86400000) / 365));
      setMounttwoelevent(Math.floor((Math.round((setuntilday - settwosinceday) / 86400000) % 365) / 30));
      setAllyearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setAllmountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    }
  };

  //คำนวนวันที่
  const calculatedate = (date) => {
    setEndeleven(date);
    if (selecttneleven === 1) {
      const setsinceday = new Date("2019", "4", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (selecttneleven === 3) {
      const setsinceday = new Date("2013", "2", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (selecttneleven === 4) {
      const setsinceday = new Date("2020", "0", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (selecttneleven === 5) {
      const setsinceday = new Date("2000", "2", "31");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setMountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    } else if (selecttneleven === 2) {
      const settwosinceday = new Date("2020", "7", "31");
      const setsinceday = new Date("2016", "5", "1");
      const setuntilday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setYeartwoelevent(Math.floor(Math.round((setuntilday - settwosinceday) / 86400000) / 365));
      setMounttwoelevent(Math.floor((Math.round((setuntilday - settwosinceday) / 86400000) % 365) / 30));
      setAllyearelevent(Math.floor(Math.round((setuntilday - setsinceday) / 86400000) / 365));
      setAllmountelevent(Math.floor((Math.round((setuntilday - setsinceday) / 86400000) % 365) / 30));
      return;
    }
  };

  //คำนวนวันที่

  const handleChangemounteleven = (event) => {
    setTopmountelevent(event.target.value);
  };
  const handleChangepriceeleven = (event) => {
    setPriceelevent(event.target.value);
  };

  const handleOpeneleven = () => setOpeneleven(true);
  const handleCloseeleven = () => setOpeneleven(false);

  //ฉบับ11

  //ฉบับ10
  const [selecttnten, setSelecttnten] = useState("");
  const [selectnameten, setSelectnameten] = useState("");
  const [topmountten, setTopmountten] = useState("");
  const [priceten, setPriceten] = useState("");
  const [amountten, setAmountten] = useState("");
  const [openten, setOpenten] = useState(false);

  //ตรวจสอบข้อมูล

  const printten = () => {
    if (!selecttnten || !topmountten || !priceten || !amountten) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      if (selecttnten === 1 || selecttnten === 2 || selecttnten === 3 || selecttnten === 4 || selecttnten === 5) {
        const data = JSON.stringify({
          TN: selecttnten,
          month: topmountten,
          price: priceten,
          amount: amountten,
          allprice: priceten * amountten,
        });

        const config = {
          method: "post",
          url: `${URLofficer}/ten${selecttnten}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            window.location.href = `https://php.thaipadi.org/ten${selecttnten}.php`;
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "ชื่อดังกล่าวไม่มีสิทธิ์ใช้งานแบบฟอร์มนี้",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
    }
  };
  //ตรวจสอบข้อมูล

  const selectpatientten = (TN, prefix, fname, lname) => {
    setSelecttnten(TN);
    setSelectnameten(`${prefix}${fname} ${lname}`);
  };

  const handleChangemountten = (event) => {
    setTopmountten(event.target.value);
  };
  const handleChangepriceten = (event) => {
    setPriceten(event.target.value);
  };
  const handleChangeamountten = (event) => {
    setAmountten(event.target.value);
  };

  const handleOpenten = () => setOpenten(true);
  const handleCloseten = () => setOpenten(false);

  //ฉบับ10

  //cheque
  const [selecttncheque, setSelecttncheque] = useState("");
  const [selectnamecheque, setSelectnamecheque] = useState("");
  const [cncheque, setCncheque] = useState("");
  const [pricecheque, setPricecheque] = useState("");
  const [providershow, setProvidershow] = useState("");
  const [providercheque, setProvidercheque] = useState("");
  const [providertelcheque, setProvidertelcheque] = useState("");
  const [opencheque, setOpencheque] = useState(false);

  //ตรวจสอบข้อมูล

  const printcheque = () => {
    console.log(selecttncheque,cncheque,pricecheque,providercheque,providertelcheque)
    if (!selecttncheque || !cncheque || !pricecheque || !providercheque || !providertelcheque) {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "ท่านกรอกข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบถ้วน",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } else {
      const data = JSON.stringify({
        TN: selecttncheque,
        CN: cncheque,
        price: pricecheque,
        provider: providercheque,
        providertel: providertelcheque,
      });

      const config = {
        method: "post",
        url: `${URLofficer}/cheque`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.href = `https://php.thaipadi.org/cheque.php`;
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };
  //ตรวจสอบข้อมูล

  const selectchequetn = (TN, prefix, fname, lname) => {
    setSelecttncheque(TN);
    setSelectnamecheque(`${prefix}${fname} ${lname}`);
  };

  const handleChangecn = (event) => {
    setCncheque(event.target.value);
  };

  const handleChangepricecheque = (event) => {
    setPricecheque(event.target.value);
  };

  const handleOpencheque = () => setOpencheque(true);
  const handleClosecheque = () => setOpencheque(false);

  //cheque

  return (
    <div>
      <Navback />
      <CssBaseline />
      <Container maxWidth="xl">
        <Box>
          <Paper style={{ marginBottom: "10px", marginTop: "100px" }}>
            <form>
              <div className="officer" style={{ padding: "10px", background: "#99FFCC", fontWeight: "bolder" }}>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" style={{ backgroundColor: "#ff6624", paddingRight: "50px", paddingLeft: "50px" }} onClick={handleOpenrest}>
                    <h2 style={{ color: "black" }}>ใบลาพักผ่อน</h2>
                  </Button>
                  <Modal open={openrest} onClose={handleCloserest} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatientrest(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดการลาพักผ่อน
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnamerest}`} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ตั้งแต่วันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ถึงวันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setStartdateDate(Date)} value={startDate} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setEnddateDate(Date)} value={endDate} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <TextField fullWidth type="number" size="small" id="dayrestworking" label="จำนวนวันทำการ" variant="standard" onChange={handleChangedayrestworking} placeholder="กรุณากรอกจำนวนวันทำการเป็นตัวเลข" value={dayrestworking} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">ผู้ปฏิบัติหน้าที่แทน</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={agent} onChange={handleChangeagent} label="ผู้ปฏิบัติหน้าที่แทน">
                                      <MenuItem value="นางสาวจุฬาพิชญ์ สุขสมบูรณ์">นางสาวจุฬาพิชญ์ สุขสมบูรณ์</MenuItem>
                                      <MenuItem value="นางสาวอานีรา สะบูดิง">นางสาวอานีรา สะบูดิง</MenuItem>
                                      <MenuItem value="นายอารอฟัต สะบูดิง">นายอารอฟัต สะบูดิง</MenuItem>
                                      <MenuItem value="นายอาซูวา อุมา">นายอาซูวา อุมา</MenuItem>
                                      <MenuItem value="นางสาวฟาตีเม๊าะ ลีมิง">นางสาวฟาตีเม๊าะ ลีมิง</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">ตำแหน่งผู้ปฏิบัติหน้าที่แทน</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={agentposition} onChange={handleChangeagentposition} label="ตำแหน่งผู้ปฏิบัติหน้าที่แทน">
                                      <MenuItem value="แพทย์แผนไทยชำนาญการ">แพทย์แผนไทยชำนาญการ</MenuItem>
                                      <MenuItem value="แพทย์แผนไทยปฏิบัติการ">แพทย์แผนไทยปฏิบัติการ</MenuItem>
                                      <MenuItem value="นักวิชาการสาธารณสุขปฏิบัติการ">นักวิชาการสาธารณสุขปฏิบัติการ</MenuItem>
                                      <MenuItem value="เจ้าพนักงานสาธารณสุข">เจ้าพนักงานสาธารณสุข</MenuItem>
                                      <MenuItem value="พนักงานบริการ">พนักงานบริการ</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printrest}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleCloserest}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" style={{ backgroundColor: "#ffa22a", paddingRight: "63px", paddingLeft: "63px" }} onClick={handleOpensick}>
                    <h2 style={{ color: "black" }}>ใบลาป่วย</h2>
                  </Button>
                  <Modal open={opensick} onClose={handleClosesick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatientsick(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดการลาป่วย
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnamesick}`} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="sick" fullWidth size="small" label="สาเหตุการลาป่วย" variant="standard" placeholder="ระบุสาเหตุการลาป่วย" onChange={handleChangecausesick} value={causesick} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ตั้งแต่วันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ถึงวันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setStartdatesick(Date)} value={startsick} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setEnddatesick(Date)} value={endsick} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printsick}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleClosesick}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" style={{ backgroundColor: "#82ac26", paddingRight: "68px", paddingLeft: "68px" }} onClick={handleOpenmission}>
                    <h2 style={{ color: "black" }}>ใบลากิจ</h2>
                  </Button>
                  <Modal open={openmission} onClose={handleClosemission} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatientmission(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดการลากิจ
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnamemission}`} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="mission" fullWidth size="small" label="สาเหตุการลากิจ" variant="standard" placeholder="ระบุสาเหตุการลากิจ" onChange={handleChangecausemission} value={causemission} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ตั้งแต่วันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ถึงวันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setStartdatemission(Date)} value={startmission} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setEnddatemission(Date)} value={endmission} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printmission}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleClosemission}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" style={{ backgroundColor: "#ecb4d3", paddingRight: "50px", paddingLeft: "50px" }} onClick={handleOpenjob}>
                    <h2 style={{ color: "black" }}>ภารกิจอื่น ๆ </h2>
                  </Button>
                  <Modal open={openjob} onClose={handleClosejob} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatientjob(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดภารกิจ
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnamejob}`} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="mission" fullWidth size="small" label="ภาจกิจ" variant="standard" placeholder="ระบุรายละเอียดภารกิจ" onChange={handleChangecausejob} value={causejob} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ตั้งแต่วันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ถึงวันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setStartjob(Date)} value={startjob} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <DatePicker onChange={(Date) => setEndjob(Date)} value={endjob} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printjob}>
                                    บันทึก
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleClosejob}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" style={{ backgroundColor: "#8BD2EC", paddingRight: "69px", paddingLeft: "69px" }} onClick={handleOpeneleven}>
                    <h2 style={{ color: "black" }}>ฉบับ 11</h2>
                  </Button>
                  <Modal open={openeleven} onClose={handleCloseeleven} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatienteleven(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดฉบับ11
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnameeleven}`} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField fullWidth id="mountarofat" size="small" label="ประจำเดือน" variant="standard" onChange={handleChangemounteleven} placeholder="ประจำเดือน" value={topmountelevent} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <Typography variant="subtitle1" component="h2" style={{ textAlign: "center" }}>
                                    ถึงวันที่
                                  </Typography>
                                </Grid>
                                <Grid item xs={16} sm={16} style={{ textAlign: "center" }}>
                                  <DatePicker onChange={(Date) => calculatedate(Date)} value={endeleven} format="dd-MM-y" locale="th-TH" calendarClassName="calendarclass" className="picker" />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField type="number" fullWidth id="arofatprice" size="small" label="จำนวนเงิน" variant="standard" onChange={handleChangepriceeleven} value={priceelevent} placeholder="กรุณาระบุจำนวนเงิน" required />
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printeleven}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleCloseeleven}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" onClick={handleOpenten} style={{ backgroundColor: "#63ecdb", paddingRight: "62px", paddingLeft: "62px" }}>
                    <h2 style={{ color: "black" }}>ฉบับ 10</h2>
                  </Button>
                  <Modal open={openten} onClose={handleCloseten} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectpatientten(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดฉบับ10
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnameten}`} required />
                                </Grid>
                                <Grid item xs={16} sm={16}>
                                  <TextField fullWidth id="mountarofat" size="small" label="ประจำเดือน" variant="standard" onChange={handleChangemountten} placeholder="ประจำเดือน" value={topmountten} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField type="number" fullWidth id="arofatprice" size="small" label="จำนวนเงินต่อเดือน" variant="standard" onChange={handleChangepriceten} value={priceten} placeholder="ระบุจำนวนเงินต่อเดือน" required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField type="number" fullWidth id="arofatprice" size="small" label="จำนวนเดือน" variant="standard" onChange={handleChangeamountten} value={amountten} placeholder="ระบุจำนวนเดือน" required />
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printten}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleCloseten}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
                <div style={{ margin: "5px", display: "block" }}>
                  <Button variant="contained" className="buttonreviews" onClick={handleOpencheque} style={{ backgroundColor: "#63ecdb", paddingRight: "62px", paddingLeft: "62px" }}>
                    <h2 style={{ color: "black" }}>ใบฝากเงิน</h2>
                  </Button>
                  <Modal open={opencheque} onClose={handleClosecheque} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                          </Box>
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "4px",
                          }}
                        >
                          <TableContainer sx={{ height: "120px", maxWidth: "320px" }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "30px",
                                      padding: "5px 0 5px 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    TN
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    ชื่อสกุล
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      maxWidth: "60px",
                                      padding: "5px 5px 5px 5px",
                                      backgroundColor: "#1bb3b3",
                                    }}
                                  >
                                    เลือก
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data
                                  .filter((e) => {
                                    return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search);
                                  })
                                  .map((item) => {
                                    return (
                                      <TableRow key={item.TN}>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 0 5px 0",
                                          }}
                                        >
                                          {item.TN}
                                        </TableCell>
                                        <TableCell style={{ padding: "0 0 0 5px" }}>
                                          {item.prefix}
                                          {item.fname} {item.lname}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            maxWidth: "60px",
                                            padding: "5px 5px 5px 5px",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                              margin: "0 0 0 8px",
                                              display: "contents",
                                            }}
                                            onClick={() => {
                                              return selectchequetn(item.TN, item.prefix, item.fname, item.lname);
                                            }}
                                          >
                                            <Send color="secondary" size="small" />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Paper>
                      <Paper>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                              รายละเอียดใบฝากเงิน
                            </Typography>
                            <Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={16} sm={16}>
                                  <TextField id="fullname" fullWidth size="small" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectnamecheque}`} required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField type="number" fullWidth id="arofatprice" size="small" label="หมายเลขเช็ค" variant="standard" onChange={handleChangecn} value={cncheque} placeholder="ระบุหมายเลขเช็ค" required />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField type="number" fullWidth id="arofatprice" size="small" label="จำนวนเงิน" variant="standard" onChange={handleChangepricecheque} value={pricecheque} placeholder="ระบุจำนวนเงิน" required />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">ผู้นำฝาก</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={providershow}
                                      onChange={(e) => {
                                        setProvidershow(e.target.value);
                                        setProvidercheque(e.target.value.split(',')[0]);
                                        setProvidertelcheque(e.target.value.split(',')[1]);
                                      }}
                                      label="providercheque"
                                    >
                                      {data                                  
                                      .filter((e) => {
                                        return e.TN < 5;
                                      })
                                      .map((item) => (
                                        <MenuItem key={item.TN} value={`${item.prefix}${item.fname} ${item.lname}, ${item.tel}`}>
                                          {item.prefix}{item.fname} {item.lname}, {item.tel}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8} style={{ marginTop: "15px" }}>
                                  <Button type="submit" variant="contained" color="primary" fullWidth onClick={printcheque}>
                                    พิมพ์
                                  </Button>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "15px" }}>
                                  <Button fullWidth variant="contained" color="error" onClick={handleClosecheque}>
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                    </Box>
                  </Modal>
                </div>
              </div>
            </form>
          </Paper>
          <div style={{ marginBottom: "70px" }}>
            <div>
              <Grid item sm={4}>
                <Paper>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1px",
                    }}
                  >
                    <h2>รายละเอียดทั้งหมด</h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={job} size="small" style={{ marginTop: "10px", width: "90%" }} />
                  </div>
                  <TableContainer sx={{ height: "600px" }} style={{ marginBottom: "10px" }}>
                    <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              tableLayout: "fixed",
                              width: "40px",
                              maxHeight: "30px",
                              padding: "5px 0 5px 0",
                              backgroundColor: "#1bb3b3",
                            }}
                            // className="searchbackdate"
                          >
                            วันที่
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              tableLayout: "fixed",
                              width: "50px",
                              padding: "5px 0 5px 0",
                              backgroundColor: "#1bb3b3",
                            }}
                            // className="searchbackdate"
                          >
                            ชื่อ-สกุล
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              tableLayout: "fixed",
                              maxHeight: "30px",
                              width: "60px",
                              padding: "0 0 0 0",
                              backgroundColor: "#1bb3b3",
                            }}
                            // className="searchbackname"
                          >
                            รายละเอียด
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              tableLayout: "fixed",
                              width: "20px",
                              backgroundColor: "#1bb3b3",
                              padding: "5px 5px 5px 5px",
                            }}
                            // className="searchbackrole"
                          >
                            สถานะ
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="arofattest">
                        {datajob
                          .filter((e) => {
                            return e.fname.includes(searchjob) || e.lname.includes(searchjob) || e.prefix.includes(searchjob) || e.date.includes(searchjob) || e.dateget.includes(searchjob) || e.cause.includes(searchjob);
                          })
                          .map((item) => {
                            return (
                              <TableRow key={item.JN} className="searchusersrow">
                                <TableCell
                                  align="center"
                                  style={{
                                    tableLayout: "fixed",
                                    width: "40px",
                                    padding: "5px 1px 5px 1px",
                                  }}
                                  // className="searchbackdate"
                                >
                                  {item.dateget}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    tableLayout: "fixed",
                                    width: "50px",
                                    padding: "5px 1px 5px 1px",
                                  }}
                                  // className="searchbackdate"
                                >
                                  {item.fname}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    tableLayout: "fixed",
                                    width: "60px",
                                    maxHeight: "10px",
                                    padding: "0 0 0 10px",
                                  }}
                                  // className="searchbackname"
                                >
                                  {item.cause}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    tableLayout: "fixed",
                                    width: "20px",
                                    padding: "5px 5px 5px 5px",
                                  }}
                                  // className="searchbackrole"
                                >
                                  <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    className="deleteusers"
                                    onClick={() => {
                                      return confirmdeletejob(item.JN);
                                    }}
                                    style={{ display: "contents" }}
                                  >
                                    <DeleteForever size="small" color="error" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </div>
            <div className="officerjob">
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>อารอฟัต สะบูดิง</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0902099333</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {arofatjob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {arofatjob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {arofatjob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={arofat} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {arofatjob
                            .filter((e) => {
                              return e.fname.includes(arofatsearchjob) || e.lname.includes(arofatsearchjob) || e.prefix.includes(arofatsearchjob) || e.date.includes(arofatsearchjob) || e.dateget.includes(arofatsearchjob) || e.cause.includes(arofatsearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>อานีรา สะบูดิง</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0872856874</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {aneerajob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {aneerajob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {aneerajob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={aneera} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {aneerajob
                            .filter((e) => {
                              return e.fname.includes(aneerasearchjob) || e.lname.includes(aneerasearchjob) || e.prefix.includes(aneerasearchjob) || e.date.includes(aneerasearchjob) || e.dateget.includes(aneerasearchjob) || e.cause.includes(aneerasearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>อาซูวา อุมา</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0948040460</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {asuwajob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {asuwajob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {asuwajob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={asuwa} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {asuwajob
                            .filter((e) => {
                              return e.fname.includes(asuwasearchjob) || e.lname.includes(asuwasearchjob) || e.prefix.includes(asuwasearchjob) || e.date.includes(asuwasearchjob) || e.dateget.includes(asuwasearchjob) || e.cause.includes(asuwasearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
            </div>
            <div className="officerjob">
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>ฟาตีเม๊าะ ลีมิง</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0957765030</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {fateemohjob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {fateemohjob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {fateemohjob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={fateemoh} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {fateemohjob
                            .filter((e) => {
                              return e.fname.includes(fateemohsearchjob) || e.lname.includes(fateemohsearchjob) || e.prefix.includes(fateemohsearchjob) || e.date.includes(fateemohsearchjob) || e.dateget.includes(fateemohsearchjob) || e.cause.includes(fateemohsearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>วันอารีนา อาแด</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0823301007</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {wanareenajob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {wanareenajob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {wanareenajob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={wanareena} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {wanareenajob
                            .filter((e) => {
                              return e.fname.includes(wanareenasearchjob) || e.lname.includes(wanareenasearchjob) || e.prefix.includes(wanareenasearchjob) || e.date.includes(wanareenasearchjob) || e.dateget.includes(wanareenasearchjob) || e.cause.includes(wanareenasearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>นูรไอดา อูมา</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0925543001</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {nuraidajob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {nuraidajob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {nuraidajob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={nuraida} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {nuraidajob
                            .filter((e) => {
                              return e.fname.includes(nuraidasearchjob) || e.lname.includes(nuraidasearchjob) || e.prefix.includes(nuraidasearchjob) || e.date.includes(nuraidasearchjob) || e.dateget.includes(nuraidasearchjob) || e.cause.includes(nuraidasearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
            </div>
            <div className="officerjob">
              <div className="jober">
                <Grid item sm={4}>
                  <Paper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <h2>สีตีฟาตีเมาะ ดอเลาะ</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>โทร. 0629750048</h4>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p>
                        ลาพักผ่อน = {seeteefateemohjob.filter((e) => e.cause === "ลาพักผ่อน").length} , ลาป่วย = {seeteefateemohjob.filter((e) => e.cause === "ลาป่วย").length} , ลากิจ = {seeteefateemohjob.filter((e) => e.cause === "ลากิจ").length}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <TextField id="fullWidth" label="ค้นหา..." align="center" variant="outlined" onChange={seeteefateemoh} size="small" style={{ marginTop: "10px", width: "90%" }} />
                    </div>
                    <TableContainer sx={{ height: "200px" }} style={{ marginBottom: "10px" }}>
                      <Table stickyHeader aria-label="sticky table" style={{ marginBottom: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackdate"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                maxHeight: "30px",
                                width: "140px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                              // className="searchbackname"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                tableLayout: "fixed",
                                width: "20px",
                                backgroundColor: "#1bb3b3",
                                padding: "5px 5px 5px 5px",
                              }}
                              // className="searchbackrole"
                            >
                              สถานะ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="arofattest">
                          {seeteefateemohjob
                            .filter((e) => {
                              return e.fname.includes(seeteefateemohsearchjob) || e.lname.includes(seeteefateemohsearchjob) || e.prefix.includes(seeteefateemohsearchjob) || e.date.includes(seeteefateemohsearchjob) || e.dateget.includes(seeteefateemohsearchjob) || e.cause.includes(seeteefateemohsearchjob);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.JN} className="searchusersrow">
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "30px",
                                      padding: "5px 1px 5px 1px",
                                    }}
                                    // className="searchbackdate"
                                  >
                                    {item.dateget}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "140px",
                                      maxHeight: "10px",
                                      padding: "0 0 0 10px",
                                    }}
                                    // className="searchbackname"
                                  >
                                    {item.cause}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      tableLayout: "fixed",
                                      width: "20px",
                                      padding: "5px 5px 5px 5px",
                                    }}
                                    // className="searchbackrole"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      className="deleteusers"
                                      onClick={() => {
                                        return confirmdeletejob(item.JN);
                                      }}
                                      style={{ display: "contents" }}
                                    >
                                      <DeleteForever size="small" color="error" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </div>
            </div>
          </div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default Officerpc;
