import { Routes , Route } from 'react-router-dom';
import './App.css';
import Backhome from './backhome/Backhome';
import Home from './fronthome/Home';
import Cancelback from './backhome/cancelback';
import Block from './backhome/Block';
import Useredit from './backhome/Useredit';
import Users from './backhome/Users';
import Searchusers from './backhome/searchusers';
import Usercreate from './backhome/Usercreate';
import Ondateline from './backhome/ondateline';
import Holidays from './backhome/holidays';
import Officerpc from './backhome/Officerpc';
import Report from './backhome/report';
import Schedule from './backhome/schedule';
import Apponeback from './backhome/apponeback';
import Login from './fronthome/Login'; 
import Loginline from './fronthome/Loginline';
import Searchloginline from './fronthome/Searchloginline';
import Cancelloginline from './fronthome/cancelloginline';
import Aboutme from './fronthome/Aboutme';
import Aboutlogin from './fronthome/aboutlogin';
import About from './fronthome/About';
import Calendar from './fronthome/Calendar';
import Reviews from './fronthome/Reviews';
import Signup from './fronthome/Signup';
import Search from './fronthome/Search';
import Cpass from './fronthome/cpass';
import Calendarusers from './fronthome/Calendarusers';
import Searchline from './fronthome/Searchline';
import Calendarline from './fronthome/Calendarline';
import Calendarusersline from './fronthome/Calendarusersline';
import Cancelappline from './fronthome/Cancelappline';
import Homeline from './fronthome/Homeline';
import Calendargroupline from './fronthome/Calendargroupline';
import Cancelappgroupline from './fronthome/Cancelappgroupline ';
import Logingroupline from './fronthome/Logingroupline';
import Calendarusersgroupline from './fronthome/Calendarusersgroupline';
import Loginschedule from './fronthome/loginschedule';
import Scheduleusers from './fronthome/scheduleusers';
import Scheduleline from './fronthome/scheduleline';
import Steamback from './backhome/steamback';
import Searchapp from './fronthome/Searchapp';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/homeline" element={<Homeline />}></Route>
      <Route path="/backhome" element={<Backhome />}></Route>
      <Route path="/aboutme" element={<Aboutme />}></Route>
      <Route path="/cancelback" element={<Cancelback />}></Route>
      <Route path="/block" element={<Block />}></Route>
      <Route path="/users" element={<Users />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/aboutlogin" element={<Aboutlogin />}></Route>
      <Route path="/calendar" element={<Calendar />}></Route>
      <Route path="/calendarline" element={<Calendarline/>}></Route>
      <Route path="/calendarusersline" element={<Calendarusersline/>}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/loginline" element={<Loginline />}></Route>
      <Route path="/reviews" element={<Reviews />}></Route>
      <Route path="/signup" element={<Signup />}></Route>
      <Route path="/search" element={<Search />}></Route>
      <Route path="/searchline" element={<Searchline />}></Route>
      <Route path="/searchloginline" element={<Searchloginline />}></Route>
      <Route path="/cancelappline" element={<Cancelappline />}></Route>
      <Route path="/cancelloginline" element={<Cancelloginline />}></Route>
      <Route path="/usercreate" element={<Usercreate />}></Route>
      <Route path="/useredit/:TN" element={<Useredit />}></Route>
      <Route path="/useredit" element={<Users />}></Route>
      <Route path="/cpass" element={<Cpass />}></Route>
      <Route path="/searchusers" element={<Searchusers />}></Route>
      <Route path="/nextdays" element={<Holidays/>}></Route>
      <Route path="/searchapp" element={<Searchline />}></Route>
      <Route path="/calendarusers" element={<Calendarusers />}></Route>
      <Route path="/calendaradminline" element={<Ondateline/>}></Route>
      <Route path="/calendargroupline" element={<Calendargroupline/>}></Route>
      <Route path="/calendarusersgroupline" element={<Calendarusersgroupline/>}></Route>
      <Route path="/cancelappgroupline" element={<Cancelappgroupline/>}></Route>
      <Route path="/logingroupline" element={<Logingroupline/>}></Route>
      <Route path="/officerpc" element={<Officerpc/>}></Route>
      <Route path="/report" element={<Report/>}></Route>
      <Route path="/schedule" element={<Schedule/>}></Route>
      <Route path="/scheduleusers" element={<Scheduleusers/>}></Route>
      <Route path="/loginschedule" element={<Loginschedule/>}></Route>
      <Route path="/scheduleline" element={<Scheduleline/>}></Route>
      <Route path="/apponeback" element={<Apponeback/>}></Route>
      {/* <Route path="/steamback" element={<Steamback/>}></Route> */}
    </Routes>
  )
}
export default App;


