import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { FormControl, Select, InputLabel, MenuItem  } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { Link, useNavigate ,  } from "react-router-dom";
import Swal from "sweetalert2";




const theme = createTheme();

export default function Signup() {

  const navigate = useNavigate()
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  // useEffect(() => {

  //   const token = localStorage.getItem('token')
    
  //   var config = {
  //     method: 'post',
  //     url: `${URL}/authen`,
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     },
  //   };
    
  //   axios(config)
  //   .then(function (response) {  
  //             Swal.fire({
  //               icon: 'warning',
  //               title: 'แจ้งเตือน',
  //               text:'ระบบยังไม่เปิดระบบสมัครสมาชิก กรุณาติดต่อเจ้าหน้าที่เพื่อรับรหัสผ่าน!',
  //               showConfirmButton: false,
  //               timer: 1500
  //             }).then((result)=>{    
  //               navigate("/");
  //             })
      
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  //   }, [navigate,URL]);


  const [ value , setValue ] = useState({
    prefix:"",
    fname:"",
    lname:"",
    password:"",
    cpassword:""
  })

  const handleChange = (e) =>{
    e.preventDefault();
    setValue({...value,
      [e.target.name]: e.target.value
    })
  }

  const checksubmit =(e)=>{
    e.preventDefault();
    if(value.password !== value.cpassword){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'รหัสผ่านไม่ตรงกัน',
            text:'กรุณากรอกรหัสผ่านให้ตรงกัน',
            showConfirmButton: false,
            timer: 2000
          })
        }else{
          handleSubmit()
        }
  }

  const handleSubmit = () => {

    const jsondata = JSON.stringify({
      prefix: value.prefix,
      fname: value.fname,
      lname: value.lname,
      tel: value.tel,
      password: value.password,
    });

    var config = {
      method: "post",
      url: `${URL}/register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: jsondata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'สมัครสมาชิกเรียบร้อย',
            showConfirmButton: false,
            timer: 2000
          }).then((result)=>{
            navigate("/login");
          });return
        } if (response.data.message.sqlState === "23000"){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'เบอร์โทรศัพท์นี้มีผู้อื่นใช้แล้ว',
            showConfirmButton: false,
            timer: 1500
          }); return
        }
        else {
          alert(response.data);
          console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            สมัครสมาชิก
          </Typography>

          <form onSubmit={checksubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="demo-simple-select-standard-label">
                    คำนำหน้า
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="service"
                    name="prefix"
                    value={value.prefix}
                    onChange={handleChange}
                  >
                    <MenuItem value="นาย">นาย</MenuItem>
                    <MenuItem value="นาง">นาง</MenuItem>
                    <MenuItem value="นางสาว">นางสาว</MenuItem>
                    <MenuItem value="เด็กชาย">เด็กชาย</MenuItem>
                    <MenuItem value="เด็กหญิง">เด็กหญิง</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="fname"
                  required
                  fullWidth
                  onChange={handleChange}
                  id="firstName"
                  label="ชื่อ"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  id="lastName"
                  label="นามสกุล"
                  name="lname"
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  name="tel"
                  label="เบอร์โทรศัพท์"
                  type="number"
                  id="tel"
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  name="password"
                  label="รหัสผ่าน"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handleChange}
                  name="cpassword"
                  label="ยืนยันรหัสผ่าน"
                  type="password"
                  id="cpassword"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
          <Link to='/login' className="loginnn" style={{textAlign: 'right' , textDecoration:'none' , color:'blue'}}>
              <p>หากเป็นสมาชิกแล้ว คลิ๊กที่นี้เพื่อเข้าสู่ระบบ</p>
          </Link>
          <hr />
            <h6 style={{marginTop:'16px' , textAlign:'center'}} >
            กรณีลืมรหัสผ่านให้ติดต่อเจ้าหน้าที่เพื่อรีซ็ตรหัสผ่าน
            </h6>
            <h6 style={{textAlign:'center'}}>
            0902099333
            </h6>
      </Container>
    </ThemeProvider>
  );
}
