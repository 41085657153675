import "./Calendar.css";
import * as React from "react";
import { useEffect} from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Calendargrouplinefive from "./Calendargrouplinefive";
import liff from "@line/liff";

const Calendargroupline = () => {

  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const navigate = useNavigate();

  //date
  useEffect(() => {
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          liff.init(
            { liffId: "1657187328-2wnqP3GZ" },
            () => {
              liff
                .getProfile()
                .then((profile) => {
                  axios
                    .put(`${URL}/uuidline/${TN}`, {
                      uuid: profile.userId,
                    })
                    .then(function (response) {
                      navigate("/calendargroupline");
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch((err) => console.error(err));
            },
            (err) => console.error(err)
          );
        } else {
          localStorage.removeItem("fname");
          localStorage.removeItem("lname");
          localStorage.removeItem("TN");
          localStorage.removeItem("role");
          localStorage.removeItem("prefix");
          localStorage.removeItem("uuid");
          navigate("/logingroupline");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);
  //date
  
  return (
    <div
      className="aboutpages"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Container>
        <Box>
          <Calendargrouplinefive />
        </Box>
      </Container>
    </div>
  );
};
export default Calendargroupline;
